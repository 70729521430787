import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Collapse,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import QuestionViewModal from "./QuestionViewModal";
import QuestionGroupViewModal from "../QuestionGroups/QuestionGroupViewModal";

const TestSection = ({
  section,
  onUpdate,
  onDelete,
  usedQuestions,
  setUsedQuestions,
  isLastSection,
  canDelete,
  allSections,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [viewQuestion, setViewQuestion] = useState(null);
  const [viewGroup, setViewGroup] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [groupQuestionsMap, setGroupQuestionsMap] = useState(new Map());
  const [allowedLanguages, setAllowedLanguages] = useState([]);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
  const [groupQuestions, setGroupQuestions] = useState(new Set());
  const [selectedGroups, setSelectedGroups] = useState(new Map());

  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  // Fetch questions on component mount
  useEffect(() => {
    if (isInitialMount) {
      fetchAllowedLanguages();
      fetchCodingQuestions();
      fetchMCQQuestions();
      fetchQuestionGroups();

      // If section has pre-selected groups, fetch their details
      if (section.selectedGroups?.length > 0) {
        section.selectedGroups.forEach(async (group) => {
          try {
            const response = await axios.get(
              `${BASE_URL}/api/org/group/get-group/${group.groupId}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const groupQuestions = response.data.questions;
            setSelectedGroups(
              (prev) => new Map(prev.set(group.groupId, groupQuestions))
            );

            // Add group questions to section's selected questions
            const updatedSection = {
              ...section,
              selectedQuestions: {
                coding: [
                  ...section.selectedQuestions.coding,
                  ...groupQuestions
                    .filter((q) => q.type === "coding")
                    .map((q) => ({
                      ...q.details,
                      id: q.details.question_id,
                      type: "coding",
                    })),
                ],
                mcq: [
                  ...section.selectedQuestions.mcq,
                  ...groupQuestions
                    .filter((q) => q.type === "mcq")
                    .map((q) => ({
                      ...q.details,
                      type: "mcq",
                    })),
                ],
              },
            };
            onUpdate(updatedSection);
          } catch (error) {
            console.error("Error loading group:", error);
          }
        });
      }
      setIsInitialMount(false);
    }
  }, [isInitialMount]);

  useEffect(() => {
    // Update usedQuestions whenever sections change
    const newUsedQuestions = {
      coding: new Set(),
      mcq: new Set(),
    };

    allSections.forEach((s) => {
      s.selectedQuestions.coding.forEach((q) => {
        newUsedQuestions.coding.add(q.id);
      });
      s.selectedQuestions.mcq.forEach((q) => {
        newUsedQuestions.mcq.add(q.mcq_question_id);
      });
    });

    setUsedQuestions(newUsedQuestions);
  }, [allSections]);

  const fetchAllowedLanguages = async () => {
    setIsLoadingLanguages(true);
    try {
      const userId = localStorage.getItem("user_id");

      // First get active plan id
      const planResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/active/id`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!planResponse.data.plan_id) {
        toast.error("No active plan found");
        return;
      }

      // Then fetch plan data including allowed languages
      const planDataResponse = await axios.get(
        `${BASE_URL}/api/plan/data?plan_id=${planResponse.data.plan_id}`
      );

      if (planDataResponse.data.languages) {
        // Filter only allowed languages and store both id and display name
        const allowed = planDataResponse.data.languages
          .filter((lang) => lang.is_allowed)
          .map((lang) => ({
            id: lang.id,
            language: lang.language, // This is the display name
          }));
        setAllowedLanguages(allowed);
      }
    } catch (error) {
      console.error("Error fetching allowed languages:", error);
      toast.error("Failed to fetch allowed languages");
    } finally {
      setIsLoadingLanguages(false);
    }
  };

  const fetchCodingQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCodingQuestions(
        response.data.map((q) => ({
          ...q,
          type: "coding",
        }))
      );
    } catch (error) {
      console.error("Error fetching coding questions:", error);
      // toast.error("Failed to fetch coding questions");
    }
  };

  const fetchMCQQuestions = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/question/fetch-questions/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.data.questions) {
        setMcqQuestions(
          response.data.data.questions.map((q) => ({
            ...q,
            type: "mcq",
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      // toast.error("Failed to fetch MCQ questions");
    }
  };

  const fetchQuestionGroups = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/group/get-all-groups/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        setQuestionGroups(response.data.groups);
      }
    } catch (error) {
      console.error("Error fetching question groups:", error);
      // toast.error("Failed to fetch question groups");
    }
  };

  const handleUpdateSection = (field, value) => {
    if (field === "allowedLanguages") {
      // Validate that all selected languages are in the allowed list
      const invalidLanguages = value.filter(
        (langId) => !allowedLanguages.some((lang) => lang.id === langId)
      );
      if (invalidLanguages.length > 0) {
        toast.error(`Invalid languages selected`);
        return;
      }
    }
    onUpdate({ ...section, [field]: value });
  };

  const filteredQuestions = () => {
    // activeTab: 0 = Groups, 1 = Coding, 2 = MCQ
    const questions = activeTab === 1 ? codingQuestions : mcqQuestions;
    const questionType = activeTab === 1 ? "coding" : "mcq";

    const selectedIds = new Set(
      section.selectedQuestions[questionType].map((q) =>
        questionType === "coding" ? q.id : q.mcq_question_id
      )
    );

    const filtered = searchQuery
      ? questions.filter((q) =>
          (q.title || q.question_text || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : questions;

    return filtered.sort((a, b) => {
      const aSelected = selectedIds.has(
        questionType === "coding" ? a.id : a.mcq_question_id
      );
      const bSelected = selectedIds.has(
        questionType === "coding" ? b.id : b.mcq_question_id
      );
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  };

  const handleToggleQuestion = (question) => {
    const type = question.type;
    const questionId =
      type === "coding" ? question.id : question.mcq_question_id;

    // Check if question is already used in another section
    const questionSection = getQuestionSection(question);
    if (questionSection && questionSection.id !== section.id) {
      toast.warning(
        `This question is already used in ${questionSection.title}`
      );
      return;
    }

    const currentSelected = section.selectedQuestions[type];
    let newSelected;

    if (
      currentSelected.some((q) =>
        type === "coding"
          ? q.id === questionId
          : q.mcq_question_id === questionId
      )
    ) {
      // Remove question
      newSelected = currentSelected.filter((q) =>
        type === "coding"
          ? q.id !== questionId
          : q.mcq_question_id !== questionId
      );
      setUsedQuestions((prev) => ({
        ...prev,
        [type]: new Set([...prev[type]].filter((id) => id !== questionId)),
      }));
    } else {
      // Add question
      newSelected = [...currentSelected, question];
      setUsedQuestions((prev) => ({
        ...prev,
        [type]: new Set([...prev[type], questionId]),
      }));
    }

    handleUpdateSection("selectedQuestions", {
      ...section.selectedQuestions,
      [type]: newSelected,
    });
  };

  const getQuestionSection = (question) => {
    const questionId =
      question.type === "coding" ? question.id : question.mcq_question_id;
    const sectionWithQuestion = allSections.find((s) =>
      s.selectedQuestions[question.type].some((q) =>
        question.type === "coding"
          ? q.id === questionId
          : q.mcq_question_id === questionId
      )
    );
    return sectionWithQuestion;
  };

  const getGroupSection = (group) => {
    return allSections.find((s) =>
      s.selectedGroups?.some((g) => g.groupId === group.group_id)
    );
  };

  const isQuestionFromSelectedGroup = (question) => {
    const questionId =
      question.type === "coding" ? question.id : question.mcq_question_id;

    // Check if question is part of any selected group
    return Array.from(selectedGroups.values()).some((groupQuestions) =>
      groupQuestions.some(
        (gq) =>
          gq.type === question.type &&
          (gq.type === "coding"
            ? gq.details.question_id === questionId
            : gq.details.mcq_question_id === questionId)
      )
    );
  };

  const renderQuestionsTable = () => {
    const questions = filteredQuestions();
    const isCodingTab = activeTab === 1;

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {isCodingTab ? (
                <>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </>
              ) : (
                <>
                  <TableCell>Question Text</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Difficulty</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question) => {
              const questionSection = getQuestionSection(question);
              const isQuestionInCurrentSection = section.selectedQuestions[
                isCodingTab ? "coding" : "mcq"
              ].some((q) =>
                isCodingTab
                  ? q.id === question.id
                  : q.mcq_question_id === question.mcq_question_id
              );
              const isFromGroup = isQuestionFromSelectedGroup(question);
              const isInOtherSection =
                questionSection && questionSection.id !== section.id;

              return (
                <TableRow key={question.id || question.mcq_question_id}>
                  {isCodingTab ? (
                    <>
                      <TableCell>{question.title}</TableCell>
                      <TableCell sx={{ maxWidth: "400px" }}>
                        <div
                          dangerouslySetInnerHTML={{ __html: question.content }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.question_text,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.question_description,
                          }}
                        />
                      </TableCell>
                      <TableCell>{question.difficulty}</TableCell>
                      <TableCell>{question.points}</TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    <Box
                      sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                    >
                      <IconButton
                        onClick={() => {
                          setViewQuestion(question);
                          setIsViewModalOpen(true);
                        }}
                        color="primary"
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {isInOtherSection ? (
                        <Chip
                          label={`Used in ${questionSection.title}`}
                          color="warning"
                          size="small"
                        />
                      ) : (
                        <Tooltip
                          title={
                            isFromGroup
                              ? "This question is part of an added question group"
                              : ""
                          }
                        >
                          <span>
                            <Button
                              size="small"
                              color={
                                isQuestionInCurrentSection ? "error" : "success"
                              }
                              onClick={() => handleToggleQuestion(question)}
                              disabled={isFromGroup}
                            >
                              {isQuestionInCurrentSection && !isFromGroup
                                ? "Remove"
                                : "Add"}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  //   Question Groups
  const renderQuestionGroupsTable = () => {
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Questions Count</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionGroups
              .filter((group) =>
                group.group_name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .map((group) => {
                const groupSection = getGroupSection(group);
                const isInCurrentSection = section.selectedGroups?.some(
                  (g) => g.groupId === group.group_id
                );
                const isInOtherSection =
                  groupSection && groupSection.id !== section.id;

                return (
                  <TableRow key={group.group_id}>
                    <TableCell>{group.group_name}</TableCell>
                    <TableCell>{group.group_description}</TableCell>
                    <TableCell>{group.questions_count} Questions</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          onClick={() => handleViewGroup(group)}
                          color="primary"
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {isInOtherSection ? (
                          <Tooltip
                            title={`Already used in ${groupSection.title}`}
                          >
                            <Chip
                              label={`Used in ${groupSection.title}`}
                              color="warning"
                              size="small"
                            />
                          </Tooltip>
                        ) : (
                          <Button
                            size="small"
                            color={isInCurrentSection ? "error" : "success"}
                            onClick={() => handleAddGroup(group)}
                          >
                            {isInCurrentSection ? "Remove" : "Add"}
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Question Groups helper functions
  const isGroupSelected = (group) => {
    // First check if it's selected in current section
    const isInCurrentSection = section.selectedGroups?.some(
      (g) => g.groupId === group.group_id
    );
    if (isInCurrentSection) return true;

    // Then check if it's selected in any other section
    return allSections.some(
      (s) =>
        s.id !== section.id &&
        s.selectedGroups?.some((g) => g.groupId === group.group_id)
    );
  };

  const handleAddGroup = async (group, isInitial = false) => {
    try {
      // If loading initial data during edit
      if (isInitial) {
        const response = await axios.get(
          `${BASE_URL}/api/org/group/get-group/${group.groupId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const groupQuestions = response.data.questions;

        // Store group questions in the map
        setSelectedGroups(
          (prev) => new Map(prev.set(group.groupId, groupQuestions))
        );

        // No need to check for existing selections or update section
        // as this is initial data loading
        return;
      }

      // Regular add/remove group flow
      // Check if group is already used in another section
      const groupSection = getGroupSection(group);
      if (groupSection && groupSection.id !== section.id) {
        toast.warning(`This group is already used in ${groupSection.title}`);
        return;
      }

      const isCurrentlySelected = section.selectedGroups?.some(
        (g) => g.groupId === group.group_id
      );

      if (isCurrentlySelected) {
        // Remove group
        const updatedSection = {
          ...section,
          selectedGroups: section.selectedGroups.filter(
            (g) => g.groupId !== group.group_id
          ),
        };

        // Remove group questions from selectedQuestions
        const groupQuestions = selectedGroups.get(group.group_id) || [];
        updatedSection.selectedQuestions = {
          coding: section.selectedQuestions.coding.filter(
            (q) =>
              !groupQuestions.some(
                (gq) => gq.type === "coding" && gq.details.question_id === q.id
              )
          ),
          mcq: section.selectedQuestions.mcq.filter(
            (q) =>
              !groupQuestions.some(
                (gq) =>
                  gq.type === "mcq" &&
                  gq.details.mcq_question_id === q.mcq_question_id
              )
          ),
        };

        // Update state
        setSelectedGroups((prev) => {
          const next = new Map(prev);
          next.delete(group.group_id);
          return next;
        });
        onUpdate(updatedSection);
      } else {
        // Add group
        const response = await axios.get(
          `${BASE_URL}/api/org/group/get-group/${group.group_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const groupQuestions = response.data.questions;

        // Store group questions
        setSelectedGroups(
          (prev) => new Map(prev.set(group.group_id, groupQuestions))
        );

        // Prepare questions array for the group
        const questionsForGroup = groupQuestions.map((gq) => ({
          type: gq.type,
          questionId:
            gq.type === "coding"
              ? gq.details.question_id
              : gq.details.mcq_question_id,
        }));

        // Update section
        const updatedSection = {
          ...section,
          selectedGroups: [
            ...(section.selectedGroups || []),
            {
              groupId: group.group_id,
              questions: questionsForGroup,
            },
          ],
          selectedQuestions: {
            coding: [
              ...section.selectedQuestions.coding,
              ...groupQuestions
                .filter((q) => q.type === "coding")
                .map((q) => ({
                  ...q.details,
                  id: q.details.question_id,
                  type: "coding",
                })),
            ],
            mcq: [
              ...section.selectedQuestions.mcq,
              ...groupQuestions
                .filter((q) => q.type === "mcq")
                .map((q) => ({ ...q.details, type: "mcq" })),
            ],
          },
        };

        onUpdate(updatedSection);
      }
    } catch (error) {
      console.error("Error handling group:", error);
      toast.error("Failed to process question group");
    }
  };

  const handleViewGroup = async (group) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/group/get-group/${group.group_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.questions) {
        setViewGroup({
          groupData: response.data.groupData,
          questions: response.data.questions,
        });
      } else {
        toast.error("Group details are not available");
      }
    } catch (error) {
      console.error("Error fetching group details:", error);
      toast.error("Failed to fetch group details");
    }
  };
  //   --Question Groups

  // question count display
  const getTotalQuestionCount = (type) => {
    const standardQuestions = section.selectedQuestions[type];
    const groupQuestions = Array.from(selectedGroups.values())
      .flat()
      .filter((q) => q.type === type);

    // Avoid double-counting by deduplicating questions across standard and group questions
    const uniqueQuestionIds = new Set([
      ...standardQuestions.map((q) =>
        type === "coding" ? q.id : q.mcq_question_id
      ),
      ...groupQuestions.map((q) =>
        type === "coding" ? q.details.question_id : q.details.mcq_question_id
      ),
    ]);

    return uniqueQuestionIds.size; // Correct count
  };

  return (
    <Paper sx={{ mb: 3, overflow: "hidden" }}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "primary.light",
          color: "white",
        }}
      >
        <Typography variant="h6">{section.title}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {canDelete && (
            <IconButton onClick={onDelete} size="small" sx={{ color: "white" }}>
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => setExpanded(!expanded)}
            size="small"
            sx={{ color: "white" }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded}>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Section Name"
                value={section.title}
                onChange={(e) => handleUpdateSection("title", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Section Duration (minutes)"
                type="number"
                value={section.duration}
                onChange={(e) =>
                  handleUpdateSection("duration", e.target.value)
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Select Languages for Coding Questions</InputLabel>
                <Select
                  multiple
                  value={section.allowedLanguages.map((lang) =>
                    typeof lang === "string" ? lang : lang.languageId
                  )}
                  onChange={(e) =>
                    handleUpdateSection("allowedLanguages", e.target.value)
                  }
                  input={
                    <OutlinedInput label="Select Languages for Coding Questions" />
                  }
                  disabled={isLoadingLanguages}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((langId) => (
                        <Chip
                          key={langId}
                          label={
                            allowedLanguages.find((lang) => lang.id === langId)
                              ?.language ||
                            section.allowedLanguages.find(
                              (lang) =>
                                typeof lang === "object" &&
                                lang.languageId === langId
                            )?.language ||
                            langId
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {isLoadingLanguages ? (
                    <MenuItem disabled>Loading languages...</MenuItem>
                  ) : (
                    allowedLanguages.map((lang) => (
                      <MenuItem key={lang.id} value={lang.id}>
                        {lang.language}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={section.shuffleQuestions}
                    onChange={(e) =>
                      handleUpdateSection("shuffleQuestions", e.target.checked)
                    }
                  />
                }
                label="Shuffle Questions"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={section.allowNavigation}
                    onChange={(e) =>
                      handleUpdateSection("allowNavigation", e.target.checked)
                    }
                  />
                }
                label="Allow Question Navigation"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Add Questions to Section
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <TextField
                placeholder="Search questions..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 300 }}
              />

              <Box sx={{ display: "flex", gap: 2 }}>
                <Chip
                  label={`Coding Questions: ${getTotalQuestionCount("coding")}`}
                  color="primary"
                  variant="outlined"
                />
                <Chip
                  label={`MCQ Questions: ${getTotalQuestionCount("mcq")}`}
                  color="secondary"
                  variant="outlined"
                />
              </Box>
            </Box>

            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
            >
              <Tab label="Question Groups" />
              <Tab label="Coding Questions" />
              <Tab label="MCQ Questions" />
            </Tabs>

            {activeTab === 0
              ? renderQuestionGroupsTable()
              : renderQuestionsTable()}
          </Box>
        </Box>
      </Collapse>

      <QuestionViewModal
        open={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        question={viewQuestion}
      />
      <QuestionGroupViewModal
        open={!!viewGroup} // Ensure this is correctly evaluating to true
        onClose={() => setViewGroup(null)}
        group={viewGroup}
      />
    </Paper>
  );
};

export default TestSection;
