import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Code,
  BookOpen,
  Trophy,
  Users,
  Briefcase,
  Settings,
  Target,
  Shield,
  BarChart2,
  CheckCircle,
  Monitor,
  Play,
  Star,
  Layout,
  GitBranch,
  Award,
  Clock,
  Coffee,
  Zap,
  Terminal,
} from "lucide-react";
import axios from "axios";
import SubFooter from "../components/Home/SubFooter";
import FooterHome from "../components/FooterHome";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export default function PremiumFeatureSection() {
  const [startCount, setStartCount] = useState(false);
  const [data, setData] = useState({
    users_count: 0,
    tests_count: 0,
    participants_count: 0,
  });

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/metrics`
      );
      setData(response.data.data);
    } catch (error) {
      console.error("error getting data", error);
    }
  };

  const platformStats = [
    {
      icon: <Code className="w-6 h-6" />,
      value: <span className="text-3xl font-bold text-blue-600">30+</span>,
      label: "Programming Languages",
      detail: "From C++ to Python, we support all major languages",
    },
    {
      icon: <Trophy className="w-6 h-6" />,
      value: (
        <div className="text-3xl font-bold text-blue-600">
          {startCount && <CountUp end={data.tests_count} duration={2} />}+
        </div>
      ),
      label: "Assessments Conducted",
      detail: "Trusted by leading organizations worldwide",
    },
    {
      icon: <Users className="w-6 h-6" />,
      value: (
        <div className="text-3xl font-bold text-blue-600">
          {startCount && <CountUp end={data.paricipants_count} duration={2} />}+
        </div>
      ),
      label: "Active Users",
      detail: "Growing community of developers",
    },
    {
      icon: <Award className="w-6 h-6" />,
      value: (
        <div className="text-3xl font-bold text-blue-600">
          {startCount && <CountUp end={data.users_count} duration={2} />}+
        </div>
      ),
      label: "Organizations",
      detail: "Trusted partner for technical assessments",
    },
  ];

  const features = {
    assessment: [
      {
        icon: <Terminal className="w-8 h-8 text-purple-500" />,
        title: "Advanced Code Assessment",
        description: "Enterprise-grade technical evaluation platform",
        highlights: [
          "Real-time code execution and testing",
          "Custom test case creation",
          "Automated plagiarism detection",
          "Multiple programming language support",
          "Advanced code analysis tools",
        ],
      },
      {
        icon: <Shield className="w-8 h-8 text-blue-500" />,
        title: "Secure Testing Environment",
        description: "Bank-grade security for your assessments",
        highlights: [
          "End-to-end encryption",
          "Secure browser monitoring",
          "IP-based access control",
          "Anti-cheating mechanisms",
          "Data privacy compliance",
        ],
      },
      {
        icon: <BarChart2 className="w-8 h-8 text-green-500" />,
        title: "Comprehensive Analytics",
        description: "Deep insights into candidate performance",
        highlights: [
          "Detailed performance metrics",
          "Comparative analysis",
          "Skill-based evaluation",
          "Custom report generation",
          "Trend analysis and insights",
        ],
      },
    ],
    learning: [
      {
        icon: <BookOpen className="w-8 h-8 text-orange-500" />,
        title: "Interactive Learning",
        description: "Comprehensive learning resources",
        highlights: [
          "Video tutorials from experts",
          "Interactive coding exercises",
          "Real-world project scenarios",
          "Progressive learning paths",
          "Hands-on practice sessions",
        ],
      },
      {
        icon: <Target className="w-8 h-8 text-red-500" />,
        title: "Skill Development",
        description: "Structured approach to mastery",
        highlights: [
          "Topic-wise practice",
          "Difficulty progression",
          "Skill assessment tests",
          "Performance tracking",
          "Personalized recommendations",
        ],
      },
      {
        icon: <GitBranch className="w-8 h-8 text-indigo-500" />,
        title: "Practice Platform",
        description: "Extensive practice environment",
        highlights: [
          "Public practice tests",
          "Company-specific preparations",
          "Contest participation",
          "Peer programming",
          "Code reviews",
        ],
      },
    ],
    organization: [
      {
        icon: <Layout className="w-8 h-8 text-teal-500" />,
        title: "Organization Dashboard",
        description: "Complete control over assessments",
        highlights: [
          "Test creation and management",
          "Candidate management",
          "Result analysis",
          "Team collaboration",
          "Custom branding options",
        ],
      },
      {
        icon: <Settings className="w-8 h-8 text-cyan-500" />,
        title: "Customizable Workflows",
        description: "Adapt to your organization's needs",
        highlights: [
          "Custom evaluation criteria",
          "Flexible scoring systems",
          "Interview process integration",
          "API integration options",
          "Automated workflows",
        ],
      },
      {
        icon: <Zap className="w-8 h-8 text-yellow-500" />,
        title: "Enterprise Features",
        description: "Built for scale and efficiency",
        highlights: [
          "Bulk operations support",
          "Advanced access control",
          "Single Sign-On (SSO)",
          "Audit logs",
          "Priority support",
        ],
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-6 lg:mb-8 leading-tight">
              Transform Your Technical Assessment Process
            </h1>
            <p className="text-base sm:text-lg lg:text-xl text-gray-600 dark:text-gray-300 mb-8 sm:mb-10 lg:mb-12 max-w-3xl mx-auto px-4">
              CirrusCypher combines powerful assessment tools, comprehensive
              learning resources, and enterprise-grade features to revolutionize
              technical evaluation.
            </p>

            {/* Platform Statistics */}
            <div
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8 mt-8 sm:mt-12 lg:mt-16 px-4"
              ref={ref}
            >
              {platformStats.map((stat, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-5 lg:p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="flex items-center justify-center mb-3 sm:mb-4">
                    <div className="p-2 sm:p-3 bg-blue-100 dark:bg-blue-900 rounded-full">
                      {stat.icon}
                    </div>
                  </div>
                  <div className="text-2xl sm:text-3xl font-bold text-blue-600 dark:text-blue-400 mb-1 sm:mb-2">
                    {stat.value}
                  </div>
                  <div className="text-xs sm:text-sm font-medium text-gray-900 dark:text-white mb-1 sm:mb-2">
                    {stat.label}
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400">
                    {stat.detail}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main Features Sections */}
      {Object.entries(features).map(([section, sectionFeatures]) => (
        <div
          key={section}
          id={section}
          className="py-12 sm:py-16 lg:py-24 px-4 sm:px-6 lg:px-8"
        >
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-8 sm:mb-12 lg:mb-16">
              <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-6">
                {section.charAt(0).toUpperCase() + section.slice(1)} Features
              </h2>
              <div className="w-16 sm:w-24 h-1 bg-blue-500 mx-auto rounded-full mb-4 sm:mb-6" />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {sectionFeatures.map((feature, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 rounded-xl p-6 sm:p-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="flex flex-col sm:flex-row items-start sm:items-center mb-6">
                    <div className="p-2 sm:p-3 bg-gray-50 dark:bg-gray-700 rounded-xl mb-4 sm:mb-0 sm:mr-4">
                      {feature.icon}
                    </div>
                    <div>
                      <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white mb-1 sm:mb-2">
                        {feature.title}
                      </h3>
                      <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                        {feature.description}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2 sm:space-y-3">
                    {feature.highlights.map((highlight, idx) => (
                      <div
                        key={idx}
                        className="flex items-start text-gray-600 dark:text-gray-300"
                      >
                        <CheckCircle className="w-4 sm:w-5 h-4 sm:h-5 text-green-500 mr-2 sm:mr-3 flex-shrink-0 mt-0.5" />
                        <span className="text-xs sm:text-sm">{highlight}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      {/* Call to Action */}
      <div
        className="relative py-12 sm:py-16 lg:py-24 px-4 sm:px-6 lg:px-8"
        style={{ background: "var(--primary-bg-color)" }}
      >
        <div className="absolute inset-0 opacity-20">
          <div className="absolute inset-0 bg-grid-white/[0.2] bg-[size:16px_16px]" />
        </div>
        <div className="relative max-w-7xl mx-auto text-center">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 sm:mb-6">
            Ready to Revolutionize Your Technical Assessment?
          </h2>
          <p className="text-base sm:text-lg lg:text-xl text-blue-100 mb-8 sm:mb-10 lg:mb-12 max-w-3xl mx-auto px-4">
            Join leading organizations worldwide in delivering exceptional
            technical assessments with CirrusCypher's comprehensive platform.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6 px-4">
            <button
              className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 bg-white text-blue-600 rounded-xl font-semibold hover:bg-blue-50 transition-colors duration-300 shadow-lg text-sm sm:text-base"
              onClick={() => navigate("/get-started")}
            >
              Start Free Trial
            </button>

            <button
              className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 bg-transparent border-2 border-white text-white rounded-xl font-semibold hover:bg-blue-700 transition-colors duration-300 text-sm sm:text-base"
              onClick={() => (window.location.href = "tel:+919175046101")}
            >
              Schedule Demo
            </button>
          </div>
        </div>
      </div>
      <SubFooter />
    </div>
  );
}
