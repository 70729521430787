import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Avatar,
  Divider,
  ListItemIcon,
} from '@mui/material';
import {
  Person as PersonIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon,
  Home as HomeIcon,
} from '@mui/icons-material';

const UserDashboardHeader = ({ organizationName, onEditProfile }) => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    navigate('/login');
  };

  const handleEditProfile = () => {
    handleClose();
    if (onEditProfile) {
      onEditProfile();
    }
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'background.paper', color: 'text.primary', boxShadow: 2 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/logos/cirruscypher-name-multi-alpha.png"
              alt="TechnoCirrus Logo"
              style={{ height: '40px' }}
            />
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                cursor: 'pointer' 
              }}
              onClick={handleHomeClick}
            >
              <HomeIcon />
              <Typography variant="body1">Home</Typography>
            </Box>
          </Box>
          {/* {organizationName && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6" component="div" sx={{ fontWeight: 600, color: '#292929' }}>
                {organizationName}
              </Typography>
            </>
          )} */}
        </Box>

        {/* Right section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            size="large"
            color="default"
            sx={{ '&:hover': { bgcolor: 'action.hover' } }}
          >
            <NotificationsIcon />
          </IconButton>
          
          <IconButton
            onClick={handleMenu}
            size="small"
            sx={{ 
              ml: 1,
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: 'primary.main',
                color: 'primary.contrastText'
              }}
            >
              <PersonIcon />
            </Avatar>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              elevation: 3,
              sx: {
                mt: 1.5,
                minWidth: 200,
                '& .MuiMenuItem-root': {
                  py: 1,
                  px: 2
                }
              }
            }}
          >
            {userRole !== 'USER' && (
          <MenuItem onClick={handleEditProfile}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit Profile
          </MenuItem>
        )}
            <Divider />
            <MenuItem onClick={handleLogout} sx={{ color: 'error.main' }}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" sx={{ color: 'error.main' }} />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default UserDashboardHeader;