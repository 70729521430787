import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  styled,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Container,
  Alert,
  Fade,
  CircularProgress,
  Divider,
  Avatar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  Save as SaveIcon,
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  CloudUpload as CloudUploadIcon,
  AccountCircle as AccountIcon,
  Security as SecurityIcon,
  Notifications as NotificationIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import axios from "axios";

// Styled components for consistency and professional look
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: 16,
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 16px rgba(0,0,0,0.15)",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  textTransform: "none",
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  boxShadow: "none",
  "&:hover": {
    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const VisualDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
  margin: "0 auto",
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const CandidateSetting = () => {
  // State for form fields
  const [username, setUsername] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // OTP-related states
  const [otp, setOtp] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  // UI and interaction states
  const [showPassword, setShowPassword] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ type: "", message: "" });
  const [avatarUrl, setAvatarUrl] = useState("/default-avatar.png");
  const [profileName, setProfileName] = useState("");

  // Configuration
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  // Initialize component with user data from localStorage
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedEmail = localStorage.getItem("email");

    if (storedUsername) setUsername(storedUsername);
    if (storedEmail) setCurrentEmail(storedEmail);
  }, []);

  // Password strength indicator
  const getPasswordStrength = (password) => {
    if (!password) return { strength: 0, label: "" };
    const strength =
      (password.length >= 8) +
      /[A-Z]/.test(password) +
      /[a-z]/.test(password) +
      /[0-9]/.test(password) +
      /[^A-Za-z0-9]/.test(password);

    const labels = ["Very Weak", "Weak", "Fair", "Good", "Strong"];
    return {
      strength: (strength / 5) * 100,
      label: labels[strength - 1] || "",
    };
  };

  const passwordStrength = getPasswordStrength(newPassword);

  // Feedback display utility
  const showFeedback = (type, message) => {
    setFeedback({ type, message });
    setTimeout(() => setFeedback({ type: "", message: "" }), 5000);
  };

  // Username update handler
  const handleUsernameSave = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/api/candidate/settings/username`,
        { username }
      );

      // Update localStorage
      localStorage.setItem("username", username);

      showFeedback("success", "Username updated successfully!");
    } catch (error) {
      showFeedback(
        "error",
        error.response?.data?.message || "Failed to update username"
      );
    } finally {
      setLoading(false);
    }
  };
  // Send OTP for email verification
  const handleSendOTP = async () => {
    if (!newEmail) {
      showFeedback("error", "Please enter a new email");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/mail/otp`, {
        email: newEmail,
      });

      showFeedback("success", "OTP sent to your email");
      setOtpSent(true);
      setOtpDialogOpen(true);
    } catch (error) {
      showFeedback(
        "error",
        error.response?.data?.message || "Failed to send OTP"
      );
    } finally {
      setLoading(false);
    }
  };


 // Verify OTP
 const handleVerifyOTP = async () => {
  if (!otp) {
    showFeedback("error", "Please enter the OTP");
    return;
  }

  setLoading(true);
  try {
    // First verify the OTP
    const otpResponse = await axios.post(`${BASE_URL}/api/mail/otp/verify`, {
      email: newEmail,
      otp: otp,
    });

    if (otpResponse.data.success === false) {
      showFeedback("error", otpResponse.data.error);
      return;
    }

    // If OTP verification succeeds, try to update the email
    try {
      await handleEmailVerification();
    } catch (error) {
      // handleEmailVerification will handle showing the error message
      return;
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error || "OTP verification failed";
    showFeedback("error", errorMessage);
    setOtpDialogOpen(true);
  } finally {
    setLoading(false);
  }
};

  // Email verification handler
  const handleEmailVerification = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/api/candidate/settings/email`,
        { email: newEmail }
      );

      // Update localStorage
      localStorage.setItem("email", newEmail);
      setCurrentEmail(newEmail);

      showFeedback("success", "Email updated successfully!");
      setEmailVerified(true);
      setNewEmail("");
    } catch (error) {
      showFeedback(
        "error",
        error.response?.data?.error || "Failed to update email"
      );
    } finally {
      setLoading(false);
    }
  };

  // Password change handler
  const handlePasswordChange = async () => {
    // Validate password match
    if (newPassword !== confirmPassword) {
      showFeedback("error", "New passwords do not match!");
      return;
    }

    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/api/candidate/settings/password`, {
        currentPassword,
        newPassword,
      });

      showFeedback("success", "Password changed successfully!");

      // Reset password fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      showFeedback(
        "error",
        error.response?.data?.error || "Failed to change password"
      );
    } finally {
      setLoading(false);
    }
  };

  // Avatar upload handler
  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create FormData to send file
      const formData = new FormData();

      formData.append("profile_photo", file);
      formData.append("candidateId", localStorage.getItem("candidateId"));

      try {
        setLoading(true);

        // Upload file to the server
        const response = await axios.post(
          `${BASE_URL}/api/upload/candidate`,
          formData
        );

        // Extract the S3 URL directly
        const uploadedFileUrl = response.data.profilePhotoUrl;
        setAvatarUrl(uploadedFileUrl);

        // Show success feedback
        showFeedback("success", "Profile photo uploaded successfully");
      } catch (error) {
        console.error("Avatar upload error:", error);
        showFeedback(
          "error",
          error.response?.data?.error || "Failed to upload profile photo"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchProfileName = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/candidate/profile`);

        const { candidate } = response.data.data;

        // Set profile name from backend
        setProfileName(candidate.profile_name);
      } catch (error) {
        console.error("Error fetching profile name:", error);
      }
    };

    fetchProfileName();
  }, [BASE_URL]);

  // Fetch existing profile photo on component mount
  useEffect(() => {
    const fetchProfilePhoto = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/candidate/profile`);

        // If a profile photo exists, update the avatar URL
        if (response.data.photoUrl) {
          setAvatarUrl(response.data.photoUrl);
        }
      } catch (error) {
        console.error("Error fetching profile photo:", error);
      }
    };

    fetchProfilePhoto();
  }, [BASE_URL]);

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/candidate/profile`);

        const { candidate } = response.data.data;

        // Set profile name
        setProfileName(candidate.profile_name);

        // Set profile photo URL
        // Directly use the profilePhotoUrl from the response
        if (candidate.profilePhotoUrl) {
          setAvatarUrl(candidate.profilePhotoUrl);
        }

        // Set email
        setCurrentEmail(candidate.email);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, [BASE_URL]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 3,
          backgroundColor: "background.default",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
            Account Settings
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Manage your account settings and preferences
          </Typography>
        </Box>

        {feedback.message && (
          <Fade in={!!feedback.message}>
            <Alert
              severity={feedback.type}
              sx={{ mb: 3, borderRadius: 2 }}
              onClose={() => setFeedback({ type: "", message: "" })}
            >
              {feedback.message}
            </Alert>
          </Fade>
        )}

        <Grid container spacing={4}>
          {/* Profile Picture Section */}
          <Grid item xs={12} md={4}>
            <StyledCard>
              <StyledCardContent sx={{ textAlign: "center" }}>
                <input
                  type="file"
                  accept="image/*"
                  id="avatar-upload"
                  style={{ display: "none" }}
                  onChange={handleAvatarUpload}
                  disabled={loading}
                />
                <label htmlFor="avatar-upload">
                  <StyledAvatar
                    src={avatarUrl}
                    sx={{ opacity: loading ? 0.5 : 1 }}
                  />
                  {loading && (
                    <CircularProgress
                      size={40}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                </label>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {profileName || "Your Name"}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {currentEmail || "your.email@example.com"}
                </Typography>
                <Button
                  component="label"
                  htmlFor="avatar-upload"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  sx={{ borderRadius: 20 }}
                  disabled={loading}
                >
                  {loading ? "Uploading..." : "Update Photo"}
                </Button>
              </StyledCardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={8}>
            {/* Username Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <AccountIcon />
                  Profile Information
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={handleUsernameSave}
                              disabled={loading}
                              startIcon={
                                loading ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <SaveIcon />
                                )
                              }
                            >
                              Save Changes
                            </StyledButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>

            {/* Email Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <EmailIcon />
                  Email Settings
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Current Email"
                      value={currentEmail}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon color="action" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="New Email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={handleSendOTP}
                              disabled={
                                loading ||
                                !newEmail ||
                                newEmail === currentEmail
                              }
                            >
                              Send OTP
                            </StyledButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>

            {/* OTP Verification Dialog */}
            <Dialog
              open={otpDialogOpen}
              onClose={() => setOtpDialogOpen(false)}
            >
              <DialogTitle>Verify Your Email</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter the 6-digit OTP sent to {newEmail}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="OTP"
                  type="text"
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  inputProps={{ maxLength: 6 }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOtpDialogOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleVerifyOTP}
                  color="primary"
                  disabled={loading || otp.length !== 6}
                >
                  {loading ? <CircularProgress size={24} /> : "Verify"}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Password Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <SecurityIcon />
                  Security Settings
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Current Password"
                      type={showPassword ? "text" : "password"}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      helperText={`Password strength: ${passwordStrength.label}`}
                      FormHelperTextProps={{
                        sx: {
                          color:
                            passwordStrength.strength > 60
                              ? "success.main"
                              : "warning.main",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Confirm New Password"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={confirmPassword && newPassword !== confirmPassword}
                      helperText={
                        confirmPassword && newPassword !== confirmPassword
                          ? "Passwords do not match"
                          : " "
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ mt: 2 }}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePasswordChange}
                        disabled={
                          loading ||
                          !currentPassword ||
                          !newPassword ||
                          !confirmPassword
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <LockIcon />
                          )
                        }
                      >
                        Update Password
                      </StyledButton>
                    </Box>
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CandidateSetting;
