import React, { useEffect } from "react";
const OutputWindow = ({ outputDetails }) => {
  useEffect(() => {
    // Debug log to track output details changes
    console.log("Output Details Updated:", outputDetails);
  }, [outputDetails]);
  const getOutput = () => {
    if (!outputDetails) return null;
    // Show stderr (runtime errors)
    if (outputDetails.stderr) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {outputDetails.stderr}
        </pre>
      );
    }
    // Show compilation errors
    if (outputDetails.compile_output) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {outputDetails.compile_output}
        </pre>
      );
    }
    // Show standard output for Accepted status
    if (outputDetails.status?.id === 3 && outputDetails.stdout) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-green-500">
          {outputDetails.stdout}
        </pre>
      );
    }
    // Show time limit exceeded
    if (outputDetails.status?.id === 5) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          Time Limit Exceeded
        </pre>
      );
    }
    // Show other status messages
    return (
      <pre className="px-2 py-1 font-normal text-xs text-gray-500">
        {outputDetails.status?.description || "No output"}
      </pre>
    );
  };
  return (
    <>
      <h1 className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
        Output
      </h1>
      <div className="w-full h-56 bg-white rounded-md text-white font-normal text-sm overflow-y-auto">
        {getOutput()}
      </div>
    </>
  );
};
export default OutputWindow;
