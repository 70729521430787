import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/LoginForm.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { showNotification } from "../utils/notification"; // Import the notification utility
import OAuthLogin from "./OAuthLogin";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState("initial"); // states: initial, otpSent, otpVerified
  const [passwordError, setPasswordError] = useState("");
  const [role, setRole] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/auth/login`,
        { email, password }
      );
      if (response.data.error === null) {
        const { token, user_id, role, username, candidateId } = response.data;

        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
        localStorage.setItem("email", email);

     
        
        if (role === "candidate") {
          localStorage.setItem("candidateId", candidateId);
          localStorage.setItem("username", username); // Save candidate-specific details
          localStorage.setItem("email", email);
          navigate("/profilepage");
        } else {
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("role", role);

          if (role === "ADMIN") {
            navigate("/admin/dashboard");
          } else {
            navigate("/user/dashboard");
          }
        }
      }
    } catch (error) {
      console.error("Error logging in:", error);
      showNotification("error", "Invalid Username or Password");
    }
  };

  const handleForgotPassword = async () => {
    if (!resetEmail) {
      showNotification("error", "Please enter your email address");
      return;
    }

    try {
      const queryResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/mail/valid`,
        {
          params: { email: resetEmail },
        }
      );

      if (queryResponse.status === 200) {
        setRole(queryResponse.data.role);
        // console.log("role", queryResponse.data.role);
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/api/mail/otp`,
          { email: resetEmail }
        );
        if (response.status === 200) {
          showNotification("success", "OTP sent to your email");
          setForgotPasswordStep("otpSent");
        } else {
          console.error("Failed to send OTP:", response.data.error);
          showNotification("error", "Failed to send OTP. Please try again.");
        }
      } else {
        console.error("Email is not registered");
        showNotification("error", "Email is not registered.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      showNotification(
        "error",
        "Failed to send OTP. Please check if email is correct and try again."
      );
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/otp/verify`,
        { email: resetEmail, otp }
      );
      if (response.status === 200) {
        setForgotPasswordStep("otpVerified");
      } else {
        console.error("Invalid OTP:", response.data.error);
        showNotification("error", "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      showNotification(
        "error",
        "Failed to verify OTP. Please check if OTP is correct and try again."
      );
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/auth/reset-password`,
        { email: resetEmail, password: newPassword, role }
      );
      if (response.status === 200) {
        showNotification("success", "Password reset successfully!");
        handleCloseForgotPasswordModal();
      } else {
        console.error("Failed to reset password:", response.data.error);
        showNotification(
          "error",
          "Failed to reset password. Please try again."
        );
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      showNotification("error", "Failed to reset password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
    // Reset all fields and steps
    setResetEmail("");
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    setShowNewPassword(false);
    setForgotPasswordStep("initial");
  };

  return (
    <div className="login-form-container">
      <img src="/assets/login.jpg" alt="Organization Logo" className="logo" />
      <h2 className="login-form-title">Login</h2>
      <p className="login-form-subtitle">
        Welcome back! Please sign in to continue.
      </p>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-form-input"
          required
        />
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-form-input"
          required
        />
        <button type="submit" className="login-form-button">
          Login
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className="signup-name">
            Not yet registered?{" "}
            <a className="signup-link" href="/signup">
              Click here
            </a>
          </p>

          <p
            className="forgot-password-link"
            onClick={handleOpenForgotPasswordModal}
          >
            Forgot Password?
          </p>
        </div>
      </form>

      <div className="oauth-login-container mt-6">
        <OAuthLogin />
      </div>

      {/* Forgot Password Modal */}
      <Dialog
        open={openForgotPasswordModal}
        onClose={handleCloseForgotPasswordModal}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {forgotPasswordStep === "initial" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="resetEmail"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <Button onClick={handleForgotPassword}>Verify Email</Button>
            </>
          )}
          {forgotPasswordStep === "otpSent" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="otp"
                label="Enter OTP"
                type="text"
                fullWidth
                variant="standard"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button onClick={handleVerifyOtp}>Verify OTP</Button>
            </>
          )}
          {forgotPasswordStep === "otpVerified" && (
            <>
              <TextField
                margin="dense"
                id="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                fullWidth
                variant="standard"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle new password visibility"
                        onClick={toggleNewPasswordVisibility}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                id="confirmPassword"
                label="Confirm Password"
                type={showNewPassword ? "text" : "password"}
                fullWidth
                variant="standard"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
              <Button onClick={handleResetPassword}>Reset Password</Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginForm;
