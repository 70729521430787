import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";

// Import icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const CandidateLinks = ({
  open,
  onClose,
  initialLinks = [],
  fetchLatestProfile,
}) => {
  // Initialize links with proper structure including IDs from backend
  const [links, setLinks] = useState(
    initialLinks.length > 0
      ? initialLinks.map((link) => ({
          id: link.id,
          platform: link.platform || determinePlatform(link.url),
          url: link.url || "",
          icon: getPlatformIcon(link.platform || determinePlatform(link.url)),
        }))
      : [
          { platform: "LinkedIn", url: "", icon: <LinkedInIcon /> },
          { platform: "GitHub", url: "", icon: <GitHubIcon /> },
          { platform: "Portfolio", url: "", icon: <LanguageIcon /> },
        ]
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Helper function to determine platform from URL
  function determinePlatform(url) {
    if (url.includes("linkedin.com")) return "LinkedIn";
    if (url.includes("github.com")) return "GitHub";
    return "Portfolio";
  }

  // Helper function to get platform icon
  function getPlatformIcon(platform) {
    switch (platform) {
      case "LinkedIn":
        return <LinkedInIcon />;
      case "GitHub":
        return <GitHubIcon />;
      default:
        return <LanguageIcon />;
    }
  }

  const handleUrlChange = (index, newUrl) => {
    const newLinks = [...links];
    newLinks[index].url = newUrl;
    setLinks(newLinks);
  };

  const handleAddLink = () => {
    setLinks([
      ...links,
      { platform: "Portfolio", url: "", icon: <LanguageIcon /> },
    ]);
  };

  const handleDeleteLink = async (id, index) => {
    try {
      setLoading(true);
      setError(null);

      // Only make API call if the link has an ID (exists in backend)
      if (id) {
        await axios.delete(
          `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/links/${id}`
        );
      }

      // Remove link from local state
      const newLinks = [...links];
      newLinks.splice(index, 1);
      setLinks(newLinks);

      // Refresh profile data
      await fetchLatestProfile();
    } catch (err) {
      console.error("Error deleting link:", err);
      setError(err.response?.data?.message || "Failed to delete link");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    // Validate links
    const validLinks = links.filter((link) => link.url.trim() !== "");

    // Prepare links for backend
    const linksToSave = validLinks.map((link) => link.url);

    setLoading(true);
    setError(null);

    try {
      // Save links to backend
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/links`,
        { links: linksToSave }
      );

      // Fetch latest profile to get updated links
      await fetchLatestProfile();

      // Close modal
      onClose();
    } catch (err) {
      console.error("Error saving links:", err);
      setError(err.response?.data?.message || "Failed to save links");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Links
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: 2 }}>
        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          {links.map((link, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {link.icon}
              <TextField
                fullWidth
                size="small"
                placeholder={`https://www.${link.platform.toLowerCase()}.com/`}
                value={link.url}
                onChange={(e) => handleUrlChange(index, e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              {/* {(link.id || link.url) && (
                <IconButton
                  onClick={() => handleDeleteLink(link.id, index)}
                  disabled={loading}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )} */}
            </Box>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={handleAddLink}
            sx={{
              alignSelf: "flex-start",
              color: "primary.main",
              textTransform: "none",
              pl: 0,
              "&:hover": {
                backgroundColor: "transparent",
                color: "primary.dark",
              },
            }}
          >
            Add another
          </Button>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loading}
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 4,
              }}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CandidateLinks;