import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Grid,
  Avatar,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { toast } from "react-toastify";

const CreateParticipants = ({ setView }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const token = localStorage.getItem("token");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { firstName: "", lastName: "", email: "" };

    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSaveParticipant = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // Fetch plan limits and participant count
      const planResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const participantAllowed = planResponse.data.max_participants;

      const participantCountResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const participantCount = participantCountResponse.data.participant_count;

      if (participantCount >= participantAllowed) {
        toast.error(
          "You have reached your participant limit. Upgrade your plan or delete existing participants."
        );
        return;
      }

      // Save the participant
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/create`,
        {
          user_id: localStorage.getItem("user_id"),
          first_name: firstName,
          last_name: lastName,
          email: email,
        }
      );

      toast.success("Participant added successfully!");
      setView("participants");
    } catch (error) {
      console.error("Error creating participant:", error);
      toast.error(
        error.response?.data?.error ||
          "Failed to add participant. Please try again."
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={2}
          sx={{
            p: 4,
            backgroundColor: "#fff",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Avatar
              sx={{
                width: 56,
                height: 56,
                bgcolor: "primary.main",
                mb: 1,
              }}
            >
              <PersonAddIcon />
            </Avatar>

            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontWeight: 500,
                color: "text.primary",
                mb: 3,
              }}
            >
              Add New Participant
            </Typography>

            <form onSubmit={handleSaveParticipant} style={{ width: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setErrors((prev) => ({ ...prev, firstName: "" }));
                    }}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    required
                    InputProps={{
                      startAdornment: (
                        <PersonIcon color="action" sx={{ mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setErrors((prev) => ({ ...prev, lastName: "" }));
                    }}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    required
                    InputProps={{
                      startAdornment: (
                        <PersonIcon color="action" sx={{ mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors((prev) => ({ ...prev, email: "" }));
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                    InputProps={{
                      startAdornment: (
                        <EmailIcon color="action" sx={{ mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                    sx={{ py: 1.5 }}
                  >
                    Create Participant
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="inherit"
                    startIcon={<CancelIcon />}
                    onClick={() => setView("participants")}
                    sx={{ py: 1.5 }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default CreateParticipants;
