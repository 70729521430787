import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DOMPurify from "dompurify";
import Editor from "@monaco-editor/react";
const MIN_SPLIT = 20;
const MAX_SPLIT = 80;
const AnalysisCodingView = ({
 question,
 currentQuestionNumber,
 totalQuestions,
 onNavigate,
}) => {
 const [splitPosition, setSplitPosition] = useState(30);
 const [isDragging, setIsDragging] = useState(false);
 const [isTestCasesCollapsed, setIsTestCasesCollapsed] = useState(false);
 const [isEditorCollapsed, setIsEditorCollapsed] = useState(false);
 const executionData = question.executionData;
 const pointsEarned = executionData?.pointsEarned || 0;
 const totalPoints = question.testCases.reduce(
   (sum, tc) => sum + tc.points,
   0
 );
  // Resize handling
 const handleMouseDown = useCallback((e) => {
   e.preventDefault();
   setIsDragging(true);
 }, []);
  const handleMouseMove = useCallback(
   (e) => {
     if (!isDragging) return;
      const container = document.querySelector(".split-container");
     if (!container) return;
      const containerRect = container.getBoundingClientRect();
     const newPosition =
       ((e.clientX - containerRect.left) / containerRect.width) * 100;
     const constrainedPosition = Math.min(
       Math.max(newPosition, MIN_SPLIT),
       MAX_SPLIT
     );
     setSplitPosition(constrainedPosition);
   },
   [isDragging]
 );
  const handleMouseUp = useCallback(() => {
   setIsDragging(false);
 }, []);
  useEffect(() => {
   if (isDragging) {
     document.addEventListener("mousemove", handleMouseMove);
     document.addEventListener("mouseup", handleMouseUp);
   }
   return () => {
     document.removeEventListener("mousemove", handleMouseMove);
     document.removeEventListener("mouseup", handleMouseUp);
   };
 }, [isDragging, handleMouseMove, handleMouseUp]);
  return (
   <div className="h-[calc(100vh-74px)] bg-gray-100">
     <div className="split-container flex h-full relative p-4">
       {/* Question Panel */}
       <div
         className="bg-white rounded-lg shadow-lg overflow-y-auto transition-all duration-300"
         style={{
           width: `${splitPosition}%`,
           minWidth: `${MIN_SPLIT}%`,
           maxWidth: `${MAX_SPLIT}%`,
         }}
       >
         <div className="p-6">
           <div className="flex justify-between items-center mb-4">
             <h2 className="text-xl font-semibold">
               Question {currentQuestionNumber} of {totalQuestions}
             </h2>
             <span className="text-sm text-gray-500">
               Score: {pointsEarned}/{totalPoints}
             </span>
           </div>
           <Typography variant="h6" className="mb-4" style={{textAlign:"left"}}>
             {question.question_title}
           </Typography>
           <div
             className="prose max-w-none mb-6"
             style={{textAlign:"left"}}
             dangerouslySetInnerHTML={{
               __html: DOMPurify.sanitize(question.question_content),
             }}
           />
         </div>
       </div>
        {/* Resizer Handle */}
       <div
         className="w-2 cursor-col-resize flex items-center justify-center bg-gray-300 hover:bg-gray-400 active:bg-gray-500 mx-2"
         style={{ cursor: "col-resize" }}
         onMouseDown={handleMouseDown}
       >
         <div className="h-8 w-1 bg-gray-400 rounded-full" />
       </div>
        {/* Code Editor and Test Cases Panel */}
       <div
         className="flex flex-col transition-all duration-300"
         style={{
           width: `${100 - splitPosition}%`,
           minWidth: `${MIN_SPLIT}%`,
           maxWidth: `${MAX_SPLIT}%`,
           height: "100%",
         }}
       >
         {/* Code Editor */}
         <div
           className={`bg-white rounded-lg shadow-lg transition-all duration-300 ${
             isEditorCollapsed ? "h-[40px]" : "flex-1"
           } overflow-hidden mb-4`}
         >
           <div 
             className="border-b border-gray-200 p-4 cursor-pointer"
             onClick={() => setIsEditorCollapsed(!isEditorCollapsed)}
           >
             <div className="flex items-center justify-between">
               <Typography variant="subtitle1" className="font-medium">
                 Submitted Code
               </Typography>
               <button className="p-1 hover:bg-gray-100 rounded">
                 {isEditorCollapsed ? <FaChevronDown /> : <FaChevronUp />}
               </button>
             </div>
           </div>
           {!isEditorCollapsed && (
             <div className="h-[calc(100%-60px)]">
               <Editor
                 height="100%"
                 width="100%"
                 language="javascript"
                 value={executionData?.code || "// No code submitted"}
                 theme="vs-dark"
                 options={{
                   readOnly: true,
                   minimap: { enabled: false },
                   scrollBeyondLastLine: false,
                   fontSize: 14,
                 }}
               />
             </div>
           )}
         </div>
          {/* Test Cases Section */}
         <div
           className={`bg-white rounded-lg shadow-lg transition-all duration-300 ${
             isTestCasesCollapsed ? "h-[40px]" : "flex-1"
           }`}
         >
           <div
             className="border-b border-gray-200 p-4 cursor-pointer"
             onClick={() => setIsTestCasesCollapsed(!isTestCasesCollapsed)}
           >
             <div className="flex items-center justify-between">
               <Typography variant="subtitle1" className="font-medium">
                 Test Cases
               </Typography>
               <button className="p-1 hover:bg-gray-100 rounded">
                 {isTestCasesCollapsed ? <FaChevronDown /> : <FaChevronUp />}
               </button>
             </div>
           </div>
           {!isTestCasesCollapsed && (
             <div className="p-4 overflow-y-auto h-[calc(100%-40px)]">
               {question.testCases.map((testCase, index) => {
                 const passed = executionData?.passedTestcases?.includes(
                   testCase.test_case_id
                 );
                  return (
                   <div
                     key={testCase.test_case_id}
                     className={`mb-4 p-4 rounded-lg border ${
                       passed
                         ? "border-green-200 bg-green-50"
                         : "border-red-200 bg-red-50"
                     }`}
                   >
                     <div className="flex justify-between items-center mb-2">
                       <Typography variant="subtitle2">
                         Test Case {index + 1}
                       </Typography>
                       <span
                         className={`px-2 py-1 rounded text-sm ${
                           passed
                             ? "bg-green-100 text-green-800"
                             : "bg-red-100 text-red-800"
                         }`}
                       >
                         {passed ? "Passed" : "Failed"} ({testCase.points} points)
                       </span>
                     </div>
                     <div className="grid grid-cols-2 gap-4">
                       <div>
                         <Typography variant="caption" className="text-gray-600">
                           Input:
                         </Typography>
                         <pre className="mt-1 p-2 bg-gray-100 rounded">
                           {testCase.input}
                         </pre>
                       </div>
                       <div>
                         <Typography variant="caption" className="text-gray-600">
                           Expected Output:
                         </Typography>
                         <pre className="mt-1 p-2 bg-gray-100 rounded">
                           {testCase.output}
                         </pre>
                       </div>
                     </div>
                   </div>
                 );
               })}
             </div>
           )}
         </div>
       </div>
     </div>
      {/* Navigation Buttons */}
      <div className="fixed bottom-4 left-[70%] transform -translate-x-1/2 flex gap-8">
       <Button
         variant="contained"
         color="primary"
         onClick={() => onNavigate("previous")}
         disabled={currentQuestionNumber === 1}
       >
         Previous
       </Button>
       <Button
         variant="contained"
         color="primary"
         onClick={() => onNavigate("next")}
         disabled={currentQuestionNumber === totalQuestions}
       >
         Next
       </Button>
     </div>
   </div>
 );
};
export default AnalysisCodingView;