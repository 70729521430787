import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/AdminEditPlan.css";
import AdminLanguage from "./AdminLanguage";
import { showNotification } from "../../utils/notification";

function AdminEditPlan({ plan, closeForm, fetchPlans }) {
  const [formData, setFormData] = useState({
    plan_name: "",
    monthly_price: "",
    annual_price: "",
    public: false,
    plan_description: "",
    max_tests: "",
    max_questions: "",
    max_testcases_per_question: "",
    max_participants: "",
    max_mcq_questions: "", // Added new field
    plan_type: "1",
    languages: [],
  });

  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const token = localStorage.getItem("token");

  // Load plan data when component mounts or plan changes
  useEffect(() => {
    if (plan) {
      setFormData({
        plan_name: plan.plan_name || "",
        monthly_price: plan.monthly_price || "",
        annual_price: plan.annual_price || "",
        public: plan.plan_type === "0",
        plan_description: plan.plan_description || "",
        max_tests: plan.max_tests || "",
        max_questions: plan.max_question || "", // Note the field name difference
        max_testcases_per_question: plan.max_testcases_per_question || "",
        max_participants: plan.max_participants || "",
        max_mcq_questions: plan.max_mcq_questions || "", // Added new field
        plan_type: plan.plan_type || "1",
        languages: plan.languages || [],
      });
    }
  }, [plan]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const newPlanType = checked ? "0" : "1"; // "0" for public, "1" for private
      setFormData((prev) => ({
        ...prev,
        public: checked,
        plan_type: newPlanType,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSavePlan = async () => {
    try {
      const requestData = {
        plan_id: plan.plan_id,
        plan_name: formData.plan_name,
        monthly_price: parseFloat(formData.monthly_price),
        annual_price: parseFloat(formData.annual_price),
        plan_type: formData.plan_type,
        plan_description: formData.plan_description,
        max_tests: parseInt(formData.max_tests),
        max_question: parseInt(formData.max_questions),
        max_testcases_per_question: parseInt(
          formData.max_testcases_per_question
        ),
        max_participants: parseInt(formData.max_participants),
        max_mcq_questions: parseInt(formData.max_mcq_questions), // Added new field
        languages: formData.languages,
      };

      if (!requestData.plan_name || !requestData.plan_description) {
        showNotification("error", "Please fill in all required fields");
        return;
      }

      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/plan`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Plan Updated Successfully") {
        showNotification("success", "Plan updated successfully");
        // First close the form
        closeForm();
        // Then fetch updated plans
        await fetchPlans();
      } else {
        showNotification(
          "error",
          response.data.message || "Failed to update plan"
        );
      }
    } catch (error) {
      console.error("Error updating plan:", error);
      showNotification("error", "An error occurred while updating the plan");
    }
  };

  return (
    <div className="create-plan-wrapper">
      <div className="create-plan-container">
        <div className="create-plan-left">
          <img src="/plan-image.jpg" alt="Plan" className="create-plan-image" />
        </div>
        <div className="create-plan-right">
          {showLanguageSelection ? (
            <AdminLanguage
              selectedLanguages={formData.languages}
              onSave={(newLanguages) => {
                setFormData((prev) => ({
                  ...prev,
                  languages: newLanguages,
                }));
                setShowLanguageSelection(false);
              }}
              onClose={() => setShowLanguageSelection(false)}
            />
          ) : (
            <>
              <div className="create-plan-header">
                <h2>Edit Plan</h2>
              </div>
              <div className="create-plan-form">
                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="plan_name"
                    value={formData.plan_name}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Plan Name</label>
                </div>

                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="monthly_price"
                    type="number"
                    value={formData.monthly_price}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Monthly Price</label>
                </div>

                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="annual_price"
                    type="number"
                    value={formData.annual_price}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Annual Price</label>
                </div>

                <div className="create-plan-input-group">
                  <textarea
                    className="create-plan-textarea"
                    name="plan_description"
                    value={formData.plan_description}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Description</label>
                </div>

                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="max_tests"
                    type="number"
                    value={formData.max_tests}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Tests Limit</label>
                </div>

                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="max_questions"
                    type="number"
                    value={formData.max_questions}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Questions Limit</label>
                </div>

                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="max_testcases_per_question"
                    type="number"
                    value={formData.max_testcases_per_question}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Test Cases Limit</label>
                </div>
                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="max_mcq_questions"
                    type="number"
                    value={formData.max_mcq_questions}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>MCQ Questions Limit</label>
                </div>
                <div className="create-plan-input-group">
                  <input
                    className="create-plan-input"
                    name="max_participants"
                    type="number"
                    value={formData.max_participants}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Participants Limit</label>
                </div>

                <div className="create-plan-checkbox">
                  <input
                    type="checkbox"
                    name="public"
                    checked={formData.public}
                    onChange={handleInputChange}
                  />
                  <label>Keep this plan publicly visible</label>
                </div>

                <button
                  className="create-plan-language-button"
                  onClick={() => setShowLanguageSelection(true)}
                >
                  Select Languages
                </button>
              </div>

              <div className="create-plan-actions">
                <button className="create-plan-button" onClick={handleSavePlan}>
                  Update Plan
                </button>
                <button className="create-plan-cancel-btn" onClick={closeForm}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminEditPlan;
