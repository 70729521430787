import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Button, Dialog, DialogTitle, 
  DialogContent, DialogActions, TextField, IconButton, Table, 
  TableBody, TableCell, TableContainer, TableHead, TableRow, 
  TablePagination, Chip, Tooltip, Alert, CircularProgress, Snackbar } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, 
  Block as BlockIcon, CheckCircle as CheckCircleIcon , Done as DoneIcon 
  } from '@mui/icons-material';
import axios from 'axios';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [validationErrors, setValidationErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/org/subuser`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        // Check if data is an empty array or null
        const formattedUsers = response.data.data && response.data.data.length > 0
          ? response.data.data.map(user => ({
              id: user.user_id,
              username: user.username,
              email: user.email,
              status: user.status_of_subuser ? 'ACTIVE' : 'INACTIVE',
              createdAt: user.created_at,
              lastLoginAt: user.last_login_date_and_time
            }))
          : [];
        
        setUsers(formattedUsers);
      } else {
        throw new Error(response.data.error || 'Failed to fetch users');
      }
    } catch (err) {
      // Check if it's a 404 error specifically
      if (err.response && err.response.status === 404) {
        // Set users to an empty array instead of throwing an error
        setUsers([]);
      } else {
        console.error('Error fetching users:', err);
        setError(err.response?.data?.error || err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.username?.trim()) errors.username = 'Username is required';
    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = 'Invalid email address';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/org/subuser`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.data.success) {
        await fetchUsers();
        handleCloseDialog();
        setSnackbar({
          open: true,
          message: 'User successfully created! An email has been sent with login credentials.',
          severity: 'success'
        });
      } else {
        throw new Error(response.data.error);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Failed to create user',
        severity: 'error'
      });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/api/org/subuser/${userId}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.data.success) {
        await fetchUsers();
        setSnackbar({
          open: true,
          message: 'User successfully deleted',
          severity: 'success'
        });
      } else {
        throw new Error(response.data.error || 'Failed to delete user');
      }
    } catch (err) {
      console.error('Error deleting user:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Failed to delete user',
        severity: 'error'
      });
    }
  };

  const handleToggleStatus = async (userId, currentStatus) => {
    try {
      const endpoint = currentStatus === 'ACTIVE' ? 'deactivate' : 'activate';
      
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/org/subuser/${endpoint}`,
        {
          subuserId: userId
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.data.success) {
        await fetchUsers();
        setSnackbar({
          open: true,
          message: `User ${currentStatus === 'ACTIVE' ? 'deactivated' : 'activated'} successfully`,
          severity: 'success'
        });
      } else {
        throw new Error(response.data.error || `Failed to ${endpoint} user`);
      }
    } catch (err) {
      console.error(`Error ${currentStatus === 'ACTIVE' ? 'deactivating' : 'activating'} user:`, err);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || `Failed to ${currentStatus === 'ACTIVE' ? 'deactivate' : 'activate'} user`,
        severity: 'error'
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setFormData({ username: '', email: '' });
    setValidationErrors({});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Add User
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                >Username</TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                >Email</TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                >Status</TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                >Created On</TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                >Last Login</TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white'
                  }}
                  align="right"
                >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
             ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No users found. Click "Add User" to create a new user.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.id} hover>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={user.status}
                        color={user.status === 'ACTIVE' ? 'success' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{user.createdAt}</TableCell>
                    <TableCell>{user.lastLoginAt || 'Never'}</TableCell>
                    <TableCell align="right">
                      <Tooltip title={user.status === 'ACTIVE' ? 'Deactivate User' : 'Activate User'}>
                        <IconButton
                          onClick={() => handleToggleStatus(user.id, user.status)}
                          color={user.status === 'ACTIVE' ? 'error' : 'success'}
                        >
                          {user.status === 'ACTIVE' ? <BlockIcon /> : <DoneIcon />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User">
                        <IconButton
                          onClick={() => handleDeleteUser(user.id)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    
                  </TableRow>
                  
                )) // Closing parenthesis added here
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle>
          Add New User
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Username"
              fullWidth
              value={formData.username}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              error={!!validationErrors.username}
              helperText={validationErrors.username}
            />
            <TextField
              label="Email"
              fullWidth
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users;