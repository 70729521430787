import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminCreatePlan from "./AdminCreatePlan";
import AdminEditPlan from "./AdminEditPlan";
import {
  FiMoreVertical,
  FiUsers,
  FiFileText,
  FiCode,
  FiCheckSquare,
} from "react-icons/fi";
import { showNotification } from "../../utils/notification";
import "../../css/AdminPlans.css";

function AdminPlans() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingPlan, setEditingPlan] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan?user_id=${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedPlans = response.data.data.map((plan) => ({
        ...plan,
        isDropdownOpen: false,
        isDeleting: false,
      }));

      setPlans(updatedPlans);
    } catch (error) {
      console.error("Error fetching plans", error);
      showNotification("error", "Failed to fetch updated plans");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []); // <-- Check here for missing or extra brackets

  const handleCreatePlan = () => {
    setEditingPlan(null);
    setIsCreating(true);
    setIsEditing(false);
  };

  const handleEditPlan = (plan) => {
    setEditingPlan(plan);
    setIsCreating(false);
    setIsEditing(true);
  };

  const handleCloseForm = () => {
    setIsCreating(false);
    setIsEditing(false);
    setEditingPlan(null);
    // await fetchPlans();
  };

   // Add this useEffect for handling clicks outside the dropdown
   useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.querySelectorAll('.plan-options');
      let clickedInside = false;
      
      dropdowns.forEach(dropdown => {
        if (dropdown.contains(event.target)) {
          clickedInside = true;
        }
      });

      if (!clickedInside) {
        setPlans(prevPlans =>
          prevPlans.map(plan => ({
            ...plan,
            isDropdownOpen: false
          }))
        );
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // Update the toggleDropdown function to handle click events better
  const toggleDropdown = (planId, event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setPlans(prevPlans =>
      prevPlans.map(plan =>
        plan.plan_id === planId
          ? { ...plan, isDropdownOpen: !plan.isDropdownOpen }
          : { ...plan, isDropdownOpen: false }
      )
    );
  };

  const handleDeletePlan = async (planId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this plan?"
    );
    if (!confirmDelete) return;

    setPlans((prevPlans) =>
      prevPlans.map((plan) =>
        plan.plan_id === planId ? { ...plan, isDeleting: true } : plan
      )
    );

    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/api/plan/${planId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        showNotification(
          "success",
          response.data.message || "Plan Deleted Successfully"
        );
        setPlans((prevPlans) =>
          prevPlans.filter((plan) => plan.plan_id !== planId)
        );
      } else {
        showNotification("error", "Failed to delete plan. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting plan:", error);
      showNotification("error", "An error occurred while deleting the plan.");
    } finally {
      setPlans((prevPlans) =>
        prevPlans.map((plan) =>
          plan.plan_id === planId
            ? { ...plan, isDeleting: false, isDropdownOpen: false }
            : plan
        )
      );
    }
  };

  return (
    <div className="plan-content">
      {isCreating ? (
        <AdminCreatePlan closeForm={handleCloseForm} fetchPlans={fetchPlans} />
      ) : isEditing ? (
        <AdminEditPlan
          plan={editingPlan}
          closeForm={handleCloseForm}
          fetchPlans={fetchPlans}
        />
      ) : (
        <div className="admin-plans">
          <div className="header">
            <h2>Manage Plans</h2>
            <button className="create-plan-btn" onClick={handleCreatePlan}>
              Create New Plan
            </button>
          </div>
          <div className="plan-cards-container">
            {loading ? (
              <p>Loading plans...</p>
            ) : plans.length === 0 ? (
              <p>No plans available</p>
            ) : (
              plans.map((plan) => (
                <div key={plan.plan_id} className="plan-card">
                  <div className="plan-card-content">
                    <div className="plan-card-header">
                      <h3>{plan.plan_name}</h3>
                      <div className="plan-options">
                      <FiMoreVertical
  className="more-icon"
  onClick={(e) => toggleDropdown(plan.plan_id, e)}
  size={24}  // Added explicit size prop
  strokeWidth={2}  // Added to make the icon slightly bolder
/>
                        {plan.isDropdownOpen && (
                          <div className="dropdown-options">
                            <ul>
                              <li
                                onClick={() => {
                                  handleEditPlan(plan);
                                  toggleDropdown(plan.plan_id);
                                }}
                              >
                                Edit
                              </li>
                              <li
                                onClick={() => handleDeletePlan(plan.plan_id)}
                              >
                                {plan.isDeleting ? "Deleting..." : "Delete"}
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="plan-card-body">
                      <div className="plan-card-price">
                        ₹{plan.monthly_price}
                        <span>/month</span>
                      </div>
                      <div className="plan-card-type">
                        {plan.plan_type === 0 ? "Public" : "Private"}
                      </div>
                      <ul className="plan-card-features">
                        <li>
                          <FiFileText />{" "}
                          <strong>{plan.max_tests || "N/A"}</strong> Tests
                        </li>
                        <li>
                          <FiCheckSquare />{" "}
                          <strong>{plan.max_question || "N/A"}</strong>{" "}
                          Questions
                        </li>
                        <li>
                          <FiCode />{" "}
                          <strong>
                            {plan.max_testcases_per_question || "N/A"}
                          </strong>{" "}
                          Test Cases
                        </li>
                        <li>
                          <FiUsers />{" "}
                          <strong>{plan.max_participants || "N/A"}</strong>{" "}
                          Participants
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPlans;
