import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
  backgroundColor: "transparent",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  "& .MuiTypography-root": {
    transition: "color 0.3s ease",
  },
  "&:hover .MuiTypography-root": {
    color: "#4b7ccb",
  },
  // Add styles for expanded state
  "&.Mui-expanded .MuiTypography-root": {
    color: "#4b7ccb",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "&.Mui-expanded": {
    minHeight: 48,
  },
  // Optional: Change the expand icon color when expanded
  "&.Mui-expanded .MuiSvgIcon-root": {
    color: "#4b7ccb",
  },
  // Optional: Change the expand icon color on hover
  "&:hover .MuiSvgIcon-root": {
    color: "#4b7ccb",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 4, 3, 4),
  backgroundColor: "rgba(75, 124, 203, 0.03)",
  borderRadius: theme.spacing(1),
}));

const FaqAccordion = ({ faq, expanded, onChange }) => {
  return (
    <StyledAccordion expanded={expanded} onChange={onChange}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" fontWeight="500">
          {faq.question}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{faq.answer}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default FaqAccordion;
