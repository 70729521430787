import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/AdminLanguage.css";
import { languageOptions } from "../../constants/languageOptions"; // Adjust import path if needed

function AdminLanguage({ selectedLanguages, onSave, onClose }) {
  const [languages, setLanguages] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    // Fetch languages from the backend
    const fetchLanguages = async () => {
      try {
        const token = localStorage.getItem("token"); // Replace with how you get your token
        // const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/get-languages-for-public-test`, {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   }
        // });

        const response = await axios.get(
          `${import.meta.env.VITE_BASE_URL}/api/test/languages`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update state with fetched languages
        setLanguages(response.data.data);
      } catch (error) {
        console.error("Error fetching languages", error);
        // Fallback to empty array if API fails
        setLanguages([]);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      // Initialize selected state based on selectedLanguages prop
      const initialSelected = selectedLanguages
        .map((lang) => {
          // Find the corresponding language option for the given language_id
          const languageOption = languageOptions.find(
            (opt) => opt.id === lang.language_id
          );

          if (languageOption) {
            return {
              language_id: languageOption.id,
              language: languageOption.name,
              is_allowed: lang.is_allowed,
            };
          }

          return null;
        })
        .filter((item) => item !== null); // Filter out null values

      setSelected(initialSelected);
    }
  }, [languages, selectedLanguages]);

  const handleLanguageChange = (language) => {
    const updatedSelection = selected.map((item) => {
      if (item.language_id === language.language_id) {
        return {
          ...item,
          is_allowed: !item.is_allowed,
        };
      }
      return item;
    });

    // Add new selection if it does not exist
    if (
      !updatedSelection.some(
        (item) => item.language_id === language.language_id
      )
    ) {
      updatedSelection.push({
        language_id: language.language_id,
        language: language.language,
        is_allowed: true,
      });
    }

    setSelected(updatedSelection);
  };

  const handleSave = () => {
    // Format the selected languages for saving
    const formattedSelected = selected.map((item) => ({
      language_id: item.language_id,
      language: item.language,
      is_allowed: item.is_allowed,
    }));

    onSave(formattedSelected); // Save selected languages and close
    onClose();
  };

  return (
    <div className="admin-language-selector">
      <div className="admin-language-content">
        <h3 className="admin-language-heading">Languages / Frameworks</h3>
        <p className="admin-language-description">
          Select the languages or frameworks to support under the selected plan.
        </p>
        <div className="admin-language-list">
          {languages.map((language) => {
            // Find corresponding language option for the language from the backend
            const languageOption = languageOptions.find(
              (opt) => opt.name === language.language
            );
            return (
              <div key={language.id} className="admin-language-list-item">
                <input
                  className="admin-language-checkbox"
                  type="checkbox"
                  checked={selected.some(
                    (item) =>
                      item.language_id === languageOption?.id && item.is_allowed
                  )}
                  onChange={() =>
                    handleLanguageChange({
                      language_id: languageOption?.id,
                      language: languageOption?.name,
                      is_allowed: !selected.some(
                        (item) =>
                          item.language_id === languageOption?.id &&
                          item.is_allowed
                      ),
                    })
                  }
                />
                <label className="admin-language-label">
                  {language.language}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="admin-language-btn-group">
        <button className="admin-language-back-btn" onClick={onClose}>
          Back
        </button>
        <button className="admin-language-save-btn" onClick={handleSave}>
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default AdminLanguage;
