import React, { useState,  useEffect  } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
  Fade,
  Stack,
  Divider,
  Alert,
  Chip,
  Grid,
  Tooltip,
  Zoom,
  useTheme,
  alpha,
  Stepper,
  Step,
  StepLabel,
  LinearProgress
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import BoltIcon from '@mui/icons-material/Bolt';
import { styled, keyframes } from '@mui/material/styles';

import axios from 'axios';
import { toast } from 'react-toastify';

// Enhanced styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.9)}, 
    ${alpha(theme.palette.background.paper, 0.95)})`,
  borderRadius: theme.spacing(3),
  boxShadow: `0 10px 40px ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

const shimmer = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const pulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0 0 ${alpha('#2196F3', 0.4)}; }
  70% { transform: scale(1.05); box-shadow: 0 0 0 10px ${alpha('#2196F3', 0)}; }
  100% { transform: scale(1); box-shadow: 0 0 0 0 ${alpha('#2196F3', 0)}; }
`;

const GenerateButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, 
    ${theme.palette.primary.main} 30%, 
    ${theme.palette.primary.light} 90%)`,
  border: 0,
  borderRadius: 48,
  boxShadow: `0 3px 15px ${alpha(theme.palette.primary.main, 0.3)}`,
  color: 'white',
  height: 56,
  padding: '0 40px',
  fontSize: '1.1rem',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    background: `linear-gradient(45deg, 
      ${theme.palette.primary.light} 30%, 
      ${theme.palette.primary.main} 90%)`,
    boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
    transform: 'translateY(-2px)',
  },
  '&:active': {
    transform: 'translateY(1px)',
  },
  '&.generating': {
    animation: `${pulse} 2s infinite`,
  },
}));

const QuestionCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: alpha(theme.palette.background.paper, 0.8),
//   backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  '&:hover': {
    transform: 'translateY(-4px) scale(1.01)',
    boxShadow: `0 12px 24px ${alpha(theme.palette.primary.main, 0.1)}`,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderWidth: '2px',
      },
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  },
}));

const StyledAddButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  transition: 'all 0.3s ease-in-out',
  background: alpha(theme.palette.success.main, 0.1),
  color: theme.palette.success.main,
  '&:hover': {
    background: alpha(theme.palette.success.main, 0.2),
    transform: 'translateY(-2px)',
  },
}));

const LoadingBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 3,
  background: alpha(theme.palette.primary.main, 0.1),
  '& .MuiLinearProgress-bar': {
    background: `linear-gradient(90deg, 
      ${theme.palette.primary.main}, 
      ${theme.palette.primary.light}, 
      ${theme.palette.primary.main})`,
    backgroundSize: '200% 100%',
    animation: `${shimmer} 2s infinite linear`,
  },
}));

const AiCodingQuestion = ({ onClose }) => {

    const [planLimits, setPlanLimits] = useState({
        max_testcases_per_question: 10 // Default fallback
      });

  const theme = useTheme();
  const [formData, setFormData] = useState({
    questionType: 'coding',
    noOfQuestions: '',
    topic: '',
    difficulty: '',
    noOfTestCases: ''
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const token = localStorage.getItem("token");

  const steps = ['Configure Questions', 'Generate', 'Review & Add'];


  useEffect(() => {
    const fetchPlanLimits = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setPlanLimits(response.data);
      } catch (error) {
        console.error('Failed to fetch plan limits', error);
        // Optionally show a toast or error message
      }
    };
    fetchPlanLimits();
}, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleGenerate = async () => {
    if (!formData.noOfQuestions || !formData.topic || !formData.difficulty || !formData.noOfTestCases) {
      setError('Please fill in all fields');
      return;
    }
    // New validation for test cases
  if (parseInt(formData.noOfTestCases) > planLimits.max_testcases_per_question) {
    setError(`Maximum ${planLimits.max_testcases_per_question} test cases allowed per question`);
    return;
  }

    setError('');
    setIsGenerating(true);
    setActiveStep(1);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/ai`,
        {
          questionType: 'coding',
          noOfQuestions: parseInt(formData.noOfQuestions),
          topic: formData.topic,
          difficulty: formData.difficulty,
          codingConfig: {
            noOfTestCases: parseInt(formData.noOfTestCases)
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        setGeneratedQuestions(response.data.questions);
        setActiveStep(2);
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate questions. Please try again.');
      setActiveStep(0);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleAddQuestion = async (question) => {
    try {
      // First, fetch the user's plan limits
      const planResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      // Fetch current question count
      const countResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      const { max_question } = planResponse.data;
      const { question_count } = countResponse.data;
  
      // Check if user has reached the question limit
      if (question_count >= max_question) {
        toast.error(`You have reached the maximum limit of ${max_question} questions for your current plan.`);
        return;
      }
  
      // Proceed with adding the question if limit not reached
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/ai/add-coding-question`,
        {
          title: question.title,
          description: question.description,
          testcases: question.testCases.map(tc => ({
            input: tc.input,
            output: tc.output,
            points: tc.points || 1
          }))
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      if (response.data.success) {
        toast.success('Question added successfully!');
        setGeneratedQuestions(prev => 
          prev.filter(q => q.title !== question.title)
        );
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to add question';
      toast.error(errorMessage);
      console.error('Error adding question:', error);
    }
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case 'easy':
        return theme.palette.success.main;
      case 'medium':
        return theme.palette.warning.main;
      case 'hard':
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4, position: 'relative' }}>
        {isGenerating && <LoadingBar />}
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onClose}
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Back to Questions
        </Button>

        <StyledPaper elevation={3}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
            <AutoAwesomeIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 40,
                animation: `${pulse} 2s infinite`,
              }}
            />
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 600,
                background: `linear-gradient(45deg, 
                  ${theme.palette.primary.main}, 
                  ${theme.palette.primary.light})`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
              }}
            >
              AI Question Generator
            </Typography>
          </Stack>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mb: 4 }}>
            <Alert 
              severity="info" 
              sx={{ 
                mb: 3,
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  fontSize: 28,
                },
              }}
              icon={<SchoolIcon fontSize="inherit" />}
            >
              Generate custom coding questions powered by AI. Specify your requirements below.
            </Alert>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Number of Questions"
                  name="noOfQuestions"
                  type="number"
                  value={formData.noOfQuestions}
                  onChange={handleInputChange}
                  InputProps={{
                    inputProps: { min: 1, max: 10 },
                    startAdornment: (
                      <CodeIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Topic"
                  name="topic"
                  value={formData.topic}
                  onChange={handleInputChange}
                  placeholder="e.g., Merge Sort, Binary Search"
                  InputProps={{
                    startAdornment: (
                      <BoltIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Difficulty</InputLabel>
                  <StyledSelect
                    name="difficulty"
                    value={formData.difficulty}
                    onChange={handleInputChange}
                    label="Difficulty"
                  >
                    {['Easy', 'Medium', 'Hard'].map((level) => (
                      <MenuItem
                        key={level}
                        value={level.toLowerCase()}
                        sx={{
                          '&:hover': {
                            backgroundColor: alpha(getDifficultyColor(level), 0.1),
                          },
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: getDifficultyColor(level),
                            mr: 1,
                            display: 'inline-block',
                          }}
                        />
                        {level}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
              <StyledTextField
  fullWidth
  label="Number of Test Cases"
  name="noOfTestCases"
  type="number"
  value={formData.noOfTestCases}
  onChange={handleInputChange}
  InputProps={{
    inputProps: { 
      min: 1, 
      max: planLimits.max_testcases_per_question 
    },
    startAdornment: (
      <Tooltip 
        title={`Max ${planLimits.max_testcases_per_question} test cases allowed`}
      >
        <HelpOutlineIcon sx={{ color: 'action.active', mr: 1 }} />
      </Tooltip>
    ),
  }}
  helperText={`Maximum ${planLimits.max_testcases_per_question} test cases allowed`}
/>
              </Grid>
            </Grid>

            {error && (
              <Zoom in={!!error}>
                <Alert
                  severity="error"
                  sx={{
                    mt: 2,
                    borderRadius: 2,
                    animation: `${pulse} 1s`,
                  }}
                >
                  {error}
                </Alert>
              </Zoom>
            )}

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <GenerateButton
                onClick={handleGenerate}
                disabled={isGenerating}
                className={isGenerating ? 'generating' : ''}
                startIcon={isGenerating ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <AutoAwesomeIcon />
                )}
              >
                {isGenerating ? 'Generating Questions...' : 'Generate Questions'}
              </GenerateButton>
            </Box>
          </Box>

          {generatedQuestions.length > 0 && (
            <Box sx={{ mt: 6 }}>
              <Divider sx={{ mb: 4 }}>
              <Chip
  label="Generated Questions"
  color="primary"
  icon={<AutoAwesomeIcon />}
  sx={{
    px: 2,
    '& .MuiChip-label': {
      display: 'block',
      overflow: 'unset',
      textOverflow: 'unset',
      whiteSpace: 'nowrap',
    },
    '& .MuiChip-icon': {
      fontSize: 20,
    },
  }}
/>
            </Divider>

            <Box sx={{ 
              maxHeight: '60vh', 
              overflowY: 'auto',
              pr: 2,
              mr: -2,
              '&::-webkit-scrollbar': {
                width: 8,
              },
              '&::-webkit-scrollbar-track': {
                background: alpha(theme.palette.primary.main, 0.1),
                borderRadius: 4,
              },
              '&::-webkit-scrollbar-thumb': {
                background: alpha(theme.palette.primary.main, 0.3),
                borderRadius: 4,
                '&:hover': {
                  background: alpha(theme.palette.primary.main, 0.4),
                },
              },
            }}>
              {generatedQuestions.map((question, index) => (
                <Fade 
                  in={true} 
                  timeout={500 * (index + 1)} 
                  key={question.id}
                >
                  <QuestionCard>
                    <CardContent>
                      <Stack spacing={2}>
                        <Stack 
                          direction="row" 
                          justifyContent="space-between" 
                          alignItems="center"
                        >
                          <Box>
                            <Typography 
                              variant="h6" 
                              sx={{
                                fontWeight: 600,
                                color: theme.palette.text.primary,
                              }}
                            >
                              {question.title}
                            </Typography>
                            <Stack 
                              direction="row" 
                              spacing={1} 
                              sx={{ mt: 1 }}
                            >
                              <Chip
                                size="small"
                                label={formData.difficulty}
                                sx={{
                                  backgroundColor: alpha(
                                    getDifficultyColor(formData.difficulty),
                                    0.1
                                  ),
                                  color: getDifficultyColor(formData.difficulty),
                                  fontWeight: 500,
                                }}
                              />
                              <Chip
                                size="small"
                                label={`${question.testCases.length} Test Cases`}
                                sx={{
                                  backgroundColor: alpha(
                                    theme.palette.info.main,
                                    0.1
                                  ),
                                  color: theme.palette.info.main,
                                  fontWeight: 500,
                                }}
                              />
                            </Stack>
                          </Box>
                          <StyledAddButton
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddQuestion(question)}
                          >
                            Add to Questions
                          </StyledAddButton>
                        </Stack>

                        <Typography 
                          color="text.secondary"
                          sx={{
                            lineHeight: 1.6,
                            fontSize: '0.95rem',
                          }}
                        >
                          {question.description}
                        </Typography>

                        <Box sx={{ mt: 2 }}>
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              mb: 1,
                              color: theme.palette.text.primary,
                              fontWeight: 600,
                            }}
                          >
                            Sample Test Cases:
                          </Typography>
                          <Grid container spacing={2}>
                            {question.testCases.slice(0, 2).map((testCase, idx) => (
                              <Grid item xs={12} sm={6} key={idx}>
                                <Paper
                                  sx={{
                                    p: 1.5,
                                    backgroundColor: alpha(
                                      theme.palette.background.default,
                                      0.5
                                    ),
                                    border: `1px solid ${alpha(
                                      theme.palette.divider,
                                      0.1
                                    )}`,
                                    borderRadius: 1,
                                  }}
                                >
                                  <Typography 
                                    variant="caption" 
                                    display="block" 
                                    color="text.secondary"
                                    sx={{ mb: 0.5 }}
                                  >
                                    Input:
                                  </Typography>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      fontFamily: 'monospace',
                                      color: theme.palette.text.primary,
                                    }}
                                  >
                                    {testCase.input}
                                  </Typography>
                                  <Typography 
                                    variant="caption" 
                                    display="block" 
                                    color="text.secondary"
                                    sx={{ mt: 1, mb: 0.5 }}
                                  >
                                    Output:
                                  </Typography>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      fontFamily: 'monospace',
                                      color: theme.palette.success.main,
                                    }}
                                  >
                                    {testCase.output}
                                  </Typography>
                                </Paper>
                              </Grid>
                            ))}
                          </Grid>
                          {question.testCases.length > 2 && (
                            <Typography 
                              variant="caption" 
                              color="text.secondary"
                              sx={{ mt: 1, display: 'block' }}
                            >
                              + {question.testCases.length - 2} more test cases
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    </CardContent>
                  </QuestionCard>
                </Fade>
              ))}
            </Box>
          </Box>
        )}
      </StyledPaper>
    </Box>
  </Container>
);
};

export default AiCodingQuestion;