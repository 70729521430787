import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Fade,
} from "@mui/material";
import {
  AccountCircle,
  Dashboard,
  ExitToApp,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Ensure withCredentials is set globally as we are using Axios frequently:
// Without this line the token was not getting sent
// axios.defaults.withCredentials = true;

const CandidateHeader = ({ activeTab, onTabChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");

    // If username is not in localStorage or is empty, fetch from backend
    if (!storedUsername || storedUsername === "undefined") {
      const fetchUsername = async () => {
        try {
          console.log("Cookies sent with the request:", document.cookie);

          const result = await axios.get(
            `${BASE_URL}/api/auth/candidate`
            // {
            //   withCredentials: true, // Include cookies in the request
            // }
          );
          const fetchedUsername = result.data.username;
          setUsername(fetchedUsername);
          // Optionally store the fetched username in localStorage
          localStorage.setItem("username", fetchedUsername);
        } catch (error) {
          console.error("Error Fetching Username", error);
        }
      };
      fetchUsername();
    } else {
      setUsername(storedUsername);
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const confirmLogout = window.confirm(
      "Do you want to log out from your profile?"
    );
    if (confirmLogout) {
      localStorage.clear();

      const response = await axios.get(`${BASE_URL}/logout`);
      if (response.data.success === true) {
        navigate("/login");
      }
    } else {
      handleMenuClose();
    }
  };

  const handleProfileSettings = () => {
    handleMenuClose();
    onTabChange("profile"); // Add this line to switch to the profile tab
  };
  const handleSettings = () => {
    handleMenuClose();
    onTabChange("settings");
  };

  const handleUsernameChange = (newUsername) => {
    setUsername(newUsername);
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={3}
      sx={{
        backgroundColor: "rgba(255,255,255,0.95)",
        backdropFilter: "blur(10px)",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        color: "black",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          px: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 700,
              color: "#1a73e8",
              letterSpacing: "-0.5px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer", // Add this to indicate it's clickable
            }}
          >
            <img
              src="/logos/cirruscypher-name-multi-alpha.png"
              alt="CirruCypher Logo"
              style={{ height: 50, marginRight: 8 }}
              onClick={() => navigate("/")} // Add this onClick handler
            />
            {/* CirruCypher */}
          </Typography>

          <Box sx={{ display: "flex", gap: 3, ml: 2 }}>
            {["compete", "prepare", "tutorials", "blogs"].map((tab) => (
              <Typography
                key={tab}
                variant="body1"
                sx={{
                  cursor: "pointer",
                  fontWeight: activeTab === tab ? 700 : 500,
                  color: activeTab === tab ? "#1a73e8" : "rgba(0,0,0,0.7)",
                  borderBottom:
                    activeTab === tab ? "3px solid #1a73e8" : "none",
                  pb: 1,
                  textTransform: "capitalize",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    color: "#1a73e8",
                    opacity: 0.8,
                  },
                }}
                onClick={() => {
                  if (["tutorials", "blogs"].includes(tab)) {
                    navigate(`/${tab}`);
                  } else {
                    onTabChange(tab);
                  }
                }}
              >
                {tab}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: "rgba(0,0,0,0.7)",
              mr: 1,
            }}
          >
            Welcome {username}
          </Typography>
          <IconButton
            onClick={handleMenuOpen}
            sx={{
              backgroundColor: "rgba(25,118,210,0.1)",
              "&:hover": {
                backgroundColor: "rgba(25,118,210,0.2)",
              },
            }}
          >
            <AccountCircle sx={{ color: "#1a73e8" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 4,
              sx: {
                borderRadius: 2,
                mt: 1,
                minWidth: 200,
              },
            }}
          >
            <MenuItem onClick={handleProfileSettings} sx={{ gap: 2 }}>
              <Dashboard fontSize="small" />
              Profile
            </MenuItem>
            <MenuItem onClick={handleSettings} sx={{ gap: 2 }}>
              <Settings fontSize="small" />
              Settings
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              sx={{ gap: 2, color: "error.main" }}
            >
              <ExitToApp fontSize="small" />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CandidateHeader;
