import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import screenfull from "screenfull";
import { toast } from "react-toastify";
import axios from "axios";

const FullScreenManager = ({ children }) => {
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [violationCount, setViolationCount] = useState(0);
  const MAX_VIOLATIONS = 50;

  useEffect(() => {
    // Request fullscreen when component mounts
    if (screenfull.isEnabled) {
      screenfull.request();
    }

    const handleFullscreenChange = () => {
      if (!screenfull.isFullscreen) {
        setShowWarning(true);
        setViolationCount((prev) => {
          const newCount = prev + 1;
          if (newCount >= MAX_VIOLATIONS) {
            handleMaxViolations();
          } else {
            // toast.error(
            //   `Warning: ${
            //     MAX_VIOLATIONS - newCount
            //   } fullscreen exits remaining before auto-submission`
            // );
          }
          return newCount;
        });
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleMaxViolations = async () => {
    toast.error(
      "Maximum fullscreen violations reached. Test will be auto-submitted."
    );
    try {
      // Submit test
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/test/submit`,
        {
          email: localStorage.getItem("email"),
          testId: localStorage.getItem("testId"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Clean up
      localStorage.removeItem("email");
      localStorage.removeItem("testId");
      localStorage.removeItem("testInfo");
      localStorage.removeItem("remainingTime");

      // Navigate to completion page
      navigate("/test-submitted");
    } catch (error) {
      console.error("Error submitting test:", error);
      toast.error("Failed to submit test");
    }
  };

  const handleWarningOk = () => {
    if (screenfull.isEnabled) {
      screenfull.request();
      setShowWarning(false);
    }
  };

  return (
    <>
      {children}

      {showWarning && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md">
            <h3 className="text-xl font-bold mb-4 text-red-600">Warning!</h3>
            <p className="mb-4">
              Fullscreen mode is required to continue the test. Please click OK
              to return to fullscreen mode.
            </p>
            <p className="mb-4 text-red-500">
              Warning:
              {/* You have used {violationCount} out of {MAX_VIOLATIONS}{" "}
              allowed fullscreen exits.  */}
              Frequent violations will lead to automatic test submission.
            </p>
            <button
              onClick={handleWarningOk}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FullScreenManager;
