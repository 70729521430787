import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { FaChevronDown, FaChevronUp, FaArrowRight } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import axios from "axios";
import DOMPurify from "dompurify";

import CodeEditorWindow from "../CodeEditorWindow";
import ThemeDropdown from "../../ThemeDropdown";
import LanguagesDropdown from "../LanguagesDropdown";
import IOTabs from "./IOTabs";
import { defineTheme } from "../../../lib/defineTheme";

const pythonDefault = `#Before writing code first select language and remove this line`;

const MIN_SPLIT = 20;
const MAX_SPLIT = 80;

const CodingView = ({
  question,
  language: initialLanguage,
  languages,
  onCodeSubmit,
  currentQuestionNumber,
  totalQuestions,
  onNavigate,
}) => {
  const [code, setCode] = useState(pythonDefault);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [theme, setTheme] = useState({ label: "Cobalt", value: "cobalt" });
  const [testCaseResults, setTestCaseResults] = useState({});
  const [isEditorCollapsed, setIsEditorCollapsed] = useState(false);
  const [isIOCollapsed, setIsIOCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("custom");
  const [splitPosition, setSplitPosition] = useState(30);
  const [isDragging, setIsDragging] = useState(false);
  const [language, setLanguage] = useState({
    id: initialLanguage?.language_id,
    language: initialLanguage?.language,
    value: initialLanguage?.language?.toLowerCase(),
  });

  const handleNextQuestion = () => {
    if (currentQuestionNumber < totalQuestions) {
      onNavigate("next");
    }
  };

  // Theme initialization
  useEffect(() => {
    defineTheme("oceanic-next").then((_) => {
      setTheme({ value: "oceanic-next", label: "Oceanic Next" });
    });
  }, []);

  // Load saved code when question changes
  useEffect(() => {
    if (question?.question_id) {
      loadSavedCode();
    }
  }, [question?.question_id]);

  // Resize handling
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;

      const container = document.querySelector(".split-container");
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const newPosition =
        ((e.clientX - containerRect.left) / containerRect.width) * 100;
      const constrainedPosition = Math.min(
        Math.max(newPosition, MIN_SPLIT),
        MAX_SPLIT
      );
      setSplitPosition(constrainedPosition);
    },
    [isDragging]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const loadSavedCode = async () => {
    try {
      const testId = localStorage.getItem("testId");
      const email = localStorage.getItem("email");

      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/test/get-code?test_id=${testId}&email=${email}&question_id=${
          question.question_id
        }`
      );

      if (response.status === 200 && response.data.code) {
        setCode(response.data.code);

        // Mark question as answered if code exists
        if (response.data.code.trim()) {
          onCodeSubmit();
        }
      } else {
        setCode(pythonDefault);
      }
    } catch (error) {
      console.error("Error loading saved code:", error);
      setCode(pythonDefault);
    }
  };

  const saveCode = async () => {
    try {
      const testId = localStorage.getItem("testId");
      const email = localStorage.getItem("email");

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/test/${testId}/save-code`,
        {
          // test_id: testId,
          email: email,
          question_id: question.question_id,
          code: code || " ",
        }
      );

      if (response.status === 200) {
        console.log("Code saved successfully");
      }
    } catch (error) {
      console.error("Error saving code:", error);
      toast.error("Failed to save code");
    }
  };

  const fetchAndRunTestCases = async (jobId) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/${
          question.question_id
        }/testcase`
      );

      const testCases = response.data;
      await runUserCode(jobId, testCases);
    } catch (error) {
      console.error("Error fetching test cases:", error);
      toast.error("Failed to fetch test cases");
    }
  };

  const handleCompile = async () => {
    try {
      await saveCode();
      setProcessing(true);
      setTestCaseResults({});

      // If custom input is provided, run single execution
      if (customInput.trim()) {
        // First compile the code
        const compileResponse = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/api/judge/compile`,
          {
            code,
            customInput: customInput.trim(),
            languageId: language.id,
          }
        );

        // Create a loading state immediately
        setOutputDetails({
          status: { id: 1, description: "Processing" },
          stdout: null,
          stderr: null,
        });

        // Get the jobId from the compilation response
        const { jobId } = compileResponse.data;

        // Poll for results
        const result = await pollForResult(jobId, null);
        if (result && result.resultData) {
          // Ensure we're setting the state with fresh data
          setOutputDetails((prevState) => {
            // Only update if the new result is different
            if (
              JSON.stringify(prevState) !== JSON.stringify(result.resultData)
            ) {
              return result.resultData;
            }
            return prevState;
          });
        }
      } else {
        // Handle test cases execution as before
        setActiveTab("testcases");
        await executeTestCases();
      }

      onCodeSubmit();
    } catch (err) {
      console.error("Error compiling code:", err);
      toast.error("Compilation failed");
      setOutputDetails({
        stderr: "Compilation failed",
        status: { id: -1, description: "Error" },
      });
    } finally {
      setProcessing(false);
    }
  };

  const pollForResult = async (jobId, testcaseId, retries = 0) => {
    const MAX_RETRIES = 10;
    const POLLING_INTERVAL = 2000;

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/judge/results`,
        {
          params: {
            jobId,
            email: localStorage.getItem("email"),
            testcaseId,
            questionId: question.question_id,
            testId: localStorage.getItem("testId"),
            sectionId: question.sectionId,
            customInput: testcaseId ? undefined : customInput, // Add customInput only when there's no testcaseId
          },
        }
      );

      const { resultData } = response.data;

      // If still in queue and haven't exceeded max retries, poll again
      if (resultData.status.id === 1 && retries < MAX_RETRIES) {
        // Update state to show processing
        setOutputDetails({
          status: { id: 1, description: "Processing" },
          stdout: null,
          stderr: null,
        });

        await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
        return pollForResult(jobId, testcaseId, retries + 1);
      }

      // For non-custom input executions, don't update outputDetails here
      if (!testcaseId) {
        setOutputDetails(resultData);
      }

      return {
        status: response.data.status,
        resultData: resultData,
        error: resultData.stderr || resultData.compile_output,
      };
    } catch (error) {
      console.error("Error polling for results:", error);
      const errorResult = {
        status: "failed",
        error: "Execution error",
        resultData: {
          stderr: "Execution failed",
          status: { id: -1, description: "Error" },
        },
      };

      // Update output details with error
      if (!testcaseId) {
        setOutputDetails(errorResult.resultData);
      }

      return errorResult;
    }
  };

  const executeTestCases = async () => {
    try {
      // Create initial loading state
      setOutputDetails({
        status: { id: 1, description: "Processing" },
        stdout: null,
        stderr: null,
      });

      const testCaseIdsResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/judge/testcases`,
        {
          params: {
            questionId: question.question_id,
          },
        }
      );
      const { testCaseIds } = testCaseIdsResponse.data;

      const compileResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/judge/compile`,
        {
          code,
          questionId: question.question_id,
          languageId: language.id,
        }
      );
      const { jobIds } = compileResponse.data;

      // Poll results for each test case
      const results = await Promise.all(
        jobIds.map(async ({ jobId, testcaseId }, index) => {
          const result = await pollForResult(jobId, testcaseId);
          // Set output details only for the first test case
          if (index === 0 && result.resultData) {
            setOutputDetails(result.resultData);
          }
          return { testcaseId, ...result };
        })
      );

      const newTestCaseResults = results.reduce((acc, result) => {
        acc[result.testcaseId] = {
          passed: result.status === "passed",
        };
        return acc;
      }, {});

      setTestCaseResults(newTestCaseResults);
    } catch (error) {
      console.error("Error executing test cases:", error);
      toast.error("Failed to execute test cases");
      setOutputDetails({
        stderr: "Failed to execute test cases",
        status: { id: -1, description: "Error" },
      });
    }
  };

  const onChange = (action, data) => {
    if (action === "code") {
      setCode(data);
      onCodeSubmit(data);
    }
  };

  const handleThemeChange = (selectedTheme) => {
    setTheme(selectedTheme);
    defineTheme(selectedTheme.value);
  };

  return (
    <div className="h-[calc(100vh-74px)] bg-gray-100">
      {" "}
      {/* Adjust height based on your header */}
      <div className="split-container flex  h-full relative p-4">
        {/* Question Panel */}
        <div
          className="bg-white rounded-lg shadow-lg overflow-y-auto transition-all duration-300"
          style={{
            width: `${splitPosition}%`,
            minWidth: `${MIN_SPLIT}%`,
            maxWidth: `${MAX_SPLIT}%`,
          }}
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                Question {currentQuestionNumber} of {totalQuestions}
              </h2>
              <span className="text-sm text-gray-500">
                Points: {question?.points || 0}
              </span>
            </div>
            <Typography variant="h6" className="mb-4" style={{textAlign:"left"}}>
              {question?.question_title}
            </Typography>
            <div
              className="prose max-w-none" style={{textAlign:"left"}}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question?.question_content),
              }}
            />
          </div>
        </div>

        {/* Resizer Handle */}
        <div
          className="w-2 cursor-col-resize flex items-center justify-center bg-gray-300 hover:bg-gray-400 active:bg-gray-500 mx-2"
          style={{ cursor: "col-resize" }}
          onMouseDown={handleMouseDown}
        >
          <div className="h-8 w-1 bg-gray-400 rounded-full" />
        </div>

        {/* Editor and IO Panel */}
        <div
          className="flex flex-col transition-all duration-300"
          style={{
            width: `${100 - splitPosition}%`,
            minWidth: `${MIN_SPLIT}%`,
            maxWidth: `${MAX_SPLIT}%`,
            height: "100%",
          }}
        >
          {/* Code Editor Section */}
          <div
            className="bg-white rounded-lg shadow-lg flex flex-col overflow-hidden"
            style={{
              flex: isEditorCollapsed ? "0" : "2",
              minHeight: isEditorCollapsed ? "60px" : "0",
              transition: "flex 0.3s ease",
            }}
          >
            <div className="border-b border-gray-200 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <LanguagesDropdown
                    language={initialLanguage}
                    languages={languages}
                    onSelectChange={(selectedLanguage) => {
                      setLanguage({
                        id: selectedLanguage.id,
                        language: selectedLanguage.language,
                        value: selectedLanguage.value,
                      });
                    }}
                  />
                  <ThemeDropdown
                    theme={theme}
                    handleThemeChange={handleThemeChange}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setIsEditorCollapsed(!isEditorCollapsed)}
                    className="p-2 hover:bg-gray-100 rounded"
                  >
                    {isEditorCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                  </button>
                  <button
                    onClick={handleCompile}
                    disabled={!code || processing}
                    className={`px-4 py-2 rounded ${
                      processing || !code
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700 text-white"
                    }`}
                  >
                    {processing ? (
                      <div className="flex items-center gap-2">
                        <VscLoading className="animate-spin" />
                        Running...
                      </div>
                    ) : (
                      "Run Code"
                    )}
                  </button>
                  {/* Add Next Question button */}
                  <button
                    onClick={handleNextQuestion}
                    disabled={currentQuestionNumber === totalQuestions}
                    className={`px-4 py-2 rounded flex items-center gap-2 ${
                      currentQuestionNumber === totalQuestions
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-green-600 hover:bg-green-700 text-white"
                    }`}
                  >
                    Next Question
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`transition-all duration-300 flex-grow ${
                isEditorCollapsed ? "h-0" : "flex-1"
              }`}
              style={{
                height: isIOCollapsed
                  ? "calc(100% - 60px)"
                  : "calc(60% - 60px)",
              }}
            >
              {!isEditorCollapsed && (
                <CodeEditorWindow
                  code={code}
                  onChange={onChange}
                  language={language?.value}
                  theme={theme.value}
                />
              )}
            </div>
          </div>

          {/* IO Section */}
          <div
            className={`bg-white rounded-lg shadow-lg mt-4 transition-all duration-300 ${
              isIOCollapsed ? "h-[40px]" : "flex-1"
            }`}
          >
            <div className="border-b border-gray-200 p-2 flex justify-between items-center">
              <span className="font-medium">Input/Output</span>
              <button
                onClick={() => setIsIOCollapsed(!isIOCollapsed)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                {isIOCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </button>
            </div>
            <div
              className={`transition-all duration-300 ${
                isIOCollapsed ? "h-0 overflow-hidden" : "h-[calc(100%-40px)]"
              }`}
            >
              {!isIOCollapsed && (
                <IOTabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  customInput={customInput}
                  setCustomInput={setCustomInput}
                  outputDetails={outputDetails}
                  testCaseResults={testCaseResults}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodingView;
