import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PrepareTestDetails from "./PrepareTestDetails";

// Styled component for enhanced card interaction
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  borderRadius: theme.spacing(3),
  boxShadow: "0 8px 15px rgba(0,0,0,0.1)",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 20px rgba(0,0,0,0.15)",
    "& .card-title": {
      color: theme.palette.primary.main,
    },
  },
}));

const CandidatePrepareTests = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);

  // Fetch tests when component mounts
  useEffect(() => {
    fetchTests();
  }, []);

  // Function to fetch preparation tests from the API
  const fetchTests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/candidate/fetch-technocirrus-tests`,
        {
          withCredentials: true, // Include cookies in the request
        }
      );

      if (response.data.success) {
        setTests(response.data.tests);
        setError(null);
      } else {
        setError("No tests found");
      }
    } catch (error) {
      const errorMessage =
        error.response?.status === 401
          ? "Unauthorized access. Please log in again."
          : error.response?.status === 404
          ? "No preparation tests available at this time."
          : error.response?.data?.error || "An unexpected error occurred.";
      setError(errorMessage);
      console.error("Error fetching preparation tests:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle viewing test details
  const handleViewTestDetails = async (testId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/org/tests/get-test-data/${testId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setSelectedTest(response.data.data);
      } else {
        console.error("Failed to fetch test details:", response.data);
        setError("Failed to load test details");
      }
    } catch (err) {
      console.error("Error fetching test details:", err);
      setError("Error loading test details");
    } finally {
      setLoading(false);
    }
  };

  // Function to render the test grid with loading, error, and empty states
  const renderTestGrid = () => {
    if (loading) {
      return (
        <Grid container spacing={4}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={250}
                sx={{
                  borderRadius: 4,
                  transform: "scale(1)",
                  backgroundColor: "rgba(0,0,0,0.06)",
                }}
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 8,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="error"
            sx={{ mb: 2, fontWeight: 600 }}
          >
            {error}
          </Typography>
        </Box>
      );
    }

    if (tests.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 8,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: 600,
              color: "primary.main",
            }}
          >
            No Practice Tests Available
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Check back later for new practice tests
          </Typography>
        </Box>
      );
    }

    return (
      <Grid container spacing={4}>
        {tests.map((test) => (
          <Grid item xs={12} sm={6} md={4} key={test.test_id}>
            <StyledCard
              onClick={() => handleViewTestDetails(test.test_id)}
              elevation={3}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  p: 3,
                }}
              >
                <Typography
                  variant="h6"
                  className="card-title"
                  sx={{
                    mb: 2,
                    fontWeight: 700,
                    color: "rgba(0,0,0,0.8)",
                    transition: "color 0.3s ease",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {test.test_title}
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    flexGrow: 1,
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    mb: 2,
                    lineHeight: 1.6,
                  }}
                >
                  {test.test_description}
                </Typography>

                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Duration: {test.duration} minutes
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {!selectedTest ? (
        renderTestGrid()
      ) : (
        <PrepareTestDetails
          test={selectedTest}
          onBack={() => setSelectedTest(null)}
        />
      )}
    </Container>
  );
};

export default CandidatePrepareTests;
