import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  Stack,
  Alert,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";

const EditQuestionAndTestcases = ({ questionId, onClose }) => {
  const [questionData, setQuestionData] = useState({
    title: "",
    description: "",
    testCases: [],
  });
  const [saveStatus, setSaveStatus] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getQuestionAndTestcases();
  }, [questionId]);

  const getQuestionAndTestcases = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/question/content?questionId=${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestionData(response.data);
    } catch (error) {
      console.error("Error fetching question and test cases:", error);
      setSaveStatus("error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTestCaseChange = (index, field, value) => {
    const updatedTestCases = [...questionData.testCases];
    updatedTestCases[index] = {
      ...updatedTestCases[index],
      [field]: value,
    };
    setQuestionData((prevData) => ({
      ...prevData,
      testCases: updatedTestCases,
    }));
  };

  const handleSubmit = async () => {
    setSaveStatus(null);
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/question/content`,
        {
          question_id: questionId,
          question_title: questionData.title,
          question_description: questionData.description,
          testcases: questionData.testCases,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSaveStatus("success");
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error("Error saving question and test cases:", error);
      setSaveStatus("error");
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <IconButton onClick={onClose} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
      </Box>

      {saveStatus && (
        <Alert
          severity={saveStatus === "success" ? "success" : "error"}
          sx={{ mb: 3 }}
          icon={
            saveStatus === "success" ? (
              <CheckCircleIcon />
            ) : (
              <ErrorOutlineIcon />
            )
          }
        >
          {saveStatus === "success"
            ? "Changes saved successfully!"
            : "Error saving changes. Please try again."}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Question Details Section */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Question Details
              </Typography>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={questionData.title}
                  onChange={handleInputChange}
                  variant="outlined"
                  placeholder="Enter question title"
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={questionData.description}
                  onChange={handleInputChange}
                  multiline
                  rows={8}
                  variant="outlined"
                  placeholder="Enter question description"
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Test Cases Section */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Test Cases
              </Typography>
              <Stack spacing={3}>
                {questionData.testCases.map((testCase, index) => (
                  <Card
                    key={testCase.id}
                    variant="outlined"
                    sx={{
                      ":hover": {
                        borderColor: "primary.main",
                        transition: "border-color 0.3s ease",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Test Case {index + 1}
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                          <TextField
                            fullWidth
                            label="Input"
                            value={testCase.inputContent}
                            onChange={(e) =>
                              handleTestCaseChange(
                                index,
                                "inputContent",
                                e.target.value
                              )
                            }
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Enter test case input"
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            fullWidth
                            label="Expected Output"
                            value={testCase.outputContent}
                            onChange={(e) =>
                              handleTestCaseChange(
                                index,
                                "outputContent",
                                e.target.value
                              )
                            }
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Enter expected output"
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            label="Points"
                            type="number"
                            value={testCase.points}
                            onChange={(e) =>
                              handleTestCaseChange(
                                index,
                                "points",
                                e.target.value
                              )
                            }
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            placeholder="Enter points"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={saveStatus === "success"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
          disabled={saveStatus === "success"}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default EditQuestionAndTestcases;
