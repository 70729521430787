import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const SectionAnalysisSelection = ({
  sections = [],
  testId,
  participantEmail,
  onSectionSelect,
  onBack,
  isLoading,
}) => {
  const [loadingSectionId, setLoadingSectionId] = useState(null);
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const token = localStorage.getItem("token");
  const handleSectionClick = async (section) => {
    setLoadingSectionId(section.section_id);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/tests/test-attempt-data`,
        {
          params: {
            testId,
            email: participantEmail,
            sectionId: section.section_id,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        onSectionSelect({
          ...section,
          questions: response.data.data.questions,
        });
      } else {
        toast.error("Failed to fetch section details");
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
      toast.error(
        error.response?.data?.message || "Failed to fetch section details"
      );
    } finally {
      setLoadingSectionId(null);
    }
  };
  if (isLoading) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            py: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }
  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Button startIcon={<ArrowBack />} onClick={onBack}>
            Back to Scoreboard
          </Button>
          <Typography variant="h5">
            Test Analysis for {participantEmail}
          </Typography>
        </Box>
        {/* Sections Grid */}
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {sections.map((section) => (
            <Grid item xs={12} sm={6} md={4} key={section.section_id}>
              <Card
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    transition: "transform 0.2s",
                  },
                  position: "relative",
                }}
                onClick={() => handleSectionClick(section)}
              >
                {loadingSectionId === section.section_id && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {section.section_name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Chip
                      label={`Section ${section.section_order_no}`}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Click to view section analysis
                      </Typography>
                    </Box>
                    <Tooltip title="View Section Analysis">
                      <IconButton
                        color="primary"
                        disabled={loadingSectionId === section.section_id}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {sections.length === 0 && !isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Typography variant="h6" color="text.secondary">
              No sections available for this test
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default SectionAnalysisSelection;
