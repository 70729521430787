import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Alert,
  Collapse,
  Divider,
  CircularProgress,
  Grid,
  Tooltip,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Lock as LockIcon,
  Security as SecurityIcon,
  Password as PasswordIcon,
  Info as InfoIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import axios from "axios";

const UserSettings = () => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // New state for username and email
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [emailUpdateLoading, setEmailUpdateLoading] = useState(false);
  const [usernameUpdateLoading, setUsernameUpdateLoading] = useState(false);

  // New state for current user info
  const [currentUsername, setCurrentUsername] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");

  const userRole = localStorage.getItem("role"); // Add this line at the start
  const isSubUser = userRole === "USER"; // Add this line

  useEffect(() => {
    // Get current user info from localStorage
    const storedUsername = localStorage.getItem("username");
    const storedEmail = localStorage.getItem("email");
    setCurrentUsername(storedUsername || "");
    setCurrentEmail(storedEmail || "");
  }, []);

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    return requirements;
  };

  // Handle username update
  const handleUsernameUpdate = async () => {
    if (!username) {
      setError("Username is required");
      return;
    }

    setUsernameUpdateLoading(true);
    setError("");
    setSuccess("");

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/org/setting/username`,
        { username },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        setSuccess("Username updated successfully");
        setCurrentUsername(username); // Update displayed username
        localStorage.setItem("username", username); // Update localStorage
        setUsername("");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to update username");
    } finally {
      setUsernameUpdateLoading(false);
    }
  };

  // Handle send OTP
  const handleSendOtp = async () => {
    if (!email) {
      setError("Email is required");
      return;
    }

    setEmailUpdateLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/otp`,
        { email }
      );

      if (response.data.message) {
        setIsOtpSent(true);
        setOtpDialogOpen(true);
        setSuccess("OTP sent successfully");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to send OTP");
    } finally {
      setEmailUpdateLoading(false);
    }
  };

  // Handle verify OTP
  const handleVerifyOtp = async () => {
    if (!otp) {
      setError("OTP is required");
      return;
    }

    setEmailUpdateLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/otp/verify`,
        { email, otp }
      );

      if (response.data.message) {
        setIsOtpVerified(true);
        setSuccess("OTP verified successfully");
        handleUpdateEmail();
      }
    } catch (err) {
      setError(err.response?.data?.error || "Invalid OTP");
    } finally {
      setEmailUpdateLoading(false);
    }
  };

  // Handle email update
  const handleUpdateEmail = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/org/setting/email`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        setSuccess("Email updated successfully");
        setCurrentEmail(email); // Update displayed email
        localStorage.setItem("email", email); // Update localStorage
        setEmail("");
        setOtp("");
        setIsOtpSent(false);
        setIsOtpVerified(false);
        setOtpDialogOpen(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to update email");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    try {
      // Validation
      if (!currentPassword || !newPassword || !confirmPassword) {
        throw new Error("All fields are required");
      }

      if (newPassword !== confirmPassword) {
        throw new Error("New passwords do not match");
      }

      const requirements = validatePassword(newPassword);
      if (!Object.values(requirements).every(Boolean)) {
        throw new Error("Password does not meet security requirements");
      }

      // Get token from localStorage
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      const username = localStorage.getItem("username");

      // Log the full URL for debugging
      const apiUrl = `${
        import.meta.env.VITE_BASE_URL
      }/api/org/setting/password`;
      console.log("API URL:", apiUrl);

      // Log request details
      console.log("Request payload:", {
        username,
        currentPassword,
        newPassword,
      });
      console.log("Headers:", {
        Authorization: `Bearer ${token.substring(0, 10)}...`, // Only log part of token for security
      });

      // Make API call to update password
      const response = await axios.put(
        apiUrl,
        {
          username,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Explicitly set content type
          },
        }
      );

      if (response.data.success) {
        setSuccess(response.data.message || "Password changed successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        throw new Error(response.data.error || "Failed to update password");
      }
    } catch (err) {
      console.error("Full error:", err);

      // Enhanced error handling
      if (err.response) {
        // Log detailed error response
        console.error("Error response:", {
          status: err.response.status,
          data: err.response.data,
          headers: err.response.headers,
        });

        switch (err.response.status) {
          case 404:
            setError("API endpoint not found. Please contact support.");
            break;
          case 401:
            setError("Your session has expired. Please login again.");
            break;
          case 400:
            setError(
              err.response.data.error ||
                "Invalid request. Please check your input."
            );
            break;
          case 500:
            setError("Server error. Please try again later.");
            break;
          default:
            setError(err.response.data.error || "An unexpected error occurred");
        }
      } else if (err.request) {
        console.error("No response received:", err.request);
        setError("No response from server. Please try again.");
      } else {
        console.error("Error setting up request:", err.message);
        setError(err.message || "An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const requirements = validatePassword(newPassword);

  const PasswordStrengthIndicator = () => (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Password Requirements:
      </Typography>
      <Grid container spacing={2}>
        {Object.entries({
          "At least 8 characters": requirements.length,
          "One uppercase letter": requirements.uppercase,
          "One lowercase letter": requirements.lowercase,
          "One number": requirements.number,
          "One special character": requirements.special,
        }).map(([requirement, met]) => (
          <Grid item xs={12} sm={6} key={requirement}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: met ? "success.main" : "error.main",
                }}
              />
              <Typography
                variant="body2"
                color={met ? "text.primary" : "text.secondary"}
              >
                {requirement}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Stack spacing={2} sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SecurityIcon sx={{ fontSize: 28, color: "primary.main", mr: 2 }} />
          <Typography variant="h5" fontWeight="600">
            Security Settings
          </Typography>
        </Box>

        {/* Centralized alerts */}
        <Collapse in={Boolean(error || success)}>
          {error && (
            <Alert severity="error" onClose={() => setError("")}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" onClose={() => setSuccess("")}>
              {success}
            </Alert>
          )}
        </Collapse>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* Current Info Display */}
          <Card
            elevation={0}
            variant="outlined"
            sx={{ mb: 3, bgcolor: "primary.50" }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Current Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Username
                  </Typography>
                  <Typography variant="body1">{currentUsername}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body1">{currentEmail}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Conditional rendering for Username and Email update sections */}
          {!isSubUser && (
            <>
              {/* Username Update Section */}
              <Card elevation={0} variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <PersonIcon sx={{ color: "primary.main", mr: 2 }} />
                    <Typography variant="h6">Update Username</Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="New Username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{ mb: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EditIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    size="large"
                    disabled={usernameUpdateLoading}
                    onClick={handleUsernameUpdate}
                    sx={{ minWidth: 200, height: 48 }}
                    startIcon={
                      usernameUpdateLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <PersonIcon />
                      )
                    }
                  >
                    {usernameUpdateLoading ? "Updating..." : "Update Username"}
                  </Button>
                </CardContent>
              </Card>

              {/* Email Update Section */}
              <Card elevation={0} variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <EmailIcon sx={{ color: "primary.main", mr: 2 }} />
                    <Typography variant="h6">Update Email</Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="New Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    size="large"
                    disabled={emailUpdateLoading || isOtpSent}
                    onClick={handleSendOtp}
                    sx={{ minWidth: 200, height: 48 }}
                    startIcon={
                      emailUpdateLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <EmailIcon />
                      )
                    }
                  >
                    {emailUpdateLoading ? "Sending..." : "Send OTP"}
                  </Button>
                </CardContent>
              </Card>
            </>
          )}

          {/* Password Update Section */}
          <Card elevation={0} variant="outlined">
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <PasswordIcon sx={{ color: "primary.main", mr: 2 }} />
                <Typography variant="h6">Change Password</Typography>
                <Tooltip title="Regularly updating your password helps keep your account secure">
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Current Password"
                  variant="outlined"
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword("current")}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword("new")}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Confirm New Password"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword("confirm")}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <PasswordStrengthIndicator />

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  sx={{ minWidth: 200, height: 48 }}
                  startIcon={
                    loading ? <CircularProgress size={20} /> : <LockIcon />
                  }
                >
                  {loading ? "Updating..." : "Update Password"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>

        {/* Security Tips Section */}
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            variant="outlined"
            sx={{ position: "sticky", top: 24 }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Security Tips
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Stack spacing={2}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <LockIcon color="action" />
                  <Typography variant="body2" color="text.secondary">
                    Use a unique password for each of your accounts
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <SecurityIcon color="action" />
                  <Typography variant="body2" color="text.secondary">
                    Enable two-factor authentication for additional security
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <PasswordIcon color="action" />
                  <Typography variant="body2" color="text.secondary">
                    Regularly update your password every 3-6 months
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* OTP Verification Dialog */}
      <Dialog
        open={otpDialogOpen}
        onClose={() => setOtpDialogOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 400,
            p: 2,
          },
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SecurityIcon color="primary" />
            <Typography variant="h6">Verify OTP</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Please enter the OTP sent to your email address
          </Typography>
          <TextField
            fullWidth
            label="Enter OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={() => setOtpDialogOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleVerifyOtp}
            variant="contained"
            disabled={emailUpdateLoading}
            startIcon={
              emailUpdateLoading ? (
                <CircularProgress size={20} />
              ) : (
                <SecurityIcon />
              )
            }
          >
            {emailUpdateLoading ? "Verifying..." : "Verify OTP"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserSettings;
