import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Alert,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  DeleteForever as DeleteIcon,
  WarningAmber as WarningIcon,
  InfoOutlined as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Styled components
const StyledDropZone = styled(Paper)(({ theme, isDragActive }) => ({
  border: `2px dashed ${isDragActive ? theme.palette.error.main : theme.palette.divider}`,
  backgroundColor: isDragActive ? theme.palette.error.light : theme.palette.background.paper,
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.error.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
}));

const CSVFormatInfo = () => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon color="error" />
      Required CSV Format:
    </Typography>
    <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
      <Typography variant="body2" component="pre" sx={{ overflowX: 'auto' }}>
        participant_id
        12345
        67890
        (One participant ID per line)
      </Typography>
    </Paper>
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      <Chip icon={<CheckCircleIcon />} label="Single Column" color="error" variant="outlined" />
      <Chip icon={<CheckCircleIcon />} label="Header Required" color="error" variant="outlined" />
      <Chip icon={<CheckCircleIcon />} label="Valid IDs Only" color="error" variant="outlined" />
    </Stack>
  </Box>
);

const BulkDeleteParticipant = ({
  errorMessage,
  handleCSVDeleteUpload,
  handleBulkDeleteParticipantSubmit,
  handleGoBackToParticipants,
  isButtonDisabled,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);
  const [fileError, setFileError] = useState('');

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      handleFileUpload({ target: { files: [file] } });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      
      // Read and process the CSV file
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        // Split by newlines and filter out empty lines
        const lines = text.split('\n').filter(line => line.trim());
        // Subtract 1 to account for header row
        const count = Math.max(0, lines.length - 1);
        setParticipantCount(count);
      };
      reader.readAsText(file);
      
      handleCSVDeleteUpload(event);
      setActiveStep(1);
      simulateProgress();
    }
  };
  
  const simulateProgress = () => {
    setUploadProgress(0);
    const timer = setInterval(() => {
      setUploadProgress((oldProgress) => {
        const diff = Math.random() * 10;
        const newProgress = Math.min(oldProgress + diff, 100);
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 100);
  };

  const handleSampleDownload = () => {
    const link = document.createElement('a');
    link.href = '/SampleBulkDeletingParticipants.csv';
    link.download = 'SampleBulkDeletingParticipants.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleBulkDeleteParticipantSubmit(event);
  };

  const steps = [
    {
      label: 'Upload CSV File',
      description: 'Upload a CSV file containing participant IDs to delete',
    },
    {
      label: 'Review Information',
      description: 'Verify participant IDs before deletion',
    },
    {
      label: 'Confirm Deletion',
      description: 'Final confirmation before deleting participants',
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <IconButton
            onClick={handleGoBackToParticipants}
            color="primary"
            sx={{ p: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" fontWeight="bold">
            Bulk Delete Participants
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
          {/* Left Section - Information */}
          <Box sx={{ flex: '1 1 300px', minWidth: 300 }}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" color="error" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <WarningIcon /> Important Information
                </Typography>
                <Alert severity="warning" sx={{ mb: 2 }}>
                  This action will permanently delete the selected participants. Make sure you have the correct participant IDs.
                </Alert>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="text.secondary" paragraph>
                  Before proceeding, please ensure:
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon fontSize="small" color="success" />
                    Your CSV file contains valid participant IDs
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon fontSize="small" color="success" />
                    Each ID is on a separate line
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon fontSize="small" color="success" />
                    You have backed up your data if needed
                  </Typography>
                </Stack>
              </CardContent>
            </StyledCard>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <HelpIcon color="error" />
                  CSV Format Guide
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CSVFormatInfo />
              </AccordionDetails>
            </Accordion>
          </Box>

          {/* Right Section - Upload & Steps */}
          <Box sx={{ flex: '2 1 600px' }}>
            <form onSubmit={handleSubmit}>
              <StyledCard>
                <CardContent>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel>
                          <Typography variant="subtitle1">{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                          <Typography color="text.secondary" paragraph>
                            {step.description}
                          </Typography>
                          
                          {index === 0 && (
                            <Box>
                              <StyledDropZone
                                isDragActive={dragActive}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              >
                                <input
                                  type="file"
                                  accept=".csv"
                                  onChange={handleFileUpload}
                                  style={{ display: 'none' }}
                                  id="csv-delete-upload"
                                />
                                <label htmlFor="csv-delete-upload">
                                  <Stack spacing={2} alignItems="center">
                                    <CloudUploadIcon sx={{ fontSize: 48, color: 'error.main' }} />
                                    <Typography variant="h6" color="error">
                                      Drag & Drop your CSV file here
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      or click to browse
                                    </Typography>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      component="span"
                                      startIcon={<DownloadIcon />}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSampleDownload();
                                      }}
                                    >
                                      Download Sample CSV
                                    </Button>
                                  </Stack>
                                </label>
                              </StyledDropZone>

                              {selectedFile && (
                                <Box sx={{ mt: 2 }}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Upload Progress:
                                  </Typography>
                                  <LinearProgress 
                                    variant="determinate" 
                                    value={uploadProgress} 
                                    color="error"
                                  />
                                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                                    {selectedFile.name}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          )}

                          {index === 1 && selectedFile && (
                            <Box sx={{ mt: 2 }}>
                              <Alert 
                                severity="info" 
                                action={
                                  <Button 
                                    color="inherit" 
                                    size="small"
                                    onClick={() => setActiveStep(2)}
                                  >
                                    Continue
                                  </Button>
                                }
                              >
                                {`Found ${participantCount} participants to delete.`}
                              </Alert>
                            </Box>
                          )}

                          {index === 2 && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="error"
                              size="large"
                              disabled={isButtonDisabled}
                              startIcon={<DeleteIcon />}
                              sx={{ mt: 2 }}
                            >
                              Delete All Selected Participants
                            </Button>
                          )}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </CardContent>
              </StyledCard>
            </form>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </Box>

        {/* Confirmation Dialog */}
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon color="warning" fontSize="large" />
            Confirm Bulk Deletion
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to delete {participantCount} participants. This action cannot be undone.
              Are you sure you want to proceed?
            </DialogContentText>
            <Box sx={{ mt: 2, p: 2, bgcolor: 'error.light', borderRadius: 1 }}>
              <Typography variant="body2" color="error.dark">
                • All selected participants will be permanently removed
              </Typography>
              <Typography variant="body2" color="error.dark">
                • Associated data will be deleted
              </Typography>
              <Typography variant="body2" color="error.dark">
                • This action is irreversible
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)}>
              Cancel
            </Button>
            <Button 
              onClick={handleConfirmDelete} 
              color="error" 
              variant="contained" 
              startIcon={<DeleteIcon />}
            >
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default BulkDeleteParticipant;