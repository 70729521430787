import React from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Stack
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateQuestion = ({
  step,
  questionTitle,
  questionDescription,
  testCases,
  input,
  output,
  points,
  setQuestionTitle,
  setQuestionDescription,
  setInput,
  setOutput,
  setPoints,
  handleFormSubmit,
  handleTestcaseSubmit,
  handleGoBackToQuestions,
  handleBackToFirstStep, // New prop for handling back to first step
}) => {
  const steps = ['Question Details', 'Test Cases'];

  

  const renderQuestionForm = () => (
    <Box component="form" onSubmit={handleFormSubmit} noValidate sx={{ mt: 3 }}>
    

      <TextField
        fullWidth
        label="Question Title"
        value={questionTitle}
        onChange={(e) => setQuestionTitle(e.target.value)}
        margin="normal"
        required
        variant="outlined"
        sx={{ mb: 3 }}
      />
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Question Description
      </Typography>
      <Box sx={{ mb: 3, '& .ql-container': { minHeight: '200px' } }}>
        <ReactQuill
          theme="snow"
          value={questionDescription}
          onChange={setQuestionDescription}
          placeholder="Enter question description"
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'image'],
              ['clean']
            ],
          }}
        />
      </Box>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
       
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
        >
          Save and Next
        </Button>
      </Stack>
    </Box>
  );

  const renderTestCaseForm = () => (
    <Box component="form" onSubmit={handleTestcaseSubmit} noValidate sx={{ mt: 3 }}>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Test Case
          </Typography>
          <TextField
            fullWidth
            label="Input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Output"
            value={output}
            onChange={(e) => setOutput(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Points"
            type="number"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
            margin="normal"
            required
            sx={{ mb: 2 }}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={handleBackToFirstStep}
            >
              Back to Question Details
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Add Test Case
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleGoBackToQuestions}
            >
              Submit Question
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {testCases.length > 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Test Cases
            </Typography>
            <List>
              {testCases.map((tc, idx) => (
                <React.Fragment key={idx}>
                  <ListItem>
                    <ListItemText
                      primary={`Test Case ${idx + 1}`}
                      secondary={
                        <Box>
                          <Typography component="span" variant="body2" color="text.primary">
                            Input: {tc.input}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Output: {tc.output}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  {idx < testCases.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Box>
  );

  return (
    <Container maxWidth="md">

<Box sx={{ py: 4 }}>
        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBackToQuestions}
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Back to Questions
        </Button>
        </Box>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create Question
        </Typography>
        
        <Stepper activeStep={step - 1} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {step === 1 ? renderQuestionForm() : renderTestCaseForm()}
      </Paper>
    </Container>
  );
};

export default CreateQuestion;