import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Security = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Prevent going back if on test page
    if (location.pathname === '/test-section') {
      // Block browser back button
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, '', window.location.href);
      };

      // Block keyboard shortcuts
      const blockNavigation = (e) => {
        if (
          // Block Alt+Left Arrow
          (e.altKey && e.keyCode === 37) ||
          // Block Backspace when not in an input/textarea
          (e.keyCode === 8 && 
           !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName) &&
           !document.activeElement.classList.contains('monaco-editor'))
        ) {
          e.preventDefault();
          return false;
        }
      };

      // Add keyboard event listener
      document.addEventListener('keydown', blockNavigation);

      return () => {
        window.onpopstate = null;
        document.removeEventListener('keydown', blockNavigation);
      };
    }

    // Prevent copy/paste in code editor
    const preventCopyPaste = (e) => {
      if (e.target.classList.contains('monaco-editor')) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('copy', preventCopyPaste);
    document.addEventListener('paste', preventCopyPaste);
    document.addEventListener('cut', preventCopyPaste);

    // Prevent right-click on code editor
    document.addEventListener('contextmenu', (e) => {
      if (e.target.classList.contains('monaco-editor')) {
        e.preventDefault();
        return false;
      }
    });

    return () => {
      window.onpopstate = null;
      document.removeEventListener('copy', preventCopyPaste);
      document.removeEventListener('paste', preventCopyPaste);
      document.removeEventListener('cut', preventCopyPaste);
    };
  }, [location.pathname]);

  return null;
};

export default Security;