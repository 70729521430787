import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
const AnalysisQuestionNavigation = ({
  questions = [],
  currentQuestion,
  onQuestionSelect,
  isOpen,
  onToggle,
  totalQuestions,
}) => {
  const handleQuestionClick = (type, globalIndex) => {
    onQuestionSelect({ type, index: globalIndex });
  };
  return (
    <div
      className={`absolute right-0 top-0 h-full bg-white shadow-lg transition-all duration-300 transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{
        width: "300px",
        zIndex: 1000,
      }}
    >
      <button
        onClick={onToggle}
        className="absolute -left-10 top-1/2 bg-blue-600 text-white p-2 rounded-l-lg"
      >
        {isOpen ? <FaChevronRight /> : <FaChevronLeft />}
      </button>
      <div className="p-6 overflow-y-auto h-full">
        {/* Progress Summary */}
        <div className="mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-2">Question Overview</h3>
          <div className="flex justify-between items-center mb-2">
            <span>Total Questions:</span>
            <span className="font-medium">{totalQuestions}</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Current Question:</span>
            <span className="font-medium">{currentQuestion.index + 1}</span>
          </div>
        </div>
        <h3 className="text-lg font-semibold mb-4">Questions</h3>
        <div className="grid grid-cols-3 gap-3">
          {questions.map((q, idx) => {
            const questionId = q.mcq_question_id || q.question_id;
            const isCurrent = currentQuestion.index === idx;
            const score = q.executionData?.points_earned || 0;
            const maxPoints = q.mcq_question_id
              ? q.questionData?.points || 0
              : q.testCases?.reduce((sum, tc) => sum + tc.points, 0) || 0;
            const isCorrect = score === maxPoints;
            const isAttempted = q.executionData !== null;
            return (
              <button
                key={questionId}
                onClick={() =>
                  handleQuestionClick(q.mcq_question_id ? "mcq" : "coding", idx)
                }
                className={`
       h-12 w-12 flex items-center justify-center rounded-lg
       text-base font-medium transition-all duration-200
       ${isCurrent ? "ring-2 ring-blue-500 bg-blue-100" : ""}
       ${
         !isAttempted
           ? "bg-gray-100 border-2 border-gray-400 text-gray-600"
           : isCorrect
           ? "bg-green-100 border-2 border-green-600 text-green-700"
           : "bg-red-100 border-2 border-red-600 text-red-700"
       }
     `}
                title={`Question ${idx + 1} (${
                  q.mcq_question_id ? "MCQ" : "Coding"
                }) - Score: ${score}/${maxPoints}`}
              >
                {idx + 1}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AnalysisQuestionNavigation;
