// import React from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Tooltip,
//   Stack
// } from '@mui/material';
// import {
//   Visibility as VisibilityIcon,
//   Email as EmailIcon,
//   Delete as DeleteIcon,
//   Group as GroupIcon,
//   Add as AddIcon,
//   PlaylistAdd as BulkAddIcon,
//   PlaylistRemove as BulkDeleteIcon,
//   Close as CloseIcon
// } from '@mui/icons-material';

// const UserParticipants = ({
//   theme,
//   tests,
//   participants,
//   handleShowAllParticipants,
//   handleCreateParticipant,
//   handleBulkCreateParticipant,
//   handleBulkDeleteParticipant,
//   handleShowParticipants,
//   handleSendEmail,
//   handleDeleteParticipant,
//   showAllParticipants,
//   setShowAllParticipants,
//   view
// }) => {
//   return (
//     <>
//       {view === "participants" && (
//         <Box sx={{ p: 3 }}>
//           {/* Header Section */}
//           <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
//             <GroupIcon
//               sx={{
//                 fontSize: 40,
//                 color: theme.palette.primary.main,
//                 mr: 2
//               }}
//             />
//             <Typography variant="h4" component="h1" color="primary">
//               Participants
//             </Typography>
//           </Box>

//           {/* Action Buttons */}
//           <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
//             <Button
//               variant="outlined"
//               startIcon={<VisibilityIcon />}
//               onClick={handleShowAllParticipants}
//             >
//               Show Participants
//             </Button>
//             <Button
//               variant="contained"
//               startIcon={<AddIcon />}
//               onClick={handleCreateParticipant}
//             >
//               Add Participant
//             </Button>
//             <Button
//               variant="contained"
//               startIcon={<BulkAddIcon />}
//               onClick={handleBulkCreateParticipant}
//             >
//               Bulk Add
//             </Button>
//             <Button
//               variant="outlined"
//               color="error"
//               startIcon={<BulkDeleteIcon />}
//               onClick={handleBulkDeleteParticipant}
//             >
//               Bulk Delete
//             </Button>
//           </Stack>

//           {/* Tests Table */}
//           <TableContainer component={Paper} elevation={2}>
//             <Table sx={{ minWidth: 650 }}>
//               <TableHead>
//                 <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
//                   <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Test ID</TableCell>
//                   <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Title</TableCell>
//                   <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Show Participants</TableCell>
//                   <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Send Email</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {tests && tests.length > 0 ? (
//                   tests.map((test) => (
//                     <TableRow
//                       key={test.test_id}
//                       sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}
//                     >
//                       <TableCell>{test.test_id}</TableCell>
//                       <TableCell>{test.test_title}</TableCell>
//                       <TableCell>
//                         <Tooltip title="View participants">
//                           <IconButton
//                             color="primary"
//                             onClick={() => handleShowParticipants(test.test_id)}
//                           >
//                             <VisibilityIcon />
//                           </IconButton>
//                         </Tooltip>
//                       </TableCell>
//                       <TableCell>
//                         <Tooltip title="Send email to participants">
//                           <IconButton
//                             color="primary"
//                             onClick={() => handleSendEmail(test.test_id)}
//                           >
//                             <EmailIcon />
//                           </IconButton>
//                         </Tooltip>
//                       </TableCell>
//                     </TableRow>
//                   ))
//                 ) : (
//                   <TableRow>
//                     <TableCell colSpan={4} align="center">
//                       <Typography variant="subtitle1" color="text.secondary">
//                         No tests found
//                       </Typography>
//                     </TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* Participants Dialog */}
//           <Dialog
//             open={showAllParticipants}
//             onClose={() => setShowAllParticipants(false)}
//             maxWidth="md"
//             fullWidth
//           >
//             <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//               <Typography variant="h6">Participants List</Typography>
//               <IconButton onClick={() => setShowAllParticipants(false)}>
//                 <CloseIcon />
//               </IconButton>
//             </DialogTitle>
//             <DialogContent>
//               <TableContainer>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>First Name</TableCell>
//                       <TableCell>Last Name</TableCell>
//                       <TableCell>Email</TableCell>
//                       <TableCell>Actions</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {participants.map((participant) => (
//                       <TableRow key={participant.email}>
//                         <TableCell>{participant.first_name}</TableCell>
//                         <TableCell>{participant.last_name}</TableCell>
//                         <TableCell>{participant.email}</TableCell>
//                         <TableCell>
//                           <Tooltip title="Delete participant">
//                             <IconButton
//                               color="error"
//                               onClick={() => handleDeleteParticipant(participant.email)}
//                             >
//                               <DeleteIcon />
//                             </IconButton>
//                           </Tooltip>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={() => setShowAllParticipants(false)} color="primary">
//                 Close
//               </Button>
//             </DialogActions>
//           </Dialog>

//         </Box>
//       )}
//     </>
//   );
// };

// export default UserParticipants;

import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import {
  Email as EmailIcon,
  Delete as DeleteIcon,
  Group as GroupIcon,
  Add as AddIcon,
  PlaylistAdd as BulkAddIcon,
  PlaylistRemove as BulkDeleteIcon,
} from "@mui/icons-material";
import axios from "axios";

const UserParticipants = ({
  theme,
  participants,
  setParticipants,
  handleCreateParticipant,
  handleBulkCreateParticipant,
  handleBulkDeleteParticipant,
  handleSendEmail,
  handleDeleteParticipant,
  view,
  userId,
  token,
  showNotification,
}) => {
  const fetchParticipants = async () => {
    try {
      // console.log("token", token);

      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header with token
          },
        }
      );

      setParticipants(response.data.data); // Assuming data is structured as { data: [...] }
    } catch (error) {
      console.error("Error fetching participants:", error);

      // Handle Axios errors
      if (error.response) {
        // Backend responded with an error status
        showNotification(
          "error",
          error.response.data.message || "Failed to fetch participants"
        );
      } else {
        // Network or other unexpected errors
        showNotification("error", "Failed to fetch participants");
      }
    }
  };

  useEffect(() => {
    if (view === "participants") {
      fetchParticipants();
    }
  }, [view]);

  return (
    <>
      {view === "participants" && (
        <Box sx={{ p: 3 }}>
          {/* Header Section */}
          {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <GroupIcon
              sx={{
                fontSize: 40,
                color: theme.palette.primary.main,
                mr: 2
              }}
            />
            <Typography variant="h4" component="h1" color="primary">
              Participants
            </Typography>
          </Box> */}

          {/* Action Buttons */}
          <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateParticipant}
            >
              Add Participant
            </Button>
            <Button
              variant="contained"
              startIcon={<BulkAddIcon />}
              onClick={handleBulkCreateParticipant}
            >
              Bulk Add
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<BulkDeleteIcon />}
              onClick={handleBulkDeleteParticipant}
            >
              Bulk Delete
            </Button>
          </Stack>

          {/* Participants Table */}
          <TableContainer component={Paper} elevation={2}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    First Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Last Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Email
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participants && participants.length > 0 ? (
                  participants.map((participant) => (
                    <TableRow
                      key={participant.email}
                      sx={{
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell>{participant.first_name}</TableCell>
                      <TableCell>{participant.last_name}</TableCell>
                      <TableCell>{participant.email}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {/* <Tooltip title="Send email to participant">
                            <IconButton
                              color="primary"
                              onClick={() => handleSendEmail(participant.email)}
                            >
                              <EmailIcon />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="Delete participant">
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleDeleteParticipant(participant.email)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="subtitle1" color="text.secondary">
                        No participants found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default UserParticipants;
