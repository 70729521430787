// CombineTests.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack
  
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
  Delete as DeleteIcon,
  Warning as WarningIcon,
  Assignment as AssignmentIcon,
  Group as PeopleIcon,
  Leaderboard as LeaderboardIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import CreateCombineTest from "./CreateCombineTest";
import TestViewModal from "./TestViewModal";
import ManageParticipants from "../ManageParticipants";
import { useNavigate } from "react-router-dom";
import ScoreBoard from "./ScoreBoard";

const CombineTests = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [showCreateTest, setShowCreateTest] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    testId: null,
    testTitle: "",
  });
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [showManageParticipants, setShowManageParticipants] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [showScoreboard, setShowScoreboard] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const theme = useTheme();

  // Fetch all tests
  const fetchTests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/org/tests`, {
        // params: { createdBy: userId },
        headers: { Authorization: `Bearer ${token}` },
      });
      setTests(response.data.data);
    } catch (error) {
      console.error("Error fetching tests:", error);
      // toast.error("Failed to fetch tests");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
  }, []);

  const handleTestCreated = () => {
    fetchTests();
    setShowCreateTest(false);
  };

  const handleEditTest = async (testId) => {
    try {
      // Fetch complete test details
      const response = await axios.get(
        `${BASE_URL}/api/org/tests/get-test-data/${testId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        const testData = response.data.data;
        setSelectedTest(testData);
        setShowCreateTest(true);
      }
    } catch (error) {
      console.error("Error fetching test details:", error);
      // toast.error("Failed to fetch test details");
    }
  };

  const handleDeleteClick = (testId, testTitle) => {
    setDeleteDialog({
      open: true,
      testId,
      testTitle,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `${BASE_URL}/api/org/tests/delete-test/${deleteDialog.testId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Test deleted successfully");
      fetchTests();
    } catch (error) {
      console.error("Error deleting test:", error);
      toast.error("Failed to delete test");
    } finally {
      setDeleteDialog({ open: false, testId: null, testTitle: "" });
    }
  };

  const handleViewTest = async (testId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/tests/get-test-data/${testId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSelectedTest(response.data.data);
      setViewModalOpen(true);
    } catch (error) {
      console.error("Error fetching test details:", error);
      toast.error("Failed to fetch test details");
    }
  };

  if (showCreateTest) {
    return (
      <CreateCombineTest
        onClose={() => {
          setShowCreateTest(false);
          setSelectedTest(null);
        }}
        onTestCreated={handleTestCreated}
        editTest={selectedTest}
      />
    );
  }

  if (showManageParticipants) {
    return (
      <ManageParticipants
        testId={selectedTestId}
        onClose={() => {
          setShowManageParticipants(false);
          setSelectedTestId(null);
        }}
      />
    );
  }

  const handleManageParticipants = (testId) => {
    setSelectedTestId(testId);
    setShowManageParticipants(true);
  };

  const handleSendEmail = (testId) => {
    const selectedTest = tests.find(test => test.test_id === testId);

    if (!selectedTest) {
      toast.warning("Test not found");
      return;
    }

    setSelectedTestId(testId);
    setIsEmailDialogOpen(true);
  };

  const handleEmailSendConfirmation = async () => {
    setIsEmailDialogOpen(false);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/mail/notify/${selectedTestId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Emails sent successfully!");
      } else {
        throw new Error(response.data.error || "Failed to send emails");
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      if (error.response?.status === 404) {
        toast.warning(
          "No participants found for this test. Please add participants before sending emails."
        );
      } else {
        toast.error(
          error.message || "Failed to send emails. Please try again."
        );
      }
    }
  };


  // handler for scoreboard
  const handleShowScoreboard = (testId) => {
    setSelectedTestId(testId);
    setShowScoreboard(true);
  };

  if (showScoreboard) {
    return (
      <ScoreBoard
        testId={selectedTestId}
        onClose={() => {
          setShowScoreboard(false);
          setSelectedTestId(null);
        }}
      />
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
       
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            gap: 2,
          }}
        >
          <AssignmentIcon
            sx={{
              fontSize: 38,
              color: theme.palette.primary.main,
            }}
          />
          <Typography variant="h4" component="h1">
            Tests
          </Typography>
        </Box> */}

        {/* Create Button Section */}
        <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
  <Button
    variant="contained"
    startIcon={<AddIcon />}
    onClick={() => setShowCreateTest(true)}
    
  >
    Create Test
  </Button>
</Stack>

        {/* Tests Table/Empty State Section */}
        {tests.length > 0 ? (
          <TableContainer
            component={Paper}
            elevation={2}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Test ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Title
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tests.map((test) => (
                  <TableRow
                    key={test.test_id}
                    sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                  >
                    <TableCell>{test.test_id}</TableCell>
                    <TableCell>{test.test_title}</TableCell>
                    <TableCell 
  sx={{ 
    maxWidth: '300px',  // Fixed width for description column
    whiteSpace: 'normal',
    '& > div': {
      maxHeight: '100px',  // Fixed height
      overflowY: 'auto',   // Add vertical scrollbar when needed
      overflowX: 'hidden', // Prevent horizontal scrollbar
      padding: '4px',      // Add some padding for the scrollbar
      '&::-webkit-scrollbar': {
        width: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  }}
>
  <div>
    {test.test_description}
  </div>
</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Tooltip title="View Test">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleViewTest(test.test_id)}
                          >
                            <ViewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Test">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleEditTest(test.test_id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Manage Participants">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleManageParticipants(test.test_id)
                            }
                          >
                            <PeopleIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Send Email to Participants">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleSendEmail(test.test_id)}
                  >
                    <EmailIcon />
                  </IconButton>
                  </Tooltip>

                        <Tooltip title="View Scoreboard">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleShowScoreboard(test.test_id)}
                          >
                            <LeaderboardIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Test">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() =>
                              handleDeleteClick(test.test_id, test.test_title)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Card
            elevation={2}
            sx={{
              textAlign: "center",
              py: 8,
              px: 4,
              backgroundColor: "background.paper",
              borderRadius: 2,
            }}
          >
            <CardContent>
              <AssignmentIcon
                sx={{
                  fontSize: 60,
                  color: "text.secondary",
                  mb: 2,
                }}
              />
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No Tests Yet
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={3}>
                Create your first test to start evaluating candidates
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setShowCreateTest(true)}
                sx={{
                  px: 4,
                  py: 1.5,
                  boxShadow: 2,
                  "&:hover": {
                    boxShadow: 4,
                  },
                }}
              >
                Create Test
              </Button>
            </CardContent>
          </Card>
        )}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() =>
          setDeleteDialog({ open: false, testId: null, testTitle: "" })
        }
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "500px",
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "error.main",
            pb: 1,
          }}
        >
          <WarningIcon color="error" />
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Are you sure you want to delete the test{" "}
            <strong>"{deleteDialog.testTitle}"</strong>?
          </DialogContentText>
          <DialogContentText
            sx={{
              backgroundColor: "warning.light",
              p: 2,
              borderRadius: 1,
              color: "warning.dark",
              fontSize: "0.9rem",
            }}
          >
            This action cannot be undone. All test data will be permanently
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() =>
              setDeleteDialog({ open: false, testId: null, testTitle: "" })
            }
            variant="outlined"
            sx={{ minWidth: "100px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            sx={{ minWidth: "100px" }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

       {/* Add Email Confirmation Dialog */}
       <Dialog
        open={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "500px",
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            pb: 1,
          }}
        >
          <EmailIcon color="primary" />
          Send Email to Participants
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Are you sure you want to send emails to all participants of this test?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setIsEmailDialogOpen(false)}
            variant="outlined"
            sx={{ minWidth: "100px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEmailSendConfirmation}
            variant="contained"
            color="primary"
            sx={{ minWidth: "100px" }}
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>


      {/* Test View Modal */}
      <TestViewModal
        open={viewModalOpen}
        onClose={() => {
          setViewModalOpen(false);
          setSelectedTest(null);
        }}
        test={selectedTest}
      />
    </Container>
  );
};

export default CombineTests;