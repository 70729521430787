import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  Tooltip,
  Snackbar,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import {
  Edit as EditIcon,
  Notes as NotesIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import axios from 'axios';

const AdminEnquiry = () => {
  // States for data management
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // States for dialogs and notifications
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [adminNote, setAdminNote] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const statusOptions = [
    'Followed Up',
    'Waiting For Update',
    'Close - No Response',
    'Close - Not Interested',
    'Close - Onboard'
  ];

  // Function to get status chip color
  const getStatusColor = (status) => {
    const colorMap = {
      'Followed Up': 'primary',
      'Waiting For Update': 'warning',
      'Close - No Response': 'error',
      'Close - Not Interested': 'error',
      'Close - Onboard': 'success'
    };
    return colorMap[status] || 'default';
  };

  // API calls
  const fetchEnquiries = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/admin`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.success) {
        setEnquiries(response.data.data);
      } else {
        throw new Error('Failed to fetch enquiries');
      }
    } catch (error) {
      setError('Error fetching enquiries: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/admin/request-status`,
        {
          requestDemoId: selectedRequest.request_demo_id,
          requestStatus: newStatus
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      if (response.data.success) {
        setSnackbar({
          open: true,
          message: 'Status updated successfully',
          severity: 'success'
        });
        fetchEnquiries();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error updating status',
        severity: 'error'
      });
    } finally {
      setOpenStatusDialog(false);
    }
  };

  const updateAdminNote = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/admin/admin-note`,
        {
          requestDemoId: selectedRequest.request_demo_id,
          adminNote: adminNote
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      if (response.data.success) {
        setSnackbar({
          open: true,
          message: 'Admin note updated successfully',
          severity: 'success'
        });
        fetchEnquiries();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error updating admin note',
        severity: 'error'
      });
    } finally {
      setOpenNotesDialog(false);
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  // Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusClick = (request) => {
    setSelectedRequest(request);
    setNewStatus(request.request_status);
    setOpenStatusDialog(true);
  };

  const handleNotesClick = (request) => {
    setSelectedRequest(request);
    setAdminNote(request.admin_note || '');
    setOpenNotesDialog(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}> 
      {/* Header Section */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mb={3}
        sx={{ 
          borderBottom: '2px solid #007bff',
          pb: 2
        }}
      >
        <Typography 
          variant="h4" 
          component="h2"
          sx={{ 
            fontWeight: 600,
            color: '#2c3e50'
          }}
        >
          Demo Requests
        </Typography>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={fetchEnquiries}
          sx={{
            bgcolor: '#007bff',
            '&:hover': {
              bgcolor: '#0056b3'
            }
          }}
        >
          Refresh
        </Button>
      </Box>

      {/* Enhanced Stats Cards */}
      <Grid container spacing={2} mb={3}>
        {statusOptions.map((status) => {
          const count = enquiries.filter(e => e.request_status === status).length;
          return (
            <Grid item xs={12} sm={6} md={2.4} key={status}>
              <Card 
                elevation={2}
                sx={{
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: 3
                  }
                }}
              >
                <CardContent>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    {status}
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#007bff', fontWeight: 600 }}>
                    {count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Enhanced Main Table */}
      <Paper 
        sx={{ 
          width: '100%', 
          mb: 2, 
          overflow: 'hidden',
          boxShadow: 3,
          borderRadius: 2
        }}
      >
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader sx={{ minWidth: 750 }} aria-label="demo requests table">
            <TableHead>
              <TableRow>
                {[
                  'Name', 'Email', 'Company', 'Job Title', 
                  'Company Size', 'Country', 'Status', 
                  'Requested At', 'Actions'
                ].map((header) => (
                  <TableCell 
                    key={header}
                    sx={{
                      bgcolor: '#007bff',
                      color: 'white',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      '&:hover': {
                        bgcolor: '#0056b3'
                      }
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {enquiries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow 
                    key={row.request_demo_id}
                    hover
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': {
                        bgcolor: '#f8f9fa'
                      }
                    }}
                  >
                    <TableCell sx={{ fontWeight: 500 }}>{`${row.first_name} ${row.last_name}`}</TableCell>
                    <TableCell>{row.work_email}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{row.job_title}</TableCell>
                    <TableCell>{row.company_size}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.request_status}
                        color={getStatusColor(row.request_status)}
                        size="small"
                        onClick={() => handleStatusClick(row)}
                        sx={{ 
                          fontWeight: 500,
                          '&:hover': {
                            transform: 'scale(1.05)'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(row.requested_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Update Notes">
                        <IconButton 
                          size="small"
                          onClick={() => handleNotesClick(row)}
                          sx={{
                            color: '#007bff',
                            '&:hover': {
                              bgcolor: '#e3f2fd'
                            }
                          }}
                        >
                          <NotesIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={enquiries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            bgcolor: '#f8f9fa',
            borderTop: '1px solid #dee2e6'
          }}
        />
      </Paper>

      {/* Enhanced Dialogs */}
      <Dialog 
        open={openStatusDialog} 
        onClose={() => setOpenStatusDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3
          }
        }}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            margin="dense"
            label="Status"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStatusDialog(false)}>Cancel</Button>
          <Button onClick={updateStatus} variant="contained" color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notes Dialog */}
      <Dialog 
        open={openNotesDialog} 
        onClose={() => setOpenNotesDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Update Notes</DialogTitle>
        <DialogContent>
          {selectedRequest && (
            <>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Client Note:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={selectedRequest.client_note || 'No client note provided'}
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="dense"
              />
              <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }} gutterBottom>
                Admin Note:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={adminNote}
                onChange={(e) => setAdminNote(e.target.value)}
                variant="outlined"
                margin="dense"
                placeholder="Enter admin notes here..."
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNotesDialog(false)}>Cancel</Button>
          <Button onClick={updateAdminNote} variant="contained" color="primary">
            Save Notes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminEnquiry;