import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Avatar,
  Paper,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  Skeleton,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CameraAlt,
  Edit,
  Save,
  Cancel,
  BusinessCenter,
  Person,
  Business,
  LocationOn,
  ContactPhone,
} from "@mui/icons-material";
import axios from "axios";
import { profileImageStore } from "./profileImageStore";

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  borderRadius: theme.spacing(2),
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const HeaderCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(2),
  background: "linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
}));

const AvatarSection = styled(Box)(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(3),
}));

const CardTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
}));

const AvatarUploadContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
});

const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  borderRadius: "50%",
});

const ActionButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}));

const OrgProfile = ({ onUpdateProfile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [addressId, setAddressId] = useState(null);
  const [originalUsername, setOriginalUsername] = useState(""); // Add this line
  const [originalOrgName, setOriginalOrgName] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  const [formData, setFormData] = useState({
    orgName: "",
    username: "",
    contactPersonName: "",
    companyName: "",
    gstNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    contactNumber: "",
    contactEmail: "",
  });

  useEffect(() => {
    fetchOrgProfile();
  }, []);

  // Updated function to handle S3 URLs with authentication parameters
  const sanitizeImageUrl = (url) => {
    if (!url) return null;

    try {
      // Remove any double encoding of the S3 URL
      const decodedUrl = decodeURIComponent(url);

      // Check if URL contains multiple S3 paths
      if (decodedUrl.includes("tc-engg.s3.ap-south-1.amazonaws.com") > 1) {
        // Extract the actual image path and auth params
        const matches = decodedUrl.match(
          /tc-engg\.s3\.ap-south-1\.amazonaws\.com\/(.+?)(\?.*)?$/
        );
        if (matches && matches[1]) {
          const actualPath = matches[1];
          const authParams = matches[2] || "";
          return `https://tc-engg.s3.ap-south-1.amazonaws.com/${actualPath}${authParams}`;
        }
      }

      return decodedUrl;
    } catch (error) {
      console.error("Error sanitizing image URL:", error);
      return url;
    }
  };

  // Update fetchOrgProfile to handle image URLs
  const fetchOrgProfile = async () => {
    setLoading(true);
    setImageLoading(true);
    const userId = localStorage.getItem("user_id");

    if (!userId) {
      setSnackbar({
        open: true,
        message: "User ID is missing. Please log in again.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/org/org-profile/get-profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const profileData = response.data.data;
        if (profileData) {
          setOriginalOrgName(profileData.name || "");
          setOriginalUsername(profileData.username || "");
          setFormData({
            orgName: profileData.name || "",
            username: profileData.username || "",
            contactPersonName: profileData.contact_person_name || "",
            companyName: profileData.company_name || "",
            gstNumber: profileData.gst_number || "",
            streetAddress: profileData.street_address || "",
            city: profileData.city || "",
            state: profileData.state || "",
            country: profileData.country || "",
            pincode: profileData.pincode || "",
            contactNumber: profileData.contact_number || "",
            contactEmail: profileData.contact_email || "",
          });
          setAddressId(profileData.address_id);

          // Handle profile photo URL
          if (profileData.profile_photo_url) {
            const sanitizedUrl = sanitizeImageUrl(
              profileData.profile_photo_url
            );

            const userId = localStorage.getItem("user_id");
            localStorage.setItem(`profile_image_${userId}`, sanitizedUrl);
            console.log("Original URL:", profileData.profile_photo_url);
            console.log("Sanitized URL:", sanitizedUrl);

            // Pre-load the image to check if it's accessible
            const img = new Image();
            img.onload = () => {
              setProfileImage(sanitizedUrl);
              setPreviewImage(sanitizedUrl);

              profileImageStore.notifyImageChange(sanitizedUrl);

              setImageError(false);
              setImageLoading(false);
            };
            img.onerror = () => {
              console.error("Failed to load image:", sanitizedUrl);
              setImageError(true);
              setImageLoading(false);
            };
            img.src = sanitizedUrl;
          } else {
            setImageLoading(false);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching organization profile:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch profile data.",
        severity: "error",
      });
      setImageLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 1 * 1024 * 1024; // Change to 1MB from 5MB
      
      if (!validTypes.includes(file.type)) {
        setSnackbar({
          open: true,
          message: "Please upload a valid image file (JPEG, PNG, or GIF)",
          severity: "error",
        });
        return;
      }
  
      if (file.size > maxSize) {
        setSnackbar({
          open: true,
          message: "File size should not exceed 1MB",
          severity: "error",
        });
        return;
      }

      setIsUploading(true);
      try {
        setProfileImage(file);
        setPreviewImage(URL.createObjectURL(file));

        profileImageStore.notifyImageChange(URL.createObjectURL(file));
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error processing image",
          severity: "error",
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^[0-9]{6}$/;

    if (!formData.orgName) errors.orgName = "Organization name is required";
    if (!formData.contactPersonName)
      errors.contactPersonName = "Contact person is required";
    if (!formData.companyName) errors.companyName = "Company name is required";

    // Organization name validation
    if (addressId && formData.orgName !== originalOrgName) {
      if (!formData.orgName.trim()) {
        errors.orgName = "Organization name is required";
      }
    }

    // Email validation
    if (!formData.contactEmail.trim()) {
      errors.contactEmail = "Email is required";
    } else if (!emailRegex.test(formData.contactEmail.trim())) {
      errors.contactEmail = "Please enter a valid email address";
    }

    // Mobile number validation
    if (!formData.contactNumber.trim()) {
      errors.contactNumber = "Mobile number is required";
    } else if (!mobileRegex.test(formData.contactNumber.trim())) {
      errors.contactNumber = "Please enter a valid 10-digit mobile number";
    }

    if (!formData.pincode) {
      errors.pincode = "Pincode is required";
    } else if (!pincodeRegex.test(formData.pincode)) {
      errors.pincode = "Invalid pincode";
    }

    if (!formData.streetAddress)
      errors.streetAddress = "Street address is required";
    if (!formData.city) errors.city = "City is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.country) errors.country = "Country is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const userId = localStorage.getItem("user_id");
    if (!userId) {
      setSnackbar({
        open: true,
        message: "User ID is missing. Please log in again.",
        severity: "error",
      });
      return;
    }
    
    setLoading(true);
    try {
      const formDataToSend = new FormData();

      // Always include userId
      formDataToSend.append("userId", userId);

      // Add form fields based on context
      const fieldsToSend = {
        contactPersonName: formData.contactPersonName,
        companyName: formData.companyName,
        gstNumber: formData.gstNumber || "", // Handle empty GST
        streetAddress: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        pincode: formData.pincode,
        contactNumber: formData.contactNumber,
        contactEmail: formData.contactEmail,
      };

      // Only include `orgName` and `username` for updates
      if (addressId) {
        if (formData.username && formData.username !== originalUsername) {
          fieldsToSend.username = formData.username;
        }
        if (formData.orgName) {
          fieldsToSend.orgName = formData.orgName;
        }
      }

      // Add fields to FormData
      Object.entries(fieldsToSend).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formDataToSend.append(key, value.trim());
        }
      });

      // Include addressId for updates
      if (addressId) {
        formDataToSend.append("addressId", addressId);
      }

      // Include profile photo if it's a new file
      if (profileImage instanceof File) {
        formDataToSend.append("profilephoto", profileImage);
      }

      // Determine the endpoint and method
      const url = addressId
        ? `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/update-profile`
        : `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/create-profile`;

      const method = addressId ? "put" : "post";

      // Log FormData contents for debugging
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      const response = await axios({
        method,
        url,
        data: formDataToSend,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Update this section to handle success properly
      if (response.data.success) {
        // Update profile image if new URL is returned
        if (response.data.data?.profile_photo_url) {
          const imageUrl = sanitizeImageUrl(response.data.data.profile_photo_url);
          const userId = localStorage.getItem("user_id");
          localStorage.setItem(`profile_image_${userId}`, imageUrl);
          profileImageStore.notifyImageChange(imageUrl);
        }
  
        setSnackbar({
          open: true,
          message: addressId ? "Profile updated successfully" : "Profile created successfully",
          severity: "success",
        });
  
        await fetchOrgProfile();
        setIsEditing(false);
      } else {
        // Handle case where success is false but no error was thrown
        throw new Error(response.data.message || "Failed to save profile");
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || error.message || "Failed to save profile",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ProfileContainer>
      <HeaderCard elevation={3}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AvatarSection>
                <AvatarUploadContainer>
                  {imageLoading && !imageError && (
                    <Skeleton
                      variant="circular"
                      width={120}
                      height={120}
                      sx={{ position: "absolute" }}
                    />
                  )}
                  <Avatar
                    src={previewImage || profileImage}
                    sx={{
                      width: 120,
                      height: 120,
                      visibility: imageLoading ? "hidden" : "visible",
                      border: "4px solid white",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    }}
                    onLoad={() => {
                      setImageLoading(false);
                      setImageError(false);
                    }}
                    onError={(e) => {
                      setImageLoading(false);
                      setImageError(true);
                      const fallbackName = formData.orgName || "User";
                      const encodedName = encodeURIComponent(fallbackName);
                      e.target.src = `https://ui-avatars.com/api/?name=${encodedName}&background=random&size=120`;
                    }}
                  />
                  {isUploading && (
                    <LoadingOverlay>
                      <CircularProgress size={24} />
                    </LoadingOverlay>
                  )}
                 {isEditing && (
  <Box sx={{ 
    mt: 1, 
    textAlign: 'center',
    // Add marginBottom to create space between text and camera icon
    mb: 4  
  }}> 
    <Typography 
      variant="caption" 
      color="text.secondary"
      // Add display block and marginBottom to ensure text stays above icon
      sx={{ 
        display: 'block',
        mb: 2
      }}
    > 
      Upload image (Max size: 1MB) 
    </Typography>
    <label htmlFor="org-profile-image">
      <input
        accept="image/*"
        id="org-profile-image"
        type="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
        disabled={loading || isUploading}
      />
      <IconButton
        component="span"
        color="primary"
        disabled={loading || isUploading}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          backgroundColor: "white",
          boxShadow: 1,
        }}
      >
        <CameraAlt />
      </IconButton>
    </label>
  </Box>
)}
                </AvatarUploadContainer>
              </AvatarSection>

              <Box sx={{ textAlign: 'left' }}>
  <Typography variant="h4" fontWeight="600" gutterBottom>
    {formData.orgName || "Organization Name"}
  </Typography>
  <Typography variant="body1" color="text.secondary" gutterBottom>
    {formData.contactEmail}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    {formData.city}, {formData.country}
  </Typography>
</Box>
            </Box>

            <ActionButtons>
              {!isEditing ? (
                <IconButton
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  disabled={loading}
                >
                  <Edit />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    <Save />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setIsEditing(false);
                      setFormErrors({});
                      fetchOrgProfile();
                    }}
                    disabled={loading}
                  >
                    <Cancel />
                  </IconButton>
                </>
              )}
            </ActionButtons>
          </Box>
        </CardContent>
      </HeaderCard>

      <Grid container spacing={3}>
        {/* Personal Information Card */}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={3}>
            <CardContent>
              <CardTitle>
                <Person />
                <Typography variant="h6" fontWeight="600">
                  Personal Information
                </Typography>
              </CardTitle>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    disabled={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    name="contactPersonName"
                    value={formData.contactPersonName}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.contactPersonName}
                    helperText={formErrors.contactPersonName}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Company Information Card */}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={3}>
            <CardContent>
              <CardTitle>
                <Business />
                <Typography variant="h6" fontWeight="600">
                  Company Information
                </Typography>
              </CardTitle>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Organization Name"
                    name="orgName"
                    value={formData.orgName}
                    onChange={handleInputChange}
                    disabled={!addressId || !isEditing || loading}
                    required={addressId}
                    error={!!formErrors.orgName}
                    helperText={formErrors.orgName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.companyName}
                    helperText={formErrors.companyName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="GST Number"
                    name="gstNumber"
                    value={formData.gstNumber}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Address Information Card */}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={3}>
            <CardContent>
              <CardTitle>
                <LocationOn />
                <Typography variant="h6" fontWeight="600">
                  Address Information
                </Typography>
              </CardTitle>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Street Address"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.streetAddress}
                    helperText={formErrors.streetAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.city}
                    helperText={formErrors.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.state}
                    helperText={formErrors.state}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.pincode}
                    helperText={formErrors.pincode}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Contact Information Card */}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={3}>
            <CardContent>
              <CardTitle>
                <ContactPhone />
                <Typography variant="h6" fontWeight="600">
                  Contact Information
                </Typography>
              </CardTitle>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.contactNumber}
                    helperText={formErrors.contactNumber}
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                    required
                    error={!!formErrors.contactEmail}
                    helperText={formErrors.contactEmail}
                    type="email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ProfileContainer>
  );
};

export default OrgProfile;
