import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
 
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Styled components (keeping the same styling from original)
const StyledBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, #ffffff 10%, #4b7ccb 120%), url("/background-image.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
  },
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const FullWidthItem = styled(Box)({
  gridColumn: '1 / -1',
});

// Company size options
const companySizeOptions = [
  '1-100',
  '101-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10000+'
];

// Sample country list (you can expand this)
const countries = [
  'United States',
  'United Kingdom',
  'Canada',
  'Australia',
  'Germany',
  'France',
  'India',
  'Japan',
  'China',
  'Brazil'
].sort();

const RequestDemo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    companyName: '',
    jobTitle: '',
    companySize: '',
    phoneNumber: '',
    country: '',
    clientNote: '',
   
  });

  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.workEmail || !/\S+@\S+\.\S+/.test(formData.workEmail)) {
      newErrors.workEmail = 'Valid work email is required';
    }
    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.jobTitle) newErrors.jobTitle = 'Job title is required';
    if (!formData.companySize) newErrors.companySize = 'Company size is required';
    if (!formData.country) newErrors.country = 'Country is required';
 
    return newErrors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target; // removed checked
    setFormData(prev => ({
      ...prev,
      [name]: value // removed consent check
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/api/admin`,
          formData
        );

        if (response.data.success) {
          setSnackbarMessage('Thank you for your interest! We\'ll contact you soon.');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.data.message || 'Something went wrong. Please try again.');
          setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);

        if (response.data.success) {
          setFormData({
            firstName: '',
            lastName: '',
            workEmail: '',
            companyName: '',
            jobTitle: '',
            companySize: '',
            phoneNumber: '',
            country: '',
            clientNote: '',
           
          });
        }
      } catch (error) {
        console.error('Error sending form:', error);
        setSnackbarMessage('An error occurred. Please try again later.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <StyledBox>
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
        }}
      >
        <HomeIcon sx={{ fontSize: 28, color: '#4b7ccb' }} />
      </IconButton>
      
      <FormContainer maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom>
          Try it yourself
        </Typography>
        <Typography variant="h3" align="center" gutterBottom>
          book a personalized <span style={{ color: '#4b7ccb' }}>demo</span> today!
        </Typography>
        
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            name="firstName"
            label="First Name"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            required
          />
          
          <TextField
            name="lastName"
            label="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            required
          />
          
          <TextField
            name="workEmail"
            label="Work Email"
            type="email"
            value={formData.workEmail}
            onChange={handleChange}
            error={!!errors.workEmail}
            helperText={errors.workEmail}
            required
          />
          
          <TextField
            name="phoneNumber"
            label="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          
          <TextField
            name="companyName"
            label="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            error={!!errors.companyName}
            helperText={errors.companyName}
            required
          />
          
          <TextField
            name="jobTitle"
            label="Job Title"
            value={formData.jobTitle}
            onChange={handleChange}
            error={!!errors.jobTitle}
            helperText={errors.jobTitle}
            required
          />

          <FormControl error={!!errors.companySize} required>
            <InputLabel>Company Size</InputLabel>
            <Select
              name="companySize"
              value={formData.companySize}
              onChange={handleChange}
              label="Company Size"
            >
              {companySizeOptions.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            {errors.companySize && (
              <Typography variant="caption" color="error">
                {errors.companySize}
              </Typography>
            )}
          </FormControl>

          <FormControl error={!!errors.country} required>
            <InputLabel>Country</InputLabel>
            <Select
              name="country"
              value={formData.country}
              onChange={handleChange}
              label="Country"
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            {errors.country && (
              <Typography variant="caption" color="error">
                {errors.country}
              </Typography>
            )}
          </FormControl>
          
          <FullWidthItem>
            <TextField
              name="clientNote"
              label="Additional Information"
              multiline
              rows={4}
              fullWidth
              value={formData.clientNote}
              onChange={handleChange}
            />
          </FullWidthItem>
          
         
          
          <FullWidthItem>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#27ae60',
                color: '#fff',
                padding: '12px',
                '&:hover': {
                  backgroundColor: 'rgb(38, 161, 89)',
                  boxShadow: '0 0 4px 3px rgba(45, 148, 57, 0.19)',
                },
              }}
            >
              Submit
            </Button>
          </FullWidthItem>
        </StyledForm>
      </FormContainer>
      
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert 
          severity={snackbarSeverity} 
          onClose={() => setOpenSnackbar(false)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
};

export default RequestDemo;