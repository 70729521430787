import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../css/AdminDashboard.css";
import {
  FaUser,
  FaUserEdit,
  FaSignOutAlt,
  FaHome,
  FaTasks,
  FaPlus,
  FaClipboardList,
  FaEye,
  FaEnvelope,
} from "react-icons/fa";
import Modal from "../Modal";

import AdminPlans from "./AdminPlans";

// import AdminQuestions from './AdminQuestions';
import MCQQuestions from "../OrgDashboard/Questions/MCQQuestions";
import AdminEnquiry from "./AdminEnquiry";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [tests, setTests] = useState([]); // Add tests state
  const [admin, setAdmin] = useState({ name: "", userId: "" });
  const [showModal, setShowModal] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [editingTestId, setEditingTestId] = useState(null);
  const [questions, setQuestions] = useState([]); // Add this line

  const [isTestCaseDialogOpen, setIsTestCaseDialogOpen] = useState(false);
  const [selectedQuestionTestCases, setSelectedQuestionTestCases] = useState(
    []
  );
  const [createQuestionView, setCreateQuestionView] = useState(false);
  const [createBulkQuestionView, setCreateBulkQuestionView] = useState(false);

  const [step, setStep] = useState(1);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [points, setPoints] = useState(1);
  const [testCases, setTestCases] = useState([]);

  const [questionLimit, setQuestionLimit] = useState(10);
  const [testcaseLimitPerQuestion, setTestcaseLimitPerQuestion] = useState(5);
  const [questionCount, setQuestionCount] = useState(0);
  const [remainingQuestionCount, setRemainingQuestionCount] = useState(10);
  const [csvRowCount, setCsvRowCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isAssigningPlan, setIsAssigningPlan] = useState(false);

  const role = localStorage.getItem("role");

  useEffect(() => {
    if (role === "ORG") {
      navigate("/login");
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
    fetchAdminData();
  }, []);

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/user?type=users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Update the fetchPlans function to send user_id in the request body
  const fetchPlans = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan?user_id=${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setPlans(response.data.data);
      } else {
        console.error("Error fetching plans:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const assignPlanToUser = async (userId, planId) => {
    try {
      const planData = plans.find((plan) => plan.plan_id === planId);
      const planValidity = new Date().setDate(new Date().getDate() + 365); // 365 days from today

      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/admin/plans/assign-plan`,
        {
          userId,
          planId,
          amount: planData.annual_price, // Use the annual price by default
          planValidTill: new Date(planValidity).toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchUsers(); // Refresh users data after assigning plan
      setIsAssigningPlan(false);
      setSelectedUserId(null);
      setSelectedPlan("");
    } catch (error) {
      console.error("Error assigning plan:", error);
    }
  };

  const fetchAdminData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/user?type=admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const adminData = response.data[0];
      setAdmin({
        name: adminData.name,
        userId: adminData.user_id,
      });
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const deleteUser = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/api/user/${selectedUserId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(false);
      fetchUsers(); // refresh users after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setShowModal(true);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to sign out?");
    if (confirmLogout) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const handleSectionClick = (section) => {
    console.log(`Activating section: ${section}`);
    setActiveSection(section);
    if (section === "subscriptions") {
      fetchSubscriptions();
    }
    if (section === "users") {
      fetchPlans();
    }
    if (section === "plans") {
      // No data fetching needed for plans unless required
    }
  };

  const handleCreateQuestion = () => {
    setCreateQuestionView(true);
    setCreateBulkQuestionView(false);
    setActiveSection("createQuestion");
  };

  const handleBulkCreateQuestionBtnClick = () => {
    setCreateBulkQuestionView(true);
    setCreateQuestionView(false);
    setActiveSection("createBulkQuestions");
  };

  const handleShowTestCases = (testCases) => {
    // Logic to show test cases for a question
    console.log("Show test cases", testCases);
  };

  const handleEditQuestion = (questionId) => {
    // Logic to edit a question
    console.log("Edit question", questionId);
  };

  const handleDeleteQuestion = (questionId) => {
    // Logic to delete a question
    console.log("Delete question", questionId);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Add logic to save question details and move to the next step
    setStep(2);
  };

  const handleTestcaseSubmit = (e) => {
    e.preventDefault();
    // Logic to add test case to the list
    const newTestCase = { input, output, points };
    setTestCases([...testCases, newTestCase]);
    // Clear the input fields
    setInput("");
    setOutput("");
    setPoints(1);
  };

  const handleGoBackToQuestions = () => {
    setCreateQuestionView(false);
    setCreateBulkQuestionView(false);
    setActiveSection("questions");
    setStep(1);
    // Reset form fields
    setQuestionTitle("");
    setQuestionDescription("");
    setInput("");
    setOutput("");
    setPoints(1);
    setTestCases([]);
  };

  const handleGoBackToQuestionsView = () => {
    setCreateBulkQuestionView(false);
    setActiveSection("questions");
  };

  return (
    <div className="admin-dashboard">
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>TechnoCirrus</h2>
        </div>
        <ul>
          <li onClick={() => handleSectionClick("dashboard")}>
            <FaHome />
            <span>Dashboard</span>
          </li>
          <li onClick={() => handleSectionClick("users")}>
            <FaUser />
            <span>Users</span>
          </li>
          <li onClick={() => handleSectionClick("subscriptions")}>
            <FaTasks />
            <span>Subscription</span>
          </li>
          <li onClick={() => handleSectionClick("plans")}>
            <FaTasks />
            <span>Plans</span>
          </li>
          <li onClick={() => handleSectionClick("enquiry")}>
            <FaEnvelope />
            <span>Enquiry</span>
          </li>
        </ul>
        <div className="sidebar-footer">
          <button className="sidebar-logout-button" onClick={handleLogout}>
            <FaSignOutAlt />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
      <div className="content">
        {activeSection === "dashboard" && (
          <div className="dashboard-container">
            <div className="top-bar">
              <div className="user-info">
                <span>{admin.name}</span>
                <FaUser />
                <div className="user-dropdown">
                  <p>{admin.name}</p>
                </div>
              </div>
            </div>
            <div className="dashboard-header">
              <h1>Welcome to Admin Dashboard</h1>
            </div>
            <table className="dashboard-table">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.user_id}</td>
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    <td>
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteClick(user.user_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeSection === "users" && (
          <div className="users-container">
            <h1 className="bold-text">Number of Users: {users.length}</h1>
            <table className="user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>No of Tests</th>
                  <th>No of Participants</th>
                  <th>Plan</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.name}</td>
                    <td>{user.num_tests}</td>
                    <td>{user.num_participants}</td>
                    <td>{user.plan_name || "No Plan"}</td>
                    <td>
                      {isAssigningPlan && selectedUserId === user.user_id ? (
                        <div className="flex items-center gap-2">
                          <select
                            className="px-2 py-1 border rounded"
                            value={selectedPlan}
                            onChange={(e) => setSelectedPlan(e.target.value)}
                          >
                            <option value="">Select Plan</option>
                            {plans.map((plan) => (
                              <option key={plan.plan_id} value={plan.plan_id}>
                                {plan.plan_name} (
                                {plan.plan_type === 0 ? "Public" : "Private"})
                              </option>
                            ))}
                          </select>
                          <button
                            className="px-2 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
                            onClick={() =>
                              assignPlanToUser(user.user_id, selectedPlan)
                            }
                            disabled={!selectedPlan}
                          >
                            Confirm
                          </button>
                          <button
                            className="px-2 py-1 text-sm bg-gray-500 text-white rounded hover:bg-gray-600"
                            onClick={() => {
                              setIsAssigningPlan(false);
                              setSelectedUserId(null);
                              setSelectedPlan("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                          onClick={() => {
                            setIsAssigningPlan(true);
                            setSelectedUserId(user.user_id);
                          }}
                          title="Assign Plan"
                        >
                          <FaUserEdit />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeSection === "plans" && (
          <div className="plans-container">
            <AdminPlans />
          </div>
        )}

        {activeSection === "enquiry" && (
          <div className="enquiry-container">
            <AdminEnquiry />
          </div>
        )}

        {activeSection === "subscriptions" && (
          <div className="subscriptions-container">
            <h1 className="bold-text">Subscription Details</h1>
            <table className="subscription-table">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Subscription Type</th>
                  <th>Plan Validity</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription) => (
                  <tr key={subscription.user_id}>
                    <td>{subscription.user_id}</td>
                    <td>{subscription.username}</td>
                    <td>{subscription.plan_name}</td>
                    <td>{subscription.plan_valid_till}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={deleteUser}
      />
    </div>
  );
};

export default AdminDashboard;
