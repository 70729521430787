import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "../../css/SubFooter.css"; // Keeping the existing CSS import
import FooterHome from "../FooterHome";

const SubFooter = () => {
    return (
        <div className="subfooter">
            <div className="inner">
                <div className='column'>
            <img src="/logos/cirruscypher-full-dark-alpha.png" alt="CirrusCypher Logo" className="footer-logo" />
            </div>
            {/* <div className="column">
                <h4 className="subfooter-title">Information</h4>
                <ul>
                    <li><Link to="/faq">FAQ</Link></li>
                    <li><Link to="/our-work">Our Other Works</Link></li>
                </ul>
            </div> */}
            {/* <div className="column">
                <h4 className="subfooter-title">Get Started</h4>
                <ul>
                    <li><Link to="/get-started">Get Started</Link></li>
                    <li><Link to="/signup">Org Signup</Link></li>
                    <li><Link to="/login">Admin Login</Link></li>
                </ul>
            </div> */}
            <div className="column">
                <h4 className="subfooter-title">About Us</h4>
                <ul>
                     <li><Link to="/faq">FAQ</Link></li>
                    <li><Link to="/feature">Features</Link></li>
                    <li><Link to="/WhyCodeEditor">Why CirrusCypher</Link></li>
                    <li><HashLink smooth to="/#how-it-works">How it Works</HashLink></li>
                </ul>
            </div>
            <div className="column">
                <h4 className="subfooter-title">Support</h4>
                <ul>
                    <li><Link to="/subscription">Buy Subscription</Link></li>
                    <li><Link to="/contact-info">Contact Us</Link></li>
                    <li><Link to="/contact-info">Submit Your Enquiry</Link></li>
                </ul>
            </div>

             {/* Knowledge Section */}
             <div className="column">
                <h4 className="subfooter-title">Knowledge</h4>
                <ul>
                    <li><Link to="/blogs">Blogs</Link></li>
                    <li><Link to="/guides">Guides</Link></li>
                    <li><Link to="/documentation">Documentation</Link></li>                   
              </ul>
            </div>
             {/* preparation Section */}
             <div className="column">
                <h4 className="subfooter-title">Preparation</h4>
                <ul>
            <li><Link to="/tutorials">Tutorials</Link></li>
                    <li><Link to="/preparation">Interview Preparation</Link></li>
                  </ul>
            </div>

            <div className="column">
            <h4 className="subfooter-title">Legal</h4>
            <ul>
                <li><Link to="/policy-page/terms">Terms and Conditions</Link></li>
                <li><Link to="/policy-page/refund">Refund Policy</Link></li>
                <li><Link to="/policy-page/cancellation">Cancellation Policy</Link></li>
                <li><Link to="/policy-page/privacy">Privacy Policy</Link></li>
            </ul>
            </div>
            </div>
            <FooterHome/>
        </div>
    );
}

export default SubFooter;