import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import axios from "axios";

import {
  FaUsers,
  FaClipboardList,
  FaShoppingCart,
  FaSignOutAlt,
  FaPlus,
  FaTachometerAlt,
  FaEye,
  FaEnvelope,
  FaCalendarAlt,
  FaRegCalendarCheck,
  FaRegClipboard,
  FaChartBar,
  FaQuestionCircle,
  FaTrash,
  FaUserCircle,
  FaFileInvoiceDollar,
  FaLayerGroup,
} from "react-icons/fa";
import GroupsIcon from "@mui/icons-material/Groups";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { GroupWork as GroupWorkIcon } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import { showNotification } from "../../utils/notification";
import "../../css/UserDashboard.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import UserDashboardHeader from "./UserDashboardHeader";
import { v4 as uuidv4 } from "uuid";
// import CreateTest from "./CreateTest";
// import EditTest from "./EditTest";
import ManageParticipants from "./ManageParticipants";
import TestView from "./TestView";
import Scorecard from "./Scorecard";
import ScorecardModal from "./ScorecardModal";
import { processParticipantCSV } from "./participantUtils";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import MCQQuestions from "./Questions/MCQQuestions";
import TestTypeSelector from "./TestTypeSelector";
import MCQTests from "./MCQTests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OrgProfile from "./OrgProfile";

import QuestionsGroups from "./QuestionGroups/QuestionsGroups";
import BillingInvoice from "./BillingInvoice";
import CombineTests from "./Tests/CombineTests";
import UserDashboardView from "./UserDashboardView";
import UserParticipants from "./Participants/UserParticipants";
import CreateParticipants from "./Participants/CreateParticipants";
import BulkCreateParticipant from "./Participants/BulkCreateParticipants";
import BulkDeleteParticipant from "./Participants/BulkDeleteParticipant";
import UserQuestions from "./Questions/UserQuestions";
import CreateQuestion from "./Questions/CreateQuestion";
import CreateBulkQuestions from "./Questions/CreateBulkQuestions";
import UserSidebar from "./UserSidebar";
import Users from "./Users";
import UserSetting from "./UserSetting";

const UserDashboard = () => {
  const theme = useTheme();

  const token = localStorage.getItem("token");
  const [tests, setTests] = useState([]);
  const [view, setView] = useState("dashboard");
  const [previousView, setPreviousView] = useState("dashboard");
  const [submissionData, setSubmissionData] = useState(null);
  const [upcomingTests, setUpcomingTests] = useState([]);
  const [pastTests, setPastTests] = useState([]);
  const [currentTests, setCurrentTests] = useState([]);
  const [createQuestionView, setCreateQuestionView] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [participantEmails, setParticipantEmails] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [showAllParticipants, setShowAllParticipants] = useState(false);
  const [bulkDeleteParticipantView, setBulkDeleteParticipantView] =
    useState(false);
  const [createParticipantView, setCreateParticipantView] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [run, setRun] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isJoyrideShown, setIsJoyrideShown] = useState(false);
  const [activeItem, setActiveItem] = useState("dashboard");
  const [notifications, setNotifications] = useState([]);
  const [bulkCreateParticipantView, setBulkCreateParticipantView] =
    useState(false);
  const [participantAllowed, setParticipantAllowed] = useState(0);
  const [alreadySavedParticipantCount, setAlreadySavedParticipantCount] =
    useState(0);
  const [canAddParticipants, setCanAddParticipants] = useState(0);
  const [csvRowCount, setCsvRowCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [createBulkQuestionView, setCreateBulkQuestionView] = useState(false);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [testcaseLimitPerQuestion, setTestcaseLimitPerQuestion] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [remainingQuestionCount, setRemainingQuestionCount] = useState(0);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);

  const [questionType, setQuestionType] = useState(null);
  const [testType, setTestType] = useState(null);
  // const [mcqTests, setMcqTests] = useState([]);
  const [selectedTestType, setSelectedTestType] = useState(null);

  // Consolidated state for dashboard data
  const [dashboardData, setDashboardData] = useState({
    testCount: 0,
    todayTestCount: 0,
    upcomingTestCount: 0,
    participantCount: 0,
    planName: "Free Plan",
    planValidity: "No validity since free plan",
    orgName: "",
    maxTests: 0,
    maxParticipants: 0,
  });

  // Add these new utility functions
  const validateCSVStructure = (results) => {
    const headers = results.meta.fields;
    const requiredHeaders = ["question_title", "question_description"];
    const testcasePattern = /^testcase\d+-(input|output|points)$/;

    // Validate basic headers
    const hasRequiredHeaders = requiredHeaders.every((header) =>
      headers.includes(header)
    );

    if (!hasRequiredHeaders) {
      return {
        isValid: false,
        error:
          "CSV must contain question_title and question_description columns",
      };
    }

    // Validate testcase headers are in correct format
    const testcaseHeaders = headers.filter((header) =>
      testcasePattern.test(header)
    );
    const testcaseSets = new Set();

    testcaseHeaders.forEach((header) => {
      const match = header.match(/^testcase(\d+)/);
      if (match) testcaseSets.add(match[1]);
    });

    for (const num of testcaseSets) {
      const requiredTestcaseHeaders = [
        `testcase${num}-input`,
        `testcase${num}-output`,
        `testcase${num}-points`,
      ];
      if (
        !requiredTestcaseHeaders.every((header) => headers.includes(header))
      ) {
        return {
          isValid: false,
          error: `Incomplete testcase set ${num}. Each testcase requires input, output, and points`,
        };
      }
    }

    return { isValid: true };
  };

  const processCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          // Validate CSV structure
          const validation = validateCSVStructure(results);
          if (!validation.isValid) {
            reject(new Error(validation.error));
            return;
          }

          // Count rows and validate data
          const rowCount = results.data.length;
          const data = results.data;

          resolve({
            rowCount,
            data,
            headers: results.meta.fields,
          });
        },
        error: (error) => {
          reject(new Error("Error parsing CSV: " + error.message));
        },
      });
    });
  };

  // Handler for selecting question type
  const handleQuestionTypeSelect = (type) => {
    setQuestionType(type);
    // If 'coding' is selected, we keep the existing view
    // If 'mcq' is selected, we'll show the MCQQuestions component
    if (type === "mcq") {
      setView("mcqQuestions");
    } else {
      setView("questions");
    }
  };

  // Handler to go back to question type selection
  const handleBackToQuestionTypes = () => {
    setQuestionType(null);
    setView("questions");
  };

  const handleTestTypeSelect = (type) => {
    setTestType(type);
    if (type === "mcq") {
      setView("mcqTests");
    } else {
      setView("codingTests");
    }
  };

  const handleBackToTestTypes = () => {
    setTestType(null);
    setView("tests");
  };

  useEffect(() => {
    if (view !== "bulkDeleteParticipantView") {
      setIsButtonDisabled(true);
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    if (view !== "createBulkQuestionView") {
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    if (view !== "bulkCreateParticipantView") {
      setCsvRowCount(0);
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    console.log("Current view:", view);
  }, [view]);

  const handleBulkCreateParticipant = async (e) => {
    e.preventDefault();
    try {
      setBulkCreateParticipantView(true);
      setView("bulkCreateParticipant");
      const planLimitResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const participant_allowed = planLimitResponse.data.max_participants;
      setParticipantAllowed(participant_allowed);
      console.log("participant_allowed", participant_allowed);

      const getParticipantCount = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const already_saved_participant_count =
        getParticipantCount.data.participant_count;
      setAlreadySavedParticipantCount(already_saved_participant_count);
      console.log(
        "already_saved_participant_count",
        already_saved_participant_count
      );

      const can_add_participants =
        participant_allowed - already_saved_participant_count;
      setCanAddParticipants(can_add_participants);
      console.log("can_add_participants", can_add_participants);
    } catch (error) {
      console.error("Error bulk uploading participant data", error);
    }
  };

  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];

    try {
      const result = await processParticipantCSV(file);
      setCsvRowCount(result.rowCount);

      // Store the parsed data for later use
      localStorage.setItem("pendingParticipants", JSON.stringify(result.data));

      // Clear any previous error messages
      setErrorMessage("");
    } catch (error) {
      console.error("Error processing CSV file:", error);
      setErrorMessage(error.message);
      setCsvRowCount(0);
    }
  };

  const handleBulkSaveParticipant = async (e) => {
    e.preventDefault();

    const pendingParticipants = JSON.parse(
      localStorage.getItem("pendingParticipants") || "[]"
    );

    if (pendingParticipants.length === 0) {
      setErrorMessage("No valid participants data found");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("user_id", userId);

      // Create a new Blob from the parsed data
      const csvContent = Papa.unparse(pendingParticipants);
      const csvBlob = new Blob([csvContent], { type: "text/csv" });
      formData.append("csvFile", csvBlob, "participants.csv");

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/import`,
        formData, // Send formData instead of just userId
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data", // Add this header for file upload
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("pendingParticipants");
        setBulkCreateParticipantView(false);
        setView("participants");
      } else {
        setErrorMessage(response.data.error || response.data.message);
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };

  //delete bulk participant
  const handleBulkDeleteParticipant = async (e) => {
    e.preventDefault();
    try {
      setBulkDeleteParticipantView(true);
      setView("bulkDeleteParticipant");
    } catch (error) {
      console.error("Error initializing bulk delete participant view", error);
    }
  };

  const handleCSVDeleteUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/bulk/validate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { invalidEmails, duplicateEmails } = response.data;

      if (invalidEmails.length === 0 && duplicateEmails.length === 0) {
        setIsButtonDisabled(false);
      } else {
        if (invalidEmails.length !== 0) {
          setErrorMessage(`File contains invalid emails ${invalidEmails}`);
          setIsButtonDisabled(true);
        } else if (duplicateEmails.length !== 0) {
          setErrorMessage(`File contains duplicate emails ${duplicateEmails}`);
          setIsButtonDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error uploading CSV file for deletion", error);
      setIsButtonDisabled(true);
    }
  };

  const handleBulkDeleteParticipantSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/api/participants/org/bulk`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBulkDeleteParticipantView(false);
        setView("participants");
        setIsButtonDisabled(true);
      } else {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };

  //show participant
  const handleShowAllParticipants = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setParticipants(data.data);
        setShowAllParticipants(true);
      } else {
        showNotification("error", data.message);
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const handleDeleteParticipant = async (email) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this participant?"
    );
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          showNotification("error", "Authentication token is missing");
          return;
        }

        const response = await axios.delete(
          `${
            import.meta.env.VITE_BASE_URL
          }/api/participants/org/${email}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // data: { email }, // Sending the email in the request body if needed
          }
        );

        if (response.status === 200) {
          showNotification("success", response.data.message); // Use response data
          setParticipants((prevParticipants) =>
            prevParticipants.filter((p) => p.email !== email)
          );
        } else {
          showNotification(
            "error",
            response.data.message || "Failed to delete participant"
          );
        }
      } catch (error) {
        console.error("Error deleting participant:", error);

        if (error.response) {
          // Server responded with an error
          showNotification(
            "error",
            error.response.data.message || "Failed to delete participant"
          );
        } else {
          // Network or unexpected error
          showNotification(
            "error",
            "Failed to delete participant due to a network issue"
          );
        }
      }
    }
  };

  // Questions view // to show testcases using eye button
  const [selectedQuestionTestCases, setSelectedQuestionTestCases] = useState(
    []
  );
  const [isTestCaseDialogOpen, setIsTestCaseDialogOpen] = useState(false);

  const handleShowTestCases = (testCases) => {
    setSelectedQuestionTestCases(testCases);
    setIsTestCaseDialogOpen(true);
  };

  // ------------------------------------------------------
  // Questions view // to open edit question page when clicked on edit icon
  const handleEditQuestion = (questionId) => {
    navigate(`/edit-question/${questionId}`);
  };

  //---------------------------------------------------------

  // Placeholder redirect function
  const redirectTo = (section) => {
    switch (section) {
      case "participant":
        handleSetView("participants");
        break;
      case "tests":
        handleSetView("tests");
        break;
      case "plan":
        break;
      case "planValidity":
        break;
      case "upcomingTests":
        handleSetView("upcomingTests");
        break;
      case "currentTest":
        handleSetView("currentTests");
        break;
      default:
        console.log(`No redirection defined for ${section}`);
    }
  };

  const upgradePlan = () => {
    // Redirect to subscription page
    navigate("/subscription");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const joyrideShown = localStorage.getItem("isJoyrideShown");
    if (joyrideShown === "true") {
      setIsJoyrideShown(true);
      setRun(false); // Don't run Joyride if it has been shown
    } else if (isLoggedIn) {
      setRun(true); // Run Joyride if it hasn't been shown and user is logged in
    }
  }, [isLoggedIn]);

  const userId = localStorage.getItem("user_id");
  const userName = localStorage.getItem("user_name");

  const navigate = useNavigate();

  // api calls for fetching data on dashboard----------------------------------------------
  const [orgName, setOrgName] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [testCount, setTestCount] = useState("");
  const [planPurchased, setPlanPurchased] = useState("Free Plan");
  const [planValidity, setPlanValidity] = useState(
    "No validity since free plan"
  );
  const [upcomingTestCount, setUpcomingTestCount] = useState("");
  const [todayTestCount, setTodayTestCount] = useState("");

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/test/count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTestCount(response.data.tests_count);
      setTodayTestCount(response.data.today_test_count);
      setUpcomingTestCount(response.data.upcoming_test_count);

      const participantCountResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setParticipantCount(participantCountResponse.data.participant_count);

      try {
        // Attempt to get plan details
        const planDetailResponse = await axios.get(
          `${import.meta.env.VITE_BASE_URL}/api/plan/name-and-validity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPlanPurchased(planDetailResponse.data.plan_name);
        setPlanValidity(planDetailResponse.data.plan_valid_till);
        setOrgName(planDetailResponse.data.username);
      } catch (error) {
        // If the error is a 404, it means no plan exists, so create a free plan entry
        if (error.response && error.response.status === 404) {
          await axios.post(
            `${import.meta.env.VITE_BASE_URL}/api/payment/free`,
            { user_id: userId },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          console.error("Error fetching plan details:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    if (view === "participants") {
      fetchUserTests();
    }
  }, [view]);

  const fetchUserTests = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/org/tests`,
        {
          // params: { createdBy: userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        setTests(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user tests:", error);
      toast.error("Failed to fetch tests");
    }
  };

  useEffect(() => {
    fetchUserTests();
    // fetchMcqTests();

    fetchDashboardData();
  }, []);

  // Create Question View ------------------------------------------------------------------
  const [questionId, setQuestionId] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [step, setStep] = useState(1);

  const handleCreateQuestion = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const questionLimit = response.data.max_question;
      console.log("questionLimit", questionLimit);

      const questionCountResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/count`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const questionCount = questionCountResponse.data.question_count;
      console.log("question_count", questionCount);

      if (questionCount < questionLimit) {
        setView("createQuestion");
        setCreateQuestionView(true);
      } else {
        showNotification(
          "error",
          "Question creation limit reached, upgrade plan or delete existing questions"
        );
        return;
      }
    } catch (error) {
      console.error("error creating question", error);
    }

    // setView("createQuestion");
    // setCreateQuestionView(true);
  };

  const handleGoBack = () => {
    setCreateQuestionView(false);
    setView("questions");
  };

  const handleSubmitQuestion = async () => {
    try {
      const newQuestionId = uuidv4();
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/question`,
        {
          questionId: newQuestionId,
          questionTitle,
          questionDescription,
          // created_by: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(response.data);
      setQuestionTitle("");
      setQuestionDescription("");
      setQuestionId(newQuestionId);
      return true;
    } catch (error) {
      console.error("error creating question frontend", error);
      return false;
    }
  };

  const handleBackToFirstStep = () => {
    setStep(1);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const success = await handleSubmitQuestion();
    if (success) {
      setStep(2);
    }
  };

  // create testcases view--------------------------------------------------------
  const [testCases, setTestCases] = useState([]);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [points, setPoints] = useState(50);

  const handleTestcaseSubmit = async (e) => {
    e.preventDefault();
    try {
      const planResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const testcaseLimit = planResponse.data.max_testcases_per_question;
      console.log("testcaseLimit", testcaseLimit);

      const testcaseCountResponse = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/question/testcases/count?question_id=${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const testcaseCount = testcaseCountResponse.data.testcase_count;
      console.log("testcaseCount", testcaseCount);

      if (testcaseCount < testcaseLimit) {
        const response = await axios.post(
          `${
            import.meta.env.VITE_BASE_URL
          }/api/question/${questionId}/testcase`,
          {
            input,
            output,
            points,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setInput("");
        setOutput("");
        setPoints(50);
        setTestCases([...testCases, { input, output }]);
        console.log(questionId);
        console.log(response.data);
      } else {
        showNotification("error", "Test case creation limit reached");
        return;
      }
    } catch (error) {
      console.error("error creating testcase", error);
    }
  };

  const handleGoBackToQuestions = () => {
    setStep(1);
    setView("questions");
    setTestCases([]);
  };

  // Create Participant View ------------------------------------------------------------------

  const handleCreateParticipant = () => {
    setView("createParticipant");
    setCreateParticipantView(true);
  };

  const handleGoBackToParticipants = () => {
    setCreateParticipantView(false);
    setView("participants");
    setIsButtonDisabled(true);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSaveParticipant = async (e) => {
    e.preventDefault();
    try {
      const getPlanIdResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const participant_allowed = getPlanIdResponse.data.max_participants;
      console.log("participant_allowed", participant_allowed);

      const getParticipantCount = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participants/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const participantCount = getParticipantCount.data.participant_count;
      console.log("participantCount", participantCount);

      if (participantCount < participant_allowed) {
        if (!validateEmail(email)) {
          toast.error("Please enter a valid email address", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }

        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/api/participants/create`,
          {
            user_id: userId,
            first_name: firstName,
            last_name: lastName,
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log(response.data);

        // Success toast
        toast.success("Participant added successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
      } else {
        // Plan limit exceeded toast
        toast.error(
          "You have reached your limit for adding participants. Upgrade your plan to add more participants or delete existing participants",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      console.error("error creating participant", error);

      // Error toast for failed participant creation
      toast.error(
        error.response?.data?.error ||
          "Failed to add participant. Please try again.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  useEffect(() => {
    if (view === "questions") {
      fetchQuestions();
    } else if (view === "upcomingTests") {
      fetchUpcomingTests();
    } else if (view === "currentTests") {
      fetchCurrentTests();
    } else if (view === "pastTests") {
      fetchPastTests();
    }
  }, [view]);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleShowParticipants = (testId) => {
    setSelectedTestId(testId);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const fetchUpcomingTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/upcoming-tests`,
        {
          created_by: userId,
        }
      );
      setUpcomingTests(response.data);
    } catch (error) {
      console.error("Error fetching upcoming tests:", error);
    }
  };

  const fetchCurrentTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/current-tests`,
        {
          created_by: userId,
        }
      );
      setCurrentTests(response.data);
    } catch (error) {
      console.error("Error fetching current tests:", error);
    }
  };

  const fetchPastTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/past-tests`,
        {
          created_by: userId,
        }
      );
      setPastTests(response.data);
    } catch (error) {
      console.error("Error fetching past tests:", error);
    }
  };

  const handleSendEmail = (testId) => {
    const selectedTest = [...tests, ...mcqTests].find(
      (test) => test.test_id === testId || test.mcq_test_id === testId
    );

    if (!selectedTest || selectedTest.participant_count === 0) {
      showNotification(
        "warning",
        "Please add participants before sending emails."
      );
      return;
    }

    setSelectedTestId(testId);
    setIsEmailDialogOpen(true);
  };

  const handleEmailSendConfirmation = async () => {
    setIsEmailDialogOpen(false);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/notify/${selectedTestId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log("Emails sent successfully!");
        toast.success("Emails sent successfully!");
      } else {
        throw new Error(response.data.error || "Failed to send emails");
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      if (error.response?.status === 404) {
        toast.warning(
          "No participants found for this test. Please add participants before sending emails."
        );
      } else {
        toast.error(
          error.message || "Failed to send emails. Please try again."
        );
      }
    }
  };

  const closeEmailDialog = () => {
    setIsEmailDialogOpen(false);
  };

  const handleSetView = (view) => {
    setView(view);
    setActiveItem(view);
    if (view === "questions") {
      // Reset question type when entering the questions view
      setQuestionType(null);
    }
  };

  const handleLogout = () => {
    navigate("/login");
  };
  const organization = {
    name: "Tanmay",
  };

  const user = {
    name: "John Doe",
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const userConfirmation = window.confirm(
        "Are you sure you want to delete this question"
      );
      if (userConfirmation) {
        await axios.delete(
          `${import.meta.env.VITE_BASE_URL}/api/question/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchQuestions();
      }
    } catch (error) {
      console.error(
        "Error deleting question and it's associated testcases",
        error
      );
    }
  };

  const handleBulkCreateQuestionBtnClick = async (e) => {
    e.preventDefault();
    try {
      setView("createBulkQuestions");
      setCreateBulkQuestionView(true);

      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const questionLimit = response.data.max_question;
      console.log("questionLimit", questionLimit);
      setQuestionLimit(questionLimit);

      const testcaseLimitPerQuestion = response.data.max_testcases_per_question;
      console.log("testcaseLimitPerQuestion", testcaseLimitPerQuestion);
      setTestcaseLimitPerQuestion(testcaseLimitPerQuestion);

      const questionCountResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/count`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const questionCount = questionCountResponse.data.question_count;
      console.log("question_count", questionCount);
      setQuestionCount(questionCount);

      console.log("questionLimit", questionLimit);

      const remainingQuestionCount = questionLimit - questionCount;
      console.log("remainingQuestionCount", remainingQuestionCount);
      setRemainingQuestionCount(remainingQuestionCount);
    } catch (error) {
      console.error("Error for bulk uploading questions", error);
    }
  };

  // Update the bulk create handler
  const handleBulkCreateQuestions = async (e) => {
    e.preventDefault();
    if (!submissionData) {
      setErrorMessage("Please upload a CSV file first");
      return;
    }

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/bulk-question-and-testcase-upload`,
        submissionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // const response = await axios.post(
      //   `${import.meta.env.VITE_BASE_URL}/api/question/bulk-upload`,
      //   submissionData,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      if (response.status === 200) {
        toast.success("Questions created successfully");
        setCreateBulkQuestionView(false);
        setView("questions");
      }
    } catch (error) {
      console.error("Error creating questions:", error);
      setErrorMessage(error.response?.data?.message || "Internal server error");
    }
  };

  // Update the file upload handler
  const handleCreateBulkQuestionsCSVUpload = async (e) => {
    const file = e.target.files[0];
    setErrorMessage("");

    try {
      const { rowCount, data, headers } = await processCSVFile(file);

      // Validate against question limits
      if (rowCount > remainingQuestionCount) {
        setErrorMessage(
          `File contains ${rowCount} questions, but you can only create ${remainingQuestionCount} more questions`
        );
        return;
      }

      // Count max testcases per question
      const maxTestcases = headers.reduce((max, header) => {
        const match = header.match(/^testcase(\d+)/);
        if (match) {
          const num = parseInt(match[1]);
          return Math.max(max, num);
        }
        return max;
      }, 0);

      if (maxTestcases > testcaseLimitPerQuestion) {
        setErrorMessage(
          `Maximum ${testcaseLimitPerQuestion} testcases allowed per question, but found ${maxTestcases}`
        );
        return;
      }

      setCsvRowCount(rowCount);

      // Store the parsed data for later submission
      const formData = new FormData();
      formData.append("csvFile", file);
      formData.append("user_id", userId);
      formData.append("created_by", userId);
      formData.append("parsedData", JSON.stringify(data));

      // Save formData for submission
      setSubmissionData(formData);
      setIsButtonDisabled(false);
    } catch (error) {
      console.error("Error processing CSV:", error);
      setErrorMessage(error.message);
      setIsButtonDisabled(true);
    }
  };

  const handleGoBackToQuestionsView = () => {
    setCreateBulkQuestionView(true);
    setView("questions");
  };

  // not using
  // const fetchMcqTests = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${
  //         import.meta.env.VITE_BASE_URL
  //       }/api/org/participant/fetch-mcq-tests-data/${userId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     setMcqTests(response.data.data.mcqTests);
  //   } catch (error) {
  //     console.error("Error fetching MCQ tests:", error);
  //   }
  // };

  const handleEditProfile = () => {
    console.log("Edit profile clicked");
    setPreviousView(view);
    setView("profile");
    handleSetView("profile");
    setActiveItem("dashboard");
  };

  const handleBackFromProfile = () => {
    const fallbackView = "dashboard";
    setView(previousView || fallbackView);
    setActiveItem(previousView || fallbackView);
  };

  return (
    <>
      <UserDashboardHeader
        organizationName={orgName}
        onEditProfile={handleEditProfile}
        onLogout={handleLogout}
      />

      <div>
        <div className="user-dashboard-container">
          <UserSidebar activeItem={activeItem} handleSetView={handleSetView} />

          <main className="user-dashboard-content">
            <div className="user-content-container">
              {/* Debug output */}
              {console.log("Rendering view:", view)}

              {/* Profile view */}
              {view === "profile" && (
                // <div className="w-full">

                <OrgProfile
                  onUpdateProfile={() => {
                    handleBackFromProfile();
                  }}
                />
                // </div>
              )}

              {view === "dashboard" && (
                <UserDashboardView
                  participantCount={participantCount}
                  testCount={testCount}
                  planPurchased={planPurchased}
                  planValidity={planValidity}
                  upcomingTestCount={upcomingTestCount}
                  todayTestCount={todayTestCount}
                  redirectTo={redirectTo}
                  upgradePlan={upgradePlan}
                  theme={theme}
                />
              )}

              {/* {view === "tests" && (
                <TestTypeSelector onSelectType={handleTestTypeSelect} />
              )} */}
              {view === "tests" && <CombineTests />}

              {view === "questions" && (
                <UserQuestions
                  questionType={questionType}
                  questions={questions}
                  handleCreateQuestion={handleCreateQuestion}
                  handleBulkCreateQuestionBtnClick={
                    handleBulkCreateQuestionBtnClick
                  }
                  handleShowTestCases={handleShowTestCases}
                  handleEditQuestion={handleEditQuestion}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleBackToQuestionTypes={handleBackToQuestionTypes}
                  handleQuestionTypeSelect={handleQuestionTypeSelect}
                />
              )}
              {/* New view for MCQ questions */}
              {view === "mcqQuestions" && (
                <MCQQuestions onBack={handleBackToQuestionTypes} />
              )}
              {isTestCaseDialogOpen && (
                <div className="dialog-overlay">
                  <div className="dialog">
                    <h2>Test Cases</h2>
                    <ul>
                      {selectedQuestionTestCases.length > 0 ? (
                        selectedQuestionTestCases.map((testCase, index) => (
                          <li key={testCase.id}>
                            <strong>Test Case {index + 1}:</strong>
                            <br />
                            <strong>Input:</strong> {testCase.inputContent}
                            <br />
                            <strong>Output:</strong> {testCase.outputContent}
                          </li>
                        ))
                      ) : (
                        <li>No test cases found.</li>
                      )}
                    </ul>
                    <button onClick={() => setIsTestCaseDialogOpen(false)}>
                      Close
                    </button>
                  </div>
                </div>
              )}

              {createQuestionView && view === "createQuestion" && (
                <CreateQuestion
                  step={step}
                  questionTitle={questionTitle}
                  questionDescription={questionDescription}
                  testCases={testCases}
                  input={input}
                  output={output}
                  points={points}
                  setQuestionTitle={setQuestionTitle}
                  setQuestionDescription={setQuestionDescription}
                  setInput={setInput}
                  setOutput={setOutput}
                  setPoints={setPoints}
                  handleFormSubmit={handleFormSubmit}
                  handleTestcaseSubmit={handleTestcaseSubmit}
                  handleGoBackToQuestions={handleGoBackToQuestions}
                  handleBackToFirstStep={handleBackToFirstStep}
                />
              )}

              {createBulkQuestionView && view === "createBulkQuestions" && (
                <CreateBulkQuestions
                  questionLimit={questionLimit}
                  remainingQuestionCount={remainingQuestionCount}
                  testcaseLimitPerQuestion={testcaseLimitPerQuestion}
                  csvRowCount={csvRowCount}
                  errorMessage={errorMessage}
                  handleCreateBulkQuestionsCSVUpload={
                    handleCreateBulkQuestionsCSVUpload
                  }
                  handleBulkCreateQuestions={handleBulkCreateQuestions}
                  handleGoBackToQuestionsView={handleGoBackToQuestionsView}
                />
              )}

              {view === "participants" && (
                <UserParticipants
                  theme={theme}
                  tests={tests}
                  participants={participants}
                  setParticipants={setParticipants}
                  handleShowAllParticipants={handleShowAllParticipants}
                  handleCreateParticipant={handleCreateParticipant}
                  handleBulkCreateParticipant={handleBulkCreateParticipant}
                  handleBulkDeleteParticipant={handleBulkDeleteParticipant}
                  handleShowParticipants={handleShowParticipants}
                  handleSendEmail={handleSendEmail}
                  handleDeleteParticipant={handleDeleteParticipant}
                  showAllParticipants={showAllParticipants}
                  setShowAllParticipants={setShowAllParticipants}
                  createParticipantView={createParticipantView}
                  view={view}
                  setView={setView}
                  token={token}
                  userId={userId}
                  showNotification={showNotification}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  handleSaveParticipant={handleSaveParticipant}
                  handleGoBackToParticipants={handleGoBackToParticipants}
                  bulkCreateParticipantView={bulkCreateParticipantView}
                  participantAllowed={participantAllowed}
                  alreadySavedParticipantCount={alreadySavedParticipantCount}
                  canAddParticipants={canAddParticipants}
                  csvRowCount={csvRowCount}
                  errorMessage={errorMessage}
                  handleCSVUpload={handleCSVUpload}
                  handleBulkSaveParticipant={handleBulkSaveParticipant}
                />
              )}

              {view === "createParticipant" && (
                <CreateParticipants setView={setView} />
              )}

              {bulkCreateParticipantView &&
                view === "bulkCreateParticipant" && (
                  <BulkCreateParticipant
                    participantAllowed={participantAllowed}
                    alreadySavedParticipantCount={alreadySavedParticipantCount}
                    canAddParticipants={canAddParticipants}
                    csvRowCount={csvRowCount}
                    errorMessage={errorMessage}
                    handleCSVUpload={handleCSVUpload}
                    handleBulkSaveParticipant={handleBulkSaveParticipant}
                    handleGoBackToParticipants={handleGoBackToParticipants}
                  />
                )}
              {bulkDeleteParticipantView &&
                view === "bulkDeleteParticipant" && (
                  <BulkDeleteParticipant
                    errorMessage={errorMessage}
                    handleCSVDeleteUpload={handleCSVDeleteUpload}
                    handleBulkDeleteParticipantSubmit={
                      handleBulkDeleteParticipantSubmit
                    }
                    handleGoBackToParticipants={handleGoBackToParticipants}
                    isButtonDisabled={isButtonDisabled}
                  />
                )}

              {view === "billing" && <BillingInvoice />}

              {view === "users" && <Users />}

              {view === "settings" && <UserSetting />}

              {view === "questionGroups" && <QuestionsGroups />}
            </div>
          </main>
          {isDialogOpen && (
            <div className="dialog-overlay">
              <div className="dialog">
                <h2>Participants for Test ID: {selectedTestId}</h2>

                <ul>
                  {participantEmails.length > 0 ? (
                    participantEmails.map((email, index) => (
                      <li key={index}>{email.email}</li>
                    ))
                  ) : (
                    <li>No participants found.</li>
                  )}
                </ul>
                <button onClick={closeDialog}>Close</button>
              </div>
            </div>
          )}
          {isEmailDialogOpen && (
            <div className="dialog-overlay">
              <div className="dialog">
                <h2>
                  Are you sure you want to send an email to all participants?
                </h2>
                <button onClick={handleEmailSendConfirmation}>Yes</button>
                <button onClick={closeEmailDialog}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
