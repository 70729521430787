import React, { useState } from "react";
import { Box, AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import {
 Assignment as AssignmentIcon,
 Person as PersonIcon,
 ArrowBack as ArrowBackIcon,}
 from "@mui/icons-material";
import AnalysisMCQView from "./AnalysisMCQView";
import AnalysisCodingView from "./AnalysisCodingView";
import AnalysisQuestionNavigation from "./AnalysisQuestionNavigation";
const TestAnalysisView = ({ testId, section, participantEmail, onBack }) => {
 const [currentQuestion, setCurrentQuestion] = useState({
   type: section.questions[0]?.mcq_question_id ? 'mcq' : 'coding',
   index: 0,
 });
 const [isNavOpen, setIsNavOpen] = useState(true);
  const getContentStyle = (isNavOpen) => ({
   width: isNavOpen ? "calc(100% - 300px)" : "100%",
   transition: "width 0.3s ease",
   marginRight: isNavOpen ? "300px" : "0",
 });
  const handleQuestionSelect = (newQuestion) => {
   setCurrentQuestion(newQuestion);
 };
  const handleNavigate = (direction) => {
   const newIndex = direction === "next" 
     ? currentQuestion.index + 1 
     : currentQuestion.index - 1;
    if (newIndex >= 0 && newIndex < section.questions.length) {
     setCurrentQuestion({
       type: section.questions[newIndex].mcq_question_id ? 'mcq' : 'coding',
       index: newIndex
     });
   }
 };
  const currentQuestionData = section.questions[currentQuestion.index];
  if (!currentQuestionData) {
   return <div>No questions available</div>;
 }
  return (
   <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
     <AppBar position="static" color="primary">
       <Toolbar sx={{ justifyContent: "space-between" }}>
         <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
           <IconButton color="inherit" onClick={onBack}>
             <ArrowBackIcon />
           </IconButton>
           <AssignmentIcon />
           <Typography variant="h6">{section.section_name}</Typography>
         </Box>
         <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
           <Box sx={{ display: "flex", alignItems: "center" }}>
             <PersonIcon sx={{ mr: 1 }} />
             <Typography>{participantEmail}</Typography>
           </Box>
         </Box>
       </Toolbar>
     </AppBar>
      <Box sx={{ display: 'flex', flex: 1, position: 'relative', overflow: 'hidden' }}>
       <Box sx={getContentStyle(isNavOpen)}>
         {currentQuestionData.mcq_question_id ? (
           <AnalysisMCQView
             question={currentQuestionData}
             currentQuestionNumber={currentQuestion.index + 1}
             totalQuestions={section.questions.length}
             onNavigate={handleNavigate}
           />
         ) : (
           <AnalysisCodingView
             question={currentQuestionData}
             currentQuestionNumber={currentQuestion.index + 1}
             totalQuestions={section.questions.length}
             onNavigate={handleNavigate}
           />
         )}
       </Box>
        <AnalysisQuestionNavigation
         questions={section.questions}
         currentQuestion={currentQuestion}
         onQuestionSelect={handleQuestionSelect}
         isOpen={isNavOpen}
         onToggle={() => setIsNavOpen(!isNavOpen)}
         totalQuestions={section.questions.length}
       />
     </Box>
   </Box>
 );
};
export default TestAnalysisView;