import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import DOMPurify from "dompurify";
import { CheckCircle, Cancel } from "@mui/icons-material";

const AnalysisMCQView = ({
  question,
  currentQuestionNumber,
  totalQuestions,
  onNavigate,
 }) => {
  const questionData = question.questionData;
  const executionData = question.executionData;
  
  // Get selected options from execution data
  const selectedOptionIds = executionData?.selected_options?.map(
    opt => opt.selected_option_id
  ) || [];
   // Check if answer is correct (all correct options selected and no incorrect ones)
  const correctOptionIds = questionData.mcq_options
    .filter(opt => opt.is_correct === 1)
    .map(opt => opt.option_id);
   const isCorrect = executionData?.points_earned === questionData.points;
   return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="w-[70%] bg-white p-6 rounded-lg shadow mx-auto">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              Question {currentQuestionNumber} of {totalQuestions}
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-gray-500">
                Score: {executionData?.points_earned || 0}/{questionData.points}
              </span>
            </div>
          </div>
           {/* Question Title and Content */}
          <Typography variant="h6" className="mb-4">
            {questionData.question_text}
          </Typography>
          <div
            className="prose max-w-none mb-6"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(questionData.question_description),
            }}
          />
           {/* Options */}
          <div className="space-y-4 mb-6">
            {questionData.mcq_options.map((option) => {
              const isSelected = selectedOptionIds.includes(option.option_id);
              const isCorrectOption = option.is_correct === 1;
               return (
                <div
                  key={option.option_id}
                  className={`p-4 rounded-lg border ${
                    isSelected
                      ? isCorrectOption
                        ? "border-green-500 bg-green-50"
                        : "border-red-500 bg-red-50"
                      : isCorrectOption
                      ? "border-green-500 bg-green-50"
                      : "border-gray-200"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div
                      className="flex-1"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(option.option_text),
                      }}
                    />
                    {(isSelected || isCorrectOption) && (
                      <div className="ml-4">
                        {isCorrectOption ? (
                          <CheckCircle color="success" />
                        ) : (
                          <Cancel color="error" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
           {/* Navigation and Analysis sections remain the same */}
          <div className="flex justify-between mt-6">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onNavigate("previous")}
              disabled={currentQuestionNumber === 1}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onNavigate("next")}
              disabled={currentQuestionNumber === totalQuestions}
            >
              Next
            </Button>
          </div>
           {/* <Paper elevation={1} className="mt-8 p-4 bg-gray-50">
            <Typography variant="h6" className="mb-2">
              Answer Analysis
            </Typography>
            <Typography variant="body1">
              Points Scored: {executionData?.points_earned || 0} out of {questionData.points}
            </Typography>
            <Typography variant="body2" className="mt-2">
              {isCorrect
                ? "Correct! All correct options were selected."
                : selectedOptionIds.length > 0
                ? "Incorrect. The correct answers are highlighted in green."
                : "Question was not attempted."}
            </Typography>
          </Paper> */}
        </div>
      </div>
    </div>
  );
};

export default AnalysisMCQView;
