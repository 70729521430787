import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ArrowRight } from 'lucide-react';
import '../css/Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    
    // Function to check token validity
    const isTokenValid = (token) => {
      if (!token) return false;
      
      try {
        // Decode the token
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const payload = JSON.parse(window.atob(base64));
        
        // Check expiration
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp > currentTime;
      } catch (error) {
        // If decoding fails, token is invalid
        return false;
      }
    };
  
    if (isTokenValid(token) && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    } else {
      // Remove invalid token and username
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      setIsLoggedIn(false);
      setUsername('');
    }
  }, []);

  // Close menu when changing routes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDashboardRedirect = () => {
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
  
    if (token) {
      if (role === 'ADMIN') {
        // Admin dashboard
        navigate('/admin/dashboard');
      } else if (role === 'ORG') {
        // Regular user dashboard
        navigate('/user/dashboard');
      } else {
        // For candidates or other roles
        navigate('/profilepage');
      }
    } else {
      // If no token, redirect to login
      navigate('/login');
    }
    
    closeMenu();
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <Link to="/" className="navbar-logo">
          <img src="/logos/cirruscypher-logo-alpha.png" alt="CirrusCypher Favicon Logo" className="favicon-logo" />
          <img src="/logos/cirruscypher-name-multi-alpha.png" alt="CirrusCypher Logo" className="logo-img" />
        </Link>

        <button className="menu-icon" onClick={toggleMenu} aria-label="Toggle menu">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <div className={`nav-overlay ${isMenuOpen ? 'show' : ''}`} onClick={closeMenu} />

        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" className={isActive('/')} onClick={closeMenu}>Home</Link></li>
          <li><Link to="/feature" className={isActive('/feature')} onClick={closeMenu}>Features</Link></li>
          <li><Link to="/subscription" className={isActive('/subscription')} onClick={closeMenu}>Pricing</Link></li>
          <li><Link to="/contact-info" className={isActive('/contact-info')} onClick={closeMenu}>Contact Us</Link></li>

          {isLoggedIn ? (
            <div className="welcome-strip">
            <span>
            <button onClick={handleDashboardRedirect} className="cta-button">
        Go to Dashboard
      </button>
            </span>
          </div>
        ) : (
          <>
          <li><Link to="/login" className={isActive('/login')} onClick={closeMenu}>Login</Link></li>
          <li className="demo-button-container">
            <Link to="/demo-request" className="request-demo-button" onClick={closeMenu}>
              Request a demo
              <ArrowRight size={16} className="arrow-icon" />
              
            </Link>
          </li>
        </>
        )}

        </ul>
      </nav>
    </div>
  );
};

export const NavBar = Navbar;
export default Navbar;
