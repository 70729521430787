import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Card,
  CardContent,
  Alert,
  CircularProgress,
  InputAdornment,
  Stack,
  Tooltip,
  Snackbar,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  Search as SearchIcon,
  PersonAdd as PersonAddIcon,
  DeleteSweep as DeleteSweepIcon,
  Download as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

// 1. Add these imports at the top
import TestAddBulkParticipant from "./Tests/TestAddBulkParticipant";
import TestDeleteBulkParticipant from "./Tests/TestDeleteBulkParticipant";

const ManageParticipants = ({ testId, onClose }) => {
  // State management
  const [email, setEmail] = useState("");
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openBulkAddDialog, setOpenBulkAddDialog] = useState(false);
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [view, setView] = useState("main"); // 'main', 'bulkAdd', or 'bulkDelete'

  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  // Fetch participants on component mount
  useEffect(() => {
    fetchParticipants();
  }, []);

  // Filter participants when search term changes
  useEffect(() => {
    const filtered = participants.filter((participant) =>
      participant.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredParticipants(filtered);
    setPage(0);
  }, [searchTerm, participants]);

  const fetchParticipants = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/test/${testId}/participants`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setParticipants(response.data);
        setFilteredParticipants(response.data);
      } else {
        // Handle empty participants list
        setParticipants([]);
        setFilteredParticipants([]);
      }
    } catch (error) {
      // Don't show error for 404 (no participants)
      if (error.response?.status === 404) {
        setParticipants([]);
        setFilteredParticipants([]);
      } else {
        handleError(error, "Error fetching participants");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error, defaultMessage) => {
    // Don't show error message for 404 when fetching participants
    if (error.response?.status === 404 && defaultMessage.includes("fetching")) {
      return;
    }

    const errorMessage =
      error.response?.data?.error || error.message || defaultMessage;
    setSnackbar({
      open: true,
      message: errorMessage,
      severity: "error",
    });
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddParticipant = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setSnackbar({
        open: true,
        message: "Invalid email format",
        severity: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const participantId = uuidv4();
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants`,
        {
          participant_id: participantId,
          user_id: userId,
          email,
          test_id: testId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.error === null) {
        throw new Error("The entered email is not added by organization");
      }

      // Add the new participant to the local state
      const newParticipant = {
        participant_id: participantId,
        email,
        test_id: testId,
        user_id: userId,
      };

      setParticipants((prev) => [...prev, newParticipant]);
      setFilteredParticipants((prev) => [...prev, newParticipant]);
      setEmail("");

      setSnackbar({
        open: true,
        message: "Participant added successfully",
        severity: "success",
      });
    } catch (error) {
      handleError(error, "Error adding participant");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteParticipant = async (participantId) => {
    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/api/participants/${participantId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        // Update local state immediately after successful deletion
        setParticipants((prev) =>
          prev.filter((p) => p.participant_id !== participantId)
        );
        setFilteredParticipants((prev) =>
          prev.filter((p) => p.participant_id !== participantId)
        );

        setSnackbar({
          open: true,
          message: "Participant deleted successfully",
          severity: "success",
        });
      }
    } catch (error) {
      handleError(error, "Error deleting participant");
    }
  };

  const handleFileUpload = async (event, isBulkDelete = false) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/bulk/validate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { invalidEmails, duplicateEmails } = response.data;
      if (invalidEmails.length > 0 || duplicateEmails.length > 0) {
        throw new Error(
          `File contains ${
            invalidEmails.length ? "invalid" : "duplicate"
          } emails: ${invalidEmails.length ? invalidEmails : duplicateEmails}`
        );
      }

      setSelectedFile(file);
    } catch (error) {
      handleError(error, "Error validating CSV file");
      setSelectedFile(null);
    }
  };

  const handleBulkOperation = async (isBulkDelete = false) => {
    if (!selectedFile) return;

    try {
      if (isBulkDelete) {
        const response = await axios.delete(
          `${
            import.meta.env.VITE_BASE_URL
          }/api/participants/bulk/${userId}/${testId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/api/participants/bulk`,
          {
            user_id: userId,
            test_id: testId,
          }
        );
      }

      fetchParticipants();
      setSelectedFile(null);
      setOpenBulkAddDialog(false);
      setOpenBulkDeleteDialog(false);
      setSnackbar({
        open: true,
        message: `Bulk ${isBulkDelete ? "deletion" : "addition"} successful`,
        severity: "success",
      });
    } catch (error) {
      handleError(
        error,
        `Error during bulk ${isBulkDelete ? "deletion" : "addition"}`
      );
    }
  };

  const handleDownloadTemplate = (fileName) => {
    const fileUrl = `${window.location.origin}/${fileName}`;

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a blob URL
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading template:", error));
  };

  return (
    <>
      {view === "bulkAdd" ? (
        <TestAddBulkParticipant
          testId={testId}
          token={token}
          onBack={() => {
            setView("main");
            fetchParticipants(); // Refresh the list after bulk operation
          }}
        />
      ) : view === "bulkDelete" ? (
        <TestDeleteBulkParticipant
          testId={testId}
          token={token}
          onBack={() => {
            setView("main");
            fetchParticipants(); // Refresh the list after bulk operation
          }}
        />
      ) : (
        <Box sx={{ maxWidth: 1200, margin: "auto", p: 3 }}>
          <Box sx={{ py: 4 }}>
            {/* Back Button */}
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={onClose}
              sx={{
                color: "primary.main",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Back to Tests
            </Button>
          </Box>

          <Paper elevation={3} sx={{ p: 3 }}>
            {/* Add Participant Form */}
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <form onSubmit={handleAddParticipant}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      fullWidth
                      label="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      type="email"
                      required
                      disabled={loading}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      startIcon={
                        loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <PersonAddIcon />
                        )
                      }
                    >
                      Add
                    </Button>
                  </Stack>
                </form>
              </CardContent>
            </Card>

            {/* Bulk Operations */}
            <Stack direction="row" spacing={2} mb={3}>
              <Button
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                onClick={() => setView("bulkAdd")}
              >
                Bulk Add
              </Button>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteSweepIcon />}
                onClick={() => setView("bulkDelete")}
              >
                Bulk Delete
              </Button>
            </Stack>

            {/* Search and Table */}
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search participants..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mb: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : filteredParticipants.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No participants found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredParticipants
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((participant) => (
                        <TableRow key={participant.participant_id}>
                          <TableCell>{participant.email}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Delete participant">
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDeleteParticipant(
                                    participant.participant_id
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={filteredParticipants.length}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
              />
            </TableContainer>

            {/* Bulk Add Dialog */}
            <Dialog
              open={openBulkAddDialog}
              onClose={() => setOpenBulkAddDialog(false)}
            >
              <DialogTitle>Bulk Add Participants</DialogTitle>
              <DialogContent>
                <Box sx={{ p: 2 }}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mb: 2 }}
                  >
                    Upload CSV
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={(e) => handleFileUpload(e, false)}
                    />
                  </Button>
                  {selectedFile && (
                    <Chip
                      label={selectedFile.name}
                      onDelete={() => setSelectedFile(null)}
                      sx={{ ml: 1 }}
                    />
                  )}
                  <Button
                    onClick={() => handleDownloadTemplate("TestSampleBulk.csv")}
                    startIcon={<DownloadIcon />}
                    sx={{ ml: 2 }}
                  >
                    Download Template
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenBulkAddDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleBulkOperation(false)}
                  disabled={!selectedFile}
                  variant="contained"
                >
                  Add All
                </Button>
              </DialogActions>
            </Dialog>

            {/* Bulk Delete Dialog */}
            <Dialog
              open={openBulkDeleteDialog}
              onClose={() => setOpenBulkDeleteDialog(false)}
            >
              <DialogTitle>Bulk Delete Participants</DialogTitle>
              <DialogContent>
                <Box sx={{ p: 2 }}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mb: 2 }}
                  >
                    Upload CSV
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={(e) => handleFileUpload(e, true)}
                    />
                  </Button>
                  {selectedFile && (
                    <Chip
                      label={selectedFile.name}
                      onDelete={() => setSelectedFile(null)}
                      sx={{ ml: 1 }}
                    />
                  )}
                  <Button
                    onClick={() => handleDownloadTemplate("TestSampleBulk.csv")}
                    startIcon={<DownloadIcon />}
                    sx={{ ml: 2 }}
                  >
                    Download Template
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenBulkDeleteDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleBulkOperation(true)}
                  disabled={!selectedFile}
                  variant="contained"
                  color="error"
                >
                  Delete All
                </Button>
              </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
              <Alert
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
                sx={{ width: "100%" }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Paper>
        </Box>
      )}
    </>
  );
};
export default ManageParticipants;
