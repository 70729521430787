import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  styled,
  Button,
  useTheme
} from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { 
  FaUsers, 
  FaClipboardList, 
  FaShoppingCart, 
  FaCalendarAlt, 
  FaRegCalendarCheck, 
  FaRegClipboard 
} from 'react-icons/fa';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  backgroundColor: '#ffffff',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
    '& .card-icon': {
      animation: 'pulse 1.5s infinite',
    },
  },
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.05)' },
    '100%': { transform: 'scale(1)' },
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '40px',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 3),
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const UserDashboardView = ({ 
  participantCount, 
  testCount, 
  planPurchased, 
  planValidity, 
  upcomingTestCount, 
  todayTestCount, 
  redirectTo, 
  upgradePlan 
}) => {
  const userRole = localStorage.getItem("role");
  const theme = useTheme();
  
  const dashboardItems = [
    {
      icon: FaUsers,
      title: 'Total No of Participants',
      value: participantCount,
      route: 'participant'
    },
    {
      icon: FaClipboardList,
      title: 'Total No of Tests',
      value: testCount,
      route: 'tests'
    },
    {
      icon: FaShoppingCart,
      title: 'Plan Purchased',
      value: planPurchased,
      route: 'plan'
    },
    {
      icon: FaCalendarAlt,
      title: 'Plan Validity',
      value: planValidity,
      route: 'planValidity'
    },
    {
      icon: FaRegCalendarCheck,
      title: 'Upcoming Tests',
      value: upcomingTestCount,
      route: 'tests'
    },
    {
      icon: FaRegClipboard,
      title: 'Current Test',
      value: todayTestCount,
      route: 'tests'
    }
  ];

  return (
    <Box sx={{ p: 4 }}>
      {/* Header Section */}
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 4,
          gap: 2,
        }}
      >
        <DashboardCustomizeIcon
          sx={{
            fontSize: 38,
            color: theme.palette.primary.main,
          }}
        />
        <Typography 
          variant="h4" 
          component="h1"
          sx={{
            fontWeight: 600,
            color: 'text.primary',
          }}
        >
          Organization Statistics
        </Typography>
      </Box> */}

      {/* Cards Grid */}
      <Grid container spacing={3}>
        {dashboardItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper 
              elevation={3}
              onClick={() => redirectTo(item.route)}
            >
              <IconWrapper className="card-icon">
                <item.icon />
              </IconWrapper>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  textAlign: 'center',
                  color: 'text.primary',
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  fontWeight: 500,
                }}
              >
                {item.value}
              </Typography>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      {/* Upgrade Button */}
       {/* Upgrade Button - Only show for non-USER roles */}
       {userRole !== 'USER' && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            onClick={upgradePlan}
            size="large"
          >
            Upgrade Plan
          </StyledButton>
        </Box>
      )}
    </Box>
  );
};

export default UserDashboardView;