import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  InputAdornment,
  Container,
  Card,
  CardContent,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  GroupWork as GroupWorkIcon,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateQuestionsGroup from "./CreateQuestionsGroup";
import QuestionGroupViewModal from "./QuestionGroupViewModal";

const QuestionsGroups = () => {
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [groups, setGroups] = useState([]); // This will store your groups data
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [editingGroup, setEditingGroup] = useState(null);
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    groupId: null,
    groupName: "",
  });
  const [viewGroup, setViewGroup] = useState(null);

  // UseEffect to fetch groups on component mount
  useEffect(() => {
    if (isInitialMount) {
      fetchGroups();
      setIsInitialMount(false);
    }
  }, [isInitialMount]);

  // Function to fetch groups
  const fetchGroups = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/org/group/get-all-groups/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setGroups(response.data.groups);
      } else {
        setGroups([]); // Set empty array for no groups
      }
    } catch (error) {
      setGroups([]); // Set empty array on error
      if (error.response) {
        // Handle specific response errors
        if (error.response.status === 404) {
          //   toast.info(error.response.data.message || "No question groups found");
        } else {
          toast.error(
            error.response.data.message || "Failed to fetch question groups"
          );
        }
      } else {
        // Handle network errors or other issues
        toast.error("Failed to connect to server");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Edit handler
  const handleEditGroup = async (groupId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/group/get-group/${groupId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEditingGroup({
        ...response.data,
        groupData: {
          ...response.data.groupData,
          group_id: groupId,
        },
      });
      setShowCreateGroup(true);
    } catch (error) {
      console.error("Error fetching group details:", error);
      toast.error("Failed to fetch group details");
    }
  };

  // View Group
  const handleViewGroup = async (groupId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/group/get-group/${groupId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setViewGroup(response.data);
    } catch (error) {
      console.error("Error fetching group details:", error);
      toast.error("Failed to fetch group details");
    }
  };

  // Delete handler
  const handleDeleteClick = (groupId, groupName) => {
    setDeleteDialog({
      open: true,
      groupId,
      groupName,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `${BASE_URL}/api/org/group/delete-group/${deleteDialog.groupId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Group deleted successfully");
      fetchGroups();
    } catch (error) {
      console.error("Error deleting group:", error);
      toast.error("Failed to delete group");
    } finally {
      setDeleteDialog({ open: false, groupId: null, groupName: "" });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({ open: false, groupId: null, groupName: "" });
  };

  const handleCreateGroup = () => {
    setShowCreateGroup(true);
  };

  const handleBack = () => {
    setShowCreateGroup(false);
    setEditingGroup(null); // Reset editing state
    fetchGroups(); // Refresh the groups list
  };

  if (showCreateGroup) {
    return (
      <CreateQuestionsGroup onBack={handleBack} editingGroup={editingGroup} />
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {/* Header Section */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            gap: 2,
          }}
        >
          <GroupWorkIcon
            sx={{
              fontSize: 38,
              color: theme.palette.primary.main,
            }}
          />
          <Typography variant="h4" component="h1">
            Questions Groups
          </Typography>
        </Box> */}

        {/* Search and Create Button Section */}
        <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
  <Button
    variant="contained"
    color="primary"
    startIcon={<AddIcon />}
    onClick={handleCreateGroup}
  >
    Create Questions Group
  </Button>
</Stack>


        {/* Groups Table/Empty State Section */}
        {groups.length > 0 ? (
          <TableContainer
            component={Paper}
            elevation={2}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Group ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Group Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Group Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Number of Questions
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((group) => (
                  <TableRow
                    key={group.group_id}
                    sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                  >
                    <TableCell>{group.group_id}</TableCell>
                    <TableCell>{group.group_name}</TableCell>
                 
<TableCell 
  sx={{ 
    maxWidth: "300px", 
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    '& > div': {
      maxHeight: "60px",
      overflowY: "auto",
      overflowX: "hidden",
      whiteSpace: "normal",
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }
  }}
>
  <div>{group.group_description}</div>
</TableCell>
                    <TableCell>{group.questions_count} Questions</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Tooltip title="View Group">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleViewGroup(group.group_id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Group">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleEditGroup(group.group_id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Group">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() =>
                              handleDeleteClick(
                                group.group_id,
                                group.group_name
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Card
            elevation={2}
            sx={{
              textAlign: "center",
              py: 8,
              px: 4,
              backgroundColor: "background.paper",
              borderRadius: 2,
            }}
          >
            <CardContent>
              <GroupWorkIcon
                sx={{
                  fontSize: 60,
                  color: "text.secondary",
                  mb: 2,
                }}
              />
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No Question Groups Yet
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={3}>
                Create your first question group to organize your questions more
                effectively
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateGroup}
                sx={{
                  px: 4,
                  py: 1.5,
                  boxShadow: 2,
                  "&:hover": {
                    boxShadow: 4,
                  },
                }}
              >
                Create Questions Group
              </Button>
            </CardContent>
          </Card>
        )}
      </Box>
      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "500px",
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "error.main",
            pb: 1,
          }}
        >
          <WarningIcon color="error" />
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Are you sure you want to delete the group{" "}
            <strong>"{deleteDialog.groupName}"</strong>?
          </DialogContentText>
          <DialogContentText
            sx={{
              backgroundColor: "warning.light",
              p: 2,
              borderRadius: 1,
              color: "warning.dark",
              fontSize: "0.9rem",
            }}
          >
            This action cannot be undone. All questions in this group will be
            unlinked from this group.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            sx={{
              minWidth: "100px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            sx={{
              minWidth: "100px",
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <QuestionGroupViewModal
        open={!!viewGroup}
        onClose={() => setViewGroup(null)}
        group={viewGroup}
      />
    </Container>
  );
};

export default QuestionsGroups;
