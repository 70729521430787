import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  styled,
  Link,
} from "@mui/material";
import Navbar from "../Navbar";
import SubFooter from "../Home/SubFooter";
import UniqueFooter from "../FooterHome";
import FaqAccordion from "./FaqAccordion";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./FaqStyle.css";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(10),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f5f8ff 100%)",
  border: "1px solid rgba(75, 124, 203, 0.1)",
  boxShadow: "0 4px 20px rgba(75, 124, 203, 0.1)",
  borderRadius: theme.spacing(2),
}));

const GradientSection = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #ffffff 0%, #4b7ccb 100%)",
  color: "#4b7ccb",
  padding: theme.spacing(6, 0),
  textAlign: "center",
  maxWidth: "800px",
  margin: "0 auto",
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: `
      0 4px 20px rgba(75, 124, 203, 0.15),
      0 8px 32px rgba(75, 124, 203, 0.12),
      inset 0 -2px 8px rgba(255, 255, 255, 0.4),
      inset 0 2px 8px rgba(75, 124, 203, 0.1)
    `,
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: `
        0 6px 24px rgba(75, 124, 203, 0.18),
        0 12px 38px rgba(75, 124, 203, 0.15),
        inset 0 -2px 8px rgba(255, 255, 255, 0.5),
        inset 0 2px 8px rgba(75, 124, 203, 0.15)
      `,
    transform: "translateY(-2px)",
  },
}));

const FaqPage = () => {
  const navigate = useNavigate();
  const [expandedFaq, setExpandedFaq] = useState(0);

  const faqs = [
    {
      question: "What is CirrusCypher?",
      answer: (
        <Typography variant="body1" sx={{ color: "#555" }}>
          CirrusCypher is a comprehensive online platform designed to streamline
          the process of conducting and administering code exams. It caters to
          three distinct user groups: organizations, participants, and
          administrators, providing a secure and efficient environment for code
          assessment.
        </Typography>
      ),
    },
    {
      question: "Who can use CirrusCypher?",
      answer: (
        <Typography variant="body1" sx={{ color: "#555" }}>
          CirrusCypher is ideal for organizations seeking to conduct effective
          code exams for various purposes, such as recruiting, skill assessment,
          and training evaluation. It's also beneficial for participants who can
          take secure and reliable coding exams in a familiar online
          environment.
        </Typography>
      ),
    },
    {
      question: "How do I get started with CirrusCypher?",
      answer: (
        <Box sx={{ color: "#555" }}>
          <Typography variant="body1" gutterBottom>
            Getting started with CirrusCypher is simple:
          </Typography>
          <Box sx={{ pl: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, mb: 1, color: "#4b7ccb" }}
            >
              For Organizations:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Visit the CirrusCypher website and navigate to the signup page.
              Create an account by providing your organization's details. After
              verifying your email address, you'll be redirected to your user
              dashboard, where you can manage tests, participants, and
              subscriptions.
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, mb: 1, color: "#4b7ccb" }}
            >
              For Participants:
            </Typography>
            <Typography variant="body1">
              You'll receive an email invitation from an organization containing
              a test link and password. Click the link, enter your email ID and
              the received password, and proceed to review the test instructions
              before starting the exam.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      question: "What are the benefits of using CirrusCypher?",
      answer: (
        <Box sx={{ color: "#555" }}>
          <Typography variant="body1" gutterBottom>
            CirrusCypher offers a multitude of benefits for both organizations
            and participants:
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, mb: 1, color: "#4b7ccb" }}
            >
              For Organizations:
            </Typography>
            <ul style={{ marginBottom: "16px" }}>
              <li>Streamline code assessment processes</li>
              <li>Save time and resources by managing exams efficiently</li>
              <li>
                Gain valuable insights into candidate skills through detailed
                reports and analytics
              </li>
              <li>
                Choose from a wide range of features, including question
                management, participant control, and test customization
              </li>
            </ul>

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, mb: 1, color: "#4b7ccb" }}
            >
              For Participants:
            </Typography>
            <ul>
              <li>
                Take secure and reliable coding exams in a familiar online
                environment
              </li>
              <li>
                Benefit from a user-friendly interface for code editing,
                language selection, and test execution
              </li>
              <li>
                Receive clear and concise exam instructions for a smooth testing
                experience
              </li>
            </ul>
          </Box>
        </Box>
      ),
    },
    {
      question: "What are the pricing options for CirrusCypher?",
      answer: (
        <Typography variant="body1" sx={{ color: "#555" }}>
          CirrusCypher offers a range of affordable subscription plans to cater
          to the needs of organizations of all sizes. You can find detailed
          pricing information on our website or by contacting our sales team.
          We're committed to providing a cost-effective solution that aligns
          with your organization's budget.
        </Typography>
      ),
    },
    {
      question: "Does CirrusCypher offer any security guarantees?",
      answer: (
        <Box sx={{ color: "#555" }}>
          <Typography variant="body1" gutterBottom>
            Security is a top priority at CirrusCypher. We employ a robust
            security infrastructure to safeguard your data and protect the
            integrity of your exams.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, mb: 1, color: "#4b7ccb" }}
            >
              Key Security Measures:
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>• Secure Data Storage:</strong> We utilize secure data
                centers to store all user information and test data.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>• HTTPS Encryption:</strong> All communication between
                your browser and our platform is encrypted using HTTPS to
                prevent unauthorized access to sensitive information.
              </Typography>
              <Typography variant="body1">
                <strong>• Authentication and Authorization:</strong> Strict
                authentication and authorization protocols are in place to
                ensure only authorized users can access and manage exams.
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      question:
        "What resources are available to help me learn more about CirrusCypher?",
      answer: (
        <Box sx={{ color: "#555" }}>
          <Typography variant="body1" gutterBottom>
            We provide a variety of resources to help you get the most out of
            CirrusCypher:
          </Typography>
          <Box sx={{ mt: 2, pl: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>• Comprehensive Documentation:</strong> Our website
              features detailed documentation covering all aspects of the
              platform, including user guides, tutorials, and FAQs.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>• Knowledge Base:</strong> Our knowledge base offers a
              wealth of troubleshooting tips, best practices, and frequently
              asked questions.
            </Typography>
            <Typography variant="body1">
              <strong>• Customer Support:</strong> Our friendly customer support
              team is always happy to answer your questions and assist you with
              any challenges you encounter. You can reach us via email or live
              chat.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      question: "Where can I find more information about CirrusCypher?",
      answer: (
        <Box sx={{ color: "#555" }}>
          <Typography variant="body1">
            For further information and inquiries, please visit our website at{" "}
            <Link href="https://CirrusCypher.com" sx={{ color: "#4b7ccb" }}>
              https://CirrusCypher.com
            </Link>
            . You can also contact our sales team through the "Contact us" page
            in the navbar or reach out through our "Submit enquiry form" on the
            website.
          </Typography>
        </Box>
      ),
    },
  ];

  const handleFaqChange = (panel) => (event, isExpanded) => {
    setExpandedFaq(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Navbar />

      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          py: 8,
          px: {
            xs: 2, // 16px padding on extra-small screens
            sm: 4, // 32px padding on small screens
            md: 8, // 64px padding on medium screens
            lg: 12, // 96px padding on large screens
          },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontWeight: "bold",
                background: "linear-gradient(135deg, #4b7ccb 0%, #2b4c8b 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Frequently Asked Questions
            </Typography>

            <Box sx={{ mt: 4 }}>
              {faqs.map((faq, index) => (
                <FaqAccordion
                  key={index}
                  faq={faq}
                  expanded={expandedFaq === index}
                  onChange={handleFaqChange(index)}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledCard>
              <Typography variant="h4" gutterBottom sx={{ color: "#4b7ccb" }}>
                Want to know more?
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, textAlign: "center" }}>
                Schedule time with one of our experts.
              </Typography>
              <Button
                variant="contained"
                size="large"
                endIcon={<CalendarTodayIcon />}
                onClick={() => navigate("/demo-request")}
                sx={{
                  background:
                    "linear-gradient(135deg, #4b7ccb 0%, #2b4c8b 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(135deg, #2b4c8b 0%, #4b7ccb 100%)",
                  },
                }}
              >
                Request a Demo
              </Button>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="xl"
        sx={{
          py: 4,
          px: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
        }}
      >
        <GradientSection>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              color: "#4b7ccb",
              fontWeight: "bold",
            }}
          >
            Find out more
          </Typography>
          <Button
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate("/contact-info")}
            sx={{
              mt: 3,
              backgroundColor: "#4b7ccb",
              color: "white",
              "&:hover": {
                backgroundColor: "#2b4c8b",
              },
              px: 4,
              py: 1.5,
            }}
          >
            Speak to an expert
          </Button>
        </GradientSection>
      </Container>

      <SubFooter />
    </Box>
  );
};

export default FaqPage;
