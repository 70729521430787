import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Alert,
  Stack,
  Paper,
  IconButton,
  Card,
  CardContent,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { toast } from "react-toastify";

// Styled components
const StyledDropZone = styled(Paper)(({ theme, isDragActive }) => ({
  border: `2px dashed ${
    isDragActive ? theme.palette.primary.main : theme.palette.divider
  }`,
  backgroundColor: isDragActive
    ? theme.palette.action.hover
    : theme.palette.background.paper,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
  borderRadius: theme.spacing(2),
}));

const CSVFormatInfo = () => (
  <Box sx={{ mt: 2 }}>
    <Typography
      variant="subtitle1"
      gutterBottom
      sx={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: 1 }}
    >
      <InfoIcon color="primary" />
      Required CSV Format:
    </Typography>
    <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
      <Typography variant="body2" component="pre" sx={{ overflowX: "auto" }}>
        question,option1,option2,option3,option4,correct_answer,marks,negative_marks,time_in_seconds
        What is React?,Library,Framework,Language,None,Library,1,0,60
      </Typography>
    </Paper>
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      <Chip
        icon={<CheckCircleIcon />}
        label="UTF-8 Encoding"
        color="primary"
        variant="outlined"
      />
      <Chip
        icon={<CheckCircleIcon />}
        label="Header Required"
        color="primary"
        variant="outlined"
      />
      <Chip
        icon={<CheckCircleIcon />}
        label="No Empty Rows"
        color="primary"
        variant="outlined"
      />
    </Stack>
  </Box>
);

const MCQBulkQuestion = ({ onBack }) => {
  const [file, setFile] = useState(null);
  const [planLimit, setPlanLimit] = useState(0);
  const [createdQuestions, setCreatedQuestions] = useState(0);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const [questionsInFile, setQuestionsInFile] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchPlanLimits();
  }, []);

  const fetchPlanLimits = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlanLimit(response.data.max_mcq_questions);

      const countResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/count`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCreatedQuestions(countResponse.data.mcq_question_count);
      setRemainingQuestions(
        response.data.max_mcq_questions - countResponse.data.mcq_question_count
      );
    } catch (error) {
      console.error("Error fetching plan limits:", error);
      toast.error("Failed to fetch plan limits");
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.name.endsWith(".csv")) {
      setFile(droppedFile);
      handleFileUpload(droppedFile);
      simulateProgress();
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleFileUpload(selectedFile);
      simulateProgress();
    }
  };

  const simulateProgress = () => {
    setUploadProgress(0);
    const timer = setInterval(() => {
      setUploadProgress((oldProgress) => {
        const diff = Math.random() * 10;
        const newProgress = Math.min(oldProgress + diff, 100);
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 100);
  };

  const handleFileUpload = async (fileToUpload) => {
    if (!fileToUpload) {
      toast.error("Please select a file to upload");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("csvFile", fileToUpload);
    formData.append("userId", userId);
    formData.append("maxMcqQuestions", planLimit);
    formData.append("mcqQuestionCount", createdQuestions);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/org/question/validate-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setQuestionsInFile(response.data.rowCount);
      toast.success("File validated successfully");
    } catch (error) {
      console.error("Error validating file:", error);
      setErrorMessage(error.response?.data?.message || "Error validating file");
      toast.error("File validation failed");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveAll = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      await axios.post(
        `${BASE_URL}/api/org/question/bulk-create-mcq-question`,
        { userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      toast.success("Questions created successfully");
      onBack();
    } catch (error) {
      console.error("Error creating questions:", error);
      setErrorMessage(
        error.response?.data?.message || "Error creating questions"
      );
      toast.error("Failed to create questions");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSampleDownload = () => {
    const link = document.createElement("a");
    link.href = "/SampleCreateBulkMcqQuestions 1.csv";
    link.download = "SampleCreateBulkMcqQuestions 1.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <IconButton onClick={onBack} color="primary" sx={{ p: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" fontWeight="bold">
            Bulk Create MCQ Questions
          </Typography>
        </Stack>

        <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
          {/* Left Section - Statistics */}
          <Box sx={{ flex: "1 1 300px", minWidth: 300 }}>
            <StyledCard>
              <CardContent>
                <Typography
                  variant="h6"
                  color="primary"
                  gutterBottom
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <InfoIcon /> Plan Statistics
                </Typography>
                <Stack spacing={3}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: "background.default",
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Total Plan Limit
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {planLimit}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(createdQuestions / planLimit) * 100}
                      sx={{ mt: 1 }}
                    />
                  </Paper>

                  <Stack direction="row" spacing={2}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        flex: 1,
                        bgcolor: "background.default",
                        border: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Already Created
                      </Typography>
                      <Typography variant="h5" color="info.main">
                        {createdQuestions}
                      </Typography>
                    </Paper>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        flex: 1,
                        bgcolor: "background.default",
                        border: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Remaining
                      </Typography>
                      <Typography variant="h5" color="success.main">
                        {remainingQuestions}
                      </Typography>
                    </Paper>
                  </Stack>
                </Stack>
              </CardContent>
            </StyledCard>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <HelpIcon color="primary" />
                  CSV Format Guide
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CSVFormatInfo />
              </AccordionDetails>
            </Accordion>
          </Box>

          {/* Right Section - Upload */}
          <Box sx={{ flex: "2 1 600px" }}>
            <StyledCard>
              <CardContent>
                <StyledDropZone
                  isDragActive={dragActive}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="csv-upload"
                  />
                  <label htmlFor="csv-upload">
                    <Stack spacing={2} alignItems="center">
                      <CloudUploadIcon
                        sx={{ fontSize: 48, color: "primary.main" }}
                      />
                      <Typography variant="h6" color="primary">
                        Drag & Drop your CSV file here
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        or click to browse
                      </Typography>
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<DownloadIcon />}
                        onClick={handleSampleDownload}
                      >
                        Download Sample CSV
                      </Button>
                    </Stack>
                  </label>
                </StyledDropZone>

                {file && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Upload Progress:
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ mt: 1, display: "block" }}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                )}

                {questionsInFile > 0 && (
                  <Alert
                    severity={
                      questionsInFile <= remainingQuestions ? "info" : "warning"
                    }
                    sx={{ mt: 2 }}
                  >
                    {questionsInFile <= remainingQuestions
                      ? `${questionsInFile} questions found in the file and ready to be added.`
                      : `File contains ${questionsInFile} questions, which exceeds the remaining limit (${remainingQuestions}). Please adjust the data.`}
                  </Alert>
                )}

                {file && questionsInFile > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleSaveAll}
                    disabled={isLoading || questionsInFile > remainingQuestions}
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 2 }}
                  >
                    Save All Questions
                  </Button>
                )}
              </CardContent>
            </StyledCard>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MCQBulkQuestion;
