import React, { useState, useEffect } from "react";
import {
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CandidateHeader from "./CandidateHeader";
import CandidateMainContent from "./CandidateMainContent";
// import CandidateLogin from "./CandidateLogin";
import { useNavigate } from "react-router-dom";

// Custom Theme (same as in CandidateDashboard)
const theme = createTheme({
  palette: {
    primary: {
      main: "#1a73e8",
    },
    background: {
      default: "#f4f6f8",
      paper: "#ffffff",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "rgba(0,0,0,0.6)",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

const GuestCandidateDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("compete");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [selectedTestForLogin, setSelectedTestForLogin] = useState(null);

  const handleAttemptTestRedirect = (test) => {
    // Store the test in localStorage to retrieve after login
    localStorage.setItem("selectedTest", JSON.stringify(test));
    setSelectedTestForLogin(test);
    setShowLoginModal(true);
    // Navigate to candidate login
    navigate("/login");
  };

  const handleLoginSuccess = () => {
    setShowLoginModal(false);
    // Optionally, you could pass the selected test to the actual test attempt page
    // Retrieve the stored test
    const storedTest = JSON.parse(localStorage.getItem("selectedTest"));

    // Clear the stored test
    localStorage.removeItem("selectedTest");

    // Navigate to profile page or test page
    navigate("/profilepage");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          background: "linear-gradient(135deg, #f5f7fa 0%, #f5f7fa 100%)",
        }}
      >
        <CandidateHeader
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isGuestMode={true}
        />
        <CandidateMainContent
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isGuestMode={true} // Explicitly set this
          onAttemptTestRedirect={handleAttemptTestRedirect}
        />

        {/* Login Modal for Test Attempt */}
        <Dialog
          open={showLoginModal}
          onClose={() => setShowLoginModal(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Login to Attempt Test</DialogTitle>
          <DialogContent>
            {/* <CandidateLogin
              preselectedTest={selectedTestForLogin}
              onLoginSuccess={handleLoginSuccess}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowLoginModal(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default GuestCandidateDashboard;
