import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Alert,
  Stack,
  Paper,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// Styled components
const StyledDropZone = styled(Paper)(({ theme, isDragActive }) => ({
  border: `2px dashed ${
    isDragActive ? theme.palette.primary.main : theme.palette.divider
  }`,
  backgroundColor: isDragActive
    ? theme.palette.action.hover
    : theme.palette.background.paper,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
  borderRadius: theme.spacing(2),
}));

const CSVFormatInfo = () => (
  <Box sx={{ mt: 2 }}>
    <Typography
      variant="subtitle1"
      gutterBottom
      sx={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: 1 }}
    >
      <InfoIcon color="primary" />
      Required CSV Format:
    </Typography>
    <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
      <Typography variant="body2" component="pre" sx={{ overflowX: "auto" }}>
        email participant1@example.com participant2@example.com
      </Typography>
    </Paper>
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      <Chip
        icon={<CheckCircleIcon />}
        label="UTF-8 Encoding"
        color="primary"
        variant="outlined"
      />
      <Chip
        icon={<CheckCircleIcon />}
        label="Header Required"
        color="primary"
        variant="outlined"
      />
      <Chip
        icon={<CheckCircleIcon />}
        label="Valid Emails Only"
        color="primary"
        variant="outlined"
      />
    </Stack>
  </Box>
);

const TestAddBulkParticipant = ({ testId, token, onBack }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [csvRowCount, setCsvRowCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = localStorage.getItem("user_id");

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      await handleFileUpload(file);
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    setSelectedFile(file);
    simulateProgress();

    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/bulk/validate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { rowCount, invalidEmails, duplicateEmails } = response.data;
      setCsvRowCount(rowCount);

      if (invalidEmails.length > 0 || duplicateEmails.length > 0) {
        setErrorMessage(
          `File contains ${
            invalidEmails.length ? "invalid" : "duplicate"
          } emails: ${
            invalidEmails.length
              ? invalidEmails.join(", ")
              : duplicateEmails.join(", ")
          }`
        );
        return;
      }

      // If validation passes, move to step 2
      setActiveStep(2); // Changed from 1 to 2
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Error processing file");
      setSelectedFile(null);
    }
  };

  const simulateProgress = () => {
    setUploadProgress(0);
    const timer = setInterval(() => {
      setUploadProgress((oldProgress) => {
        const diff = Math.random() * 10;
        const newProgress = Math.min(oldProgress + diff, 100);
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 100);
  };

  const handleBulkSave = async (e) => {
    e.preventDefault();
    try {
      // await axios.post(
      //   `${import.meta.env.VITE_BASE_URL}/bulk-add-test-participants`,
      //   {
      //     user_id: userId,
      //     test_id: testId,
      //   }
      // );

      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/participants/bulk`,
        {
          user_id: userId,
          test_id: testId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onBack();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Error saving participants"
      );
    }
  };

  const steps = [
    {
      label: "Upload CSV File",
      description: "Upload a CSV file containing participant email addresses",
    },
    {
      label: "Review Information",
      description: "Review participant data before saving",
    },
    {
      label: "Save Participants",
      description: "Confirm and save all participants",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <IconButton onClick={onBack} color="primary">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" fontWeight="bold">
            Bulk Add Participants
          </Typography>
        </Stack>

        <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
          {/* Left Section - Information */}
          <Box sx={{ flex: "1 1 300px", minWidth: 300 }}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  <InfoIcon /> Instructions
                </Typography>
                <Typography variant="body2" paragraph>
                  Upload a CSV file containing participant email addresses to
                  add them in bulk. Make sure your CSV follows the required
                  format.
                </Typography>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      <HelpIcon color="primary" />
                      CSV Format Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CSVFormatInfo />
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </StyledCard>
          </Box>

          {/* Right Section - Upload & Steps */}
          <Box sx={{ flex: "2 1 600px" }}>
            <StyledCard>
              <CardContent>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <Typography variant="subtitle1">
                          {step.label}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Typography color="text.secondary" paragraph>
                          {step.description}
                        </Typography>
                        {index === 0 && (
                          <Box>
                            <StyledDropZone
                              isDragActive={dragActive}
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            >
                              <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileSelect}
                                style={{ display: "none" }}
                                id="csv-upload"
                              />
                              <label htmlFor="csv-upload">
                                <Stack spacing={2} alignItems="center">
                                  <CloudUploadIcon
                                    sx={{ fontSize: 48, color: "primary.main" }}
                                  />
                                  <Typography variant="h6" color="primary">
                                    Drag & Drop your CSV file here
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    or click to browse
                                  </Typography>
                                  <Button
                                    variant="outlined"
                                    component="a"
                                    href="/TestSampleBulk.csv"
                                    download
                                    startIcon={<DownloadIcon />}
                                  >
                                    Download Sample CSV
                                  </Button>
                                </Stack>
                              </label>
                            </StyledDropZone>

                            {selectedFile && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Upload Progress:
                                </Typography>
                                <LinearProgress
                                  variant="determinate"
                                  value={uploadProgress}
                                />
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ mt: 1, display: "block" }}
                                >
                                  {selectedFile.name}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        )}

                        {index === 1 && csvRowCount > 0 && (
                          <Box sx={{ mt: 2 }}>
                            <Alert severity="info" sx={{ mb: 2 }}>
                              {`${csvRowCount} participants found in the file and ready to be added.`}
                            </Alert>
                            <Button
                              variant="contained"
                              onClick={() => setActiveStep(2)}
                              size="large"
                            >
                              Continue to Save
                            </Button>
                          </Box>
                        )}

                        {index === 2 && (
                          <Box sx={{ mt: 2 }}>
                            <Alert severity="info" sx={{ mb: 2 }}>
                              Ready to add {csvRowCount} participants to the
                              test.
                            </Alert>
                            <Button
                              onClick={handleBulkSave}
                              variant="contained"
                              size="large"
                              startIcon={<CloudUploadIcon />}
                            >
                              Save All Participants
                            </Button>
                          </Box>
                        )}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
            </StyledCard>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TestAddBulkParticipant;
