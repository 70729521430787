import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Skeleton,
  Card,
  CardContent,
  Chip,
  useTheme,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Download as DownloadIcon,
  EmojiEvents as TrophyIcon,
  ArrowBack as ArrowBackIcon,
  AccessTime as TimeIcon,
  Description as DescriptionIcon,
  Tag as TagIcon,
  People as PeopleIcon,
  Analytics as AnalyticsIcon,
  CalendarToday,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { dummyAnalysisData } from "./Analysis/dummyData";
import SectionAnalysisSelection from "./Analysis/SectionAnalysisSelection";
import TestAnalysisView from "./Analysis/TestAnalysisView";

const ScoreBoard = ({ testId, onClose }) => {
  const [testDetails, setTestDetails] = useState(null);
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSection, setLoadingSection] = useState(false);
  const [view, setView] = useState("scoreboard");
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [scoresResponse, testDetailsResponse] = await Promise.all([
          axios.get(`${BASE_URL}/api/org/tests/score`, {
            params: { testId },
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${BASE_URL}/api/org/tests/get-test-data/${testId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setScores(scoresResponse.data.data);
        // Store only the data part of the response
        setTestDetails(testDetailsResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // toast.error("Failed to fetch scoreboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [testId]);

  const getCsvData = () => {
    return scores.map((score, index) => ({
      Rank: index + 1,
      Email: score.email,
      "Total Score": score.combined_total_points,
    }));
  };

  const formatDateTime = (dateString) => {
    try {
      return new Date(dateString).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      return "Date not available";
    }
  };

  //function to calculate total duration from sections
  const calculateTotalDuration = (sections = []) => {
    return sections.reduce(
      (total, section) => total + section.sectionDuration,
      0
    );
  };

  const handleAnalysisClick = async (participant) => {
    setLoadingSection(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/tests/section-data`,
        {
          params: { testId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      if (response.data.success) {
        setSectionData(response.data.data);
        setSelectedParticipant(participant);
        setView("sections");
      } else {
        toast.error("Failed to fetch section data");
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
      toast.error("Failed to fetch section data");
    } finally {
      setLoadingSection(false);
    }
  };
   const handleSectionSelect = (section) => {
    setSelectedSection(section);
    setView("analysis");
  };
   const handleBack = () => {
    if (view === "analysis") {
      setView("sections");
      setSelectedSection(null);
    } else if (view === "sections") {
      setView("scoreboard");
      setSelectedParticipant(null);
      setSectionData(null);
    }
  };
   // Render different views
  if (view === "analysis" && selectedSection) {
    return (
      <TestAnalysisView
        testId={testId}
        section={selectedSection}
        participantEmail={selectedParticipant?.email}
        onBack={handleBack}
      />
    );
  }
   if (view === "sections") {
    return (
      <SectionAnalysisSelection
        sections={sectionData || []}
        testId={testId}
        participantEmail={selectedParticipant?.email}
        onSectionSelect={handleSectionSelect}
        onBack={handleBack}
        isLoading={loadingSection}
      />
    );
  }

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <Skeleton variant="rectangular" height={50} sx={{ mb: 4 }} />
          <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} />
          <Skeleton variant="rectangular" height={400} />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onClose}
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Back to Tests
        </Button>

        {/* Test Details Section */}
        <Box sx={{ mb: 6 }}>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
            >
              {testDetails?.testTitle}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mb: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <TagIcon color="primary" />
                <Typography variant="subtitle1">
                  Test ID: {testDetails?.testId}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <TimeIcon color="primary" />
                <Typography variant="subtitle1">
                  Duration: {calculateTotalDuration(testDetails?.sections)}{" "}
                  minutes
                </Typography>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CalendarToday color="primary" />
                <Typography variant="subtitle1">
                  Start: {formatDateTime(testDetails?.testStartDateTime)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CalendarToday color="primary" />
                <Typography variant="subtitle1">
                  End: {formatDateTime(testDetails?.testEndDateTime)}
                </Typography>
              </Box> */}
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
              <DescriptionIcon color="primary" sx={{ mt: 0.5 }} />
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.secondary }}
              >
                {testDetails?.testDescription}
              </Typography>
            </Box>

            {/* Section Details */}
            {/* {testDetails?.sections?.map((section, index) => (
              <Box
                key={section.sectionId}
                sx={{
                  mt: 2,
                  p: 2,
                  backgroundColor: theme.palette.grey[50],
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" color="primary" gutterBottom>
                  {section.sectionName}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  <Chip
                    size="small"
                    label={`Duration: ${section.sectionDuration} mins`}
                  />
                  <Chip
                    size="small"
                    label={`MCQ Questions: ${
                      section.mcqQuestionIds?.length || 0
                    }`}
                  />
                  <Chip
                    size="small"
                    label={`Coding Questions: ${
                      section.codingQuestionIds?.length || 0
                    }`}
                  />
                </Box>
              </Box>
            ))} */}
          </Box>
          <Divider sx={{ mb: 3 }} />
        </Box>

        {/* Table Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mb: 3,
          }}
        >
          <Chip
            icon={<PeopleIcon />}
            label={`${scores.length} Participants`}
            color="primary"
            sx={{ px: 2 }}
          />
          <CSVLink
            data={getCsvData()}
            filename={`${testDetails?.testTitle || "test"}_${
              testDetails?.testId || ""
            }_scores.csv`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              disabled={scores.length === 0}
            >
              Export to CSV
            </Button>
          </CSVLink>
        </Box>

        {/* Scoreboard Table */}
        {scores.length > 0 ? (
          <TableContainer
            component={Paper}
            elevation={3}
            sx={{ borderRadius: 2, overflow: "hidden" }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell sx={{ color: "white" }}>Rank</TableCell>
                  <TableCell sx={{ color: "white" }}>Email</TableCell>
                  <TableCell align="right" sx={{ color: "white" }}>
                    Total Score
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    Analysis
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.map((score, index) => (
                  <TableRow
                    key={score.participant_id}
                    sx={{
                      backgroundColor:
                        index < 3
                          ? `${theme.palette.primary.light}15`
                          : "inherit",
                      "&:hover": {
                        backgroundColor: `${theme.palette.primary.light}25`,
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {index < 3 && (
                          <TrophyIcon
                            sx={{
                              color:
                                index === 0
                                  ? "#FFD700"
                                  : index === 1
                                  ? "#C0C0C0"
                                  : "#CD7F32",
                            }}
                          />
                        )}
                        {index + 1}
                      </Box>
                    </TableCell>
                    <TableCell>{score.email}</TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight={index < 3 ? "bold" : "regular"}
                      >
                        {score.combined_total_points}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="View Test Analysis">
                        <IconButton
                          color="primary"
                          onClick={() => handleAnalysisClick(score)}
                        >
                          <AnalyticsIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Card
            sx={{
              textAlign: "center",
              py: 8,
              backgroundColor: theme.palette.grey[50],
            }}
          >
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                No scores available for this test yet
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
};

export default ScoreBoard;
