import React from "react";
import "../../css/FeatureSection.css";
import { Link, useNavigate } from "react-router-dom";

const FeatureSection = ({ isDarkTheme }) => {
  const navigate = useNavigate();

  const handleGuestDashboardAccess = () => {
    // Navigate to the guest candidate dashboard
    navigate("/guest-candidate-dashboard");
  };

  return (
    <section className={`feature-section`}>
      <div className="features-grid">
        <div className="feature-card">
          <div className="feature-image-container">
            <img
              src="/assets/coding.png"
              alt="Coding"
              className="feature-image"
            />
          </div>
          <div className="feature-details-container">
            <h3 className="feature-title">Interactive Coding Challenges</h3>
            <p className="feature-details">
              Sharpen your coding skills with a wide range of interactive
              challenges covering various programming languages and difficulty
              levels.
            </p>
          </div>
          <div className="features-link">
            <button 
              onClick={handleGuestDashboardAccess}
            >
              View Challenges &gt;
            </button>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-image-container">
            <img
              src="/assets/tutorials.png"
              alt="Tutorials"
              className="feature-image"
            />
          </div>
          <div className="feature-details-container">
            <h3 className="feature-title">Comprehensive Tutorials</h3>
            <p className="feature-details">
              Access comprehensive tutorials and guides curated by
              experts, covering fundamental concepts, advanced topics, and
              real-world applications.
            </p>
          </div>
          <span className="features-link"><Link to="/tutorials">View Tutorials &gt;</Link></span>
        </div>
        <div className="feature-card">
          <div className="feature-image-container">
            <img
              src="/assets/projects.png"
              alt="Projects"
              className="feature-image"
            />
          </div>
          <div className="feature-details-container">
            <h3 className="feature-title">Skill Up</h3>
            <p className="feature-details">
              Apply your learning to real-world projects and build a strong
              portfolio to showcase your skills to potential employers.
            </p>
          </div>
          <span className="features-link"><Link to="/login">View Projects &gt;</Link></span>
        </div>
        <div className="feature-card">
          <div className="feature-image-container">
            <img
              src="/assets/ai-test.jpg"
              alt="AI-Powered Test Creation"
              className="feature-image"
            />
          </div>
          <div className="feature-details-container">
            <h3 className="feature-title">AI-Powered Test Creation</h3>
            <p className="feature-details">
              Streamline your assessment process with intelligent, automated
              test generation tailored to your organization's needs.
            </p>
          </div>
          <span className="features-link"><Link to="/login">Try It &gt;</Link></span>
        </div>
      </div>
  
    </section>
  );
};

export default FeatureSection;