import React from "react";
import { Link } from "react-router-dom";
import "../../css/HomeHeroSection.css";
import { Bot } from 'lucide-react';

// Additional CSS for the AI tag
const styles = {
  aiTag: {
    display: 'inline-block',
    backgroundColor: '#27ae60',
    color: 'white',
    padding: '4px 12px',
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '10px',
    letterSpacing: '0.05em'
  }
};

const HomeHeroSection = ({ isDarkTheme }) => {
  return (
    <section className={`home-hero ${isDarkTheme ? "dark-theme" : ""}`}>
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-text">
            <div className="hero-title-wrapper">
            <div className="ai-tag-wrapper">
            <Bot 
      className="ai-icon rotate-glow-animation" 
      size={28} 
      color="#27ae60" 
      style={{ marginBottom: '6px' }} 
    />
              <span className="ai-tag">AI Powered</span>

              </div>
              <h1 className="hero-title">Master Coding with CirrusCypher</h1>
            </div>
            <p className="hero-description">
              Level up your coding skills with interactive challenges, tutorials, and real-world projects.
            </p>
            <div className="cta-buttons">
              <Link to="/get-started" className="cta-button">Get Started</Link>
              <Link to="/WhyCodeEditor" className="cta-button secondary">Learn More</Link>
            </div>
          </div>
          <div className="hero-image">
            <img src="/assets/hero-image.jpg" alt="Coding Illustration" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHeroSection;