import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../css/CandidateSignup.css"; // Make sure this file includes the updated CSS
import { showNotification } from "../../utils/notification";
import Navbar from "../Navbar";
import { Home } from "lucide-react";

const CandidateSignup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
  const navigate = useNavigate();

  const handleSendOTP = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/otp`,
        { email }
      );
      console.log("OTP sent:", response.data);
      showNotification("success", "OTP sent to your email.");
      setOtpSent(true); // Show OTP input after sending successfully
    } catch (error) {
      console.error("Error sending OTP:", error);
      showNotification("error", "Failed to send OTP. Please try again.");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/otp/verify`,
        {
          email,
          otp,
        }
      );

      const { message } = response.data;

      if (message === "OTP verified successfully") {
        showNotification(
          "success",
          "OTP verified successfully. Proceed with signup."
        );
        setIsVerified(true); // Show username and password inputs after OTP verification
      } else if (message === "OTP expired") {
        showNotification("error", "OTP expired. Please retry.");
      } else if (message === "OTP not found or expired") {
        showNotification("error", "Invalid OTP. Please enter correct OTP.");
      } else {
        showNotification("error", "Unexpected error. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      showNotification("error", "Failed to verify OTP. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isVerified) {
      showNotification("error", "Please verify the OTP before signing up");
      return;
    }

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/auth/signup`,
        {
          email: email,
          password: password,
          username: username,
          role: "CANDIDATE",
        }
      );
      if (response.data.error === null) {
        setIsVerified(false);
        navigate("/login");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        if (errorMessage === "this email is already used") {
          showNotification("error", "This email is already used");
        } else if (errorMessage === "this username is already used") {
          showNotification("error", "This username is already used");
        } else {
          showNotification("error", `An error occurred: ${errorMessage}`);
        }
      } else {
        showNotification("error", "An unexpected error occurred");
      }
      setEmail("");
      setPassword("");
      setUsername("");
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      {/* <Navbar/> */}
      <div className="candidate-signup-page">
        <div className="candidate-signup-left">
          <div
            className="home-icon-container"
            onClick={handleGoHome}
            title="Go to Home"
          >
            <Home color="white" size={32} className="home-icon" />
          </div>
          <div className="background-animation"></div>
          <div className="candidate-signup-welcome-text">
            <h1>Welcome to Cirruscypher Community</h1>
          </div>
        </div>
        <div className="candidate-signup-right">
          <div className="candidate-signup-container">
            <h2 className="candidate-signup-title">Join Us</h2>
            <h1 className="candidate-signup-header">
              Create your Cirruscypher Account
            </h1>
            <form onSubmit={handleSubmit} className="candidate-signup-form">
              {/* Email input always visible */}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="candidate-signup-input-field"
              />
              {/* Show Send OTP button */}
              {!otpSent && (
                <button
                  type="button"
                  onClick={handleSendOTP}
                  className="candidate-signup-send-otp-button candidate-signup-button"
                >
                  Send OTP
                </button>
              )}

              {/* Show OTP and Verify OTP button if OTP is sent */}
              {otpSent && !isVerified && (
                <>
                  <input
                    type="text"
                    placeholder="OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="candidate-signup-input-field"
                  />
                  <button
                    type="button"
                    onClick={handleVerifyOTP}
                    className="candidate-signup-verify-otp-button candidate-signup-button"
                  >
                    Verify OTP
                  </button>
                </>
              )}

              {/* Show username, password and sign up button only if OTP is verified */}
              {isVerified && (
                <>
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="candidate-signup-input-field"
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={6}
                    required
                    className="candidate-signup-input-field"
                  />
                  <button
                    type="submit"
                    className="candidate-signup-submit-button candidate-signup-button"
                  >
                    Sign Up
                  </button>
                </>
              )}
            </form>
            <p className="candidate-signup-login-prompt">
              Already have an account?{" "}
              <span
                onClick={handleLoginClick}
                className="candidate-signup-login-link"
              >
                Log In
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSignup;
