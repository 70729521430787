import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const years = Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i);

const degrees = [
  'B.E', 'B.Tech', 'M.Tech', 'BCA', 'MCA', 'B.Sc', 
  'M.Sc', 'B.Com', 'M.Com', 'BBA', 'MBA', 'Ph.D'
];

const departments = [
  'Computer Science and Engineering',
  'Information Technology',
  'Electrical Engineering',
  'Mechanical Engineering',
  'Civil Engineering',
  'Electronics and Communication',
  'Chemical Engineering',
  'Business Administration',
  'Commerce',
  'Physics',
  'Mathematics'
];

const scoreTypes = ['GPA', 'Percentage', 'CGPA'];

const defaultData = {
  school: '',
  degree: '',
  department: '',
  startMonth: '',
  startYear: '',
  endMonth: '',
  endYear: '',
  scoreType: '',
  score: '',
  description: '',
  currentlyStudying: false,
};

const CandidateEducation = ({ open, onClose, onSave, educationData = null }) => {
  const [formData, setFormData] = useState(defaultData);
  const [isAddAnother, setIsAddAnother] = useState(false);
  
  useEffect(() => {
    // Only set form data if educationData exists and we're not in "add another" mode
    if (educationData && !isAddAnother) {
      setFormData({ ...defaultData, ...educationData });
    } else {
      setFormData(defaultData);
    }
    // Reset isAddAnother when dialog opens/closes
    setIsAddAnother(false);
  }, [educationData, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      currentlyStudying: checked,
      ...(checked ? { endMonth: '', endYear: '' } : {}),
    }));
  };

  const handleSave = () => {
    onSave(formData);
    if (isAddAnother) {
      setFormData(defaultData);
      setIsAddAnother(false);
    } else {
      onClose();
    }
  };

  const handleAddAnother = () => {
    setIsAddAnother(true);
    setFormData(defaultData);
  };

  const customSelectProps = {
    IconComponent: KeyboardArrowDownIcon,
    sx: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#BDBDBD'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3'
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: '20px 24px',
        borderBottom: '1px solid #E0E0E0'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {educationData && !isAddAnother ? 'Edit Education' : 'Add Education'}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: '24px' }}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            name="school"
            label="School/College *"
            value={formData.school}
            onChange={handleInputChange}
            placeholder="Which School/College have you studied at?"
            required
            variant="outlined"
          />

          <FormControl fullWidth>
            <InputLabel>Degree *</InputLabel>
            <Select
              name="degree"
              value={formData.degree}
              onChange={handleInputChange}
              label="Degree *"
              required
              {...customSelectProps}
            >
              {degrees.map((degree) => (
                <MenuItem key={degree} value={degree}>{degree}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Department</InputLabel>
            <Select
              name="department"
              value={formData.department}
              onChange={handleInputChange}
              label="Department"
              {...customSelectProps}
            >
              {departments.map((dept) => (
                <MenuItem key={dept} value={dept}>{dept}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
              Starting from *
            </Typography>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  name="startMonth"
                  value={formData.startMonth}
                  onChange={handleInputChange}
                  label="Month"
                  required
                  {...customSelectProps}
                >
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                  name="startYear"
                  value={formData.startYear}
                  onChange={handleInputChange}
                  label="Year"
                  required
                  {...customSelectProps}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.currentlyStudying}
                onChange={handleCheckboxChange}
                sx={{
                  '&.Mui-checked': {
                    color: '#2196F3'
                  }
                }}
              />
            }
            label="Currently studying here"
          />

          {!formData.currentlyStudying && (
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
                Ending in *
              </Typography>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    name="endMonth"
                    value={formData.endMonth}
                    onChange={handleInputChange}
                    label="Month"
                    required
                    {...customSelectProps}
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>{month}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    name="endYear"
                    value={formData.endYear}
                    onChange={handleInputChange}
                    label="Year"
                    required
                    {...customSelectProps}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          )}

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
              Score
            </Typography>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Score type</InputLabel>
                <Select
                  name="scoreType"
                  value={formData.scoreType}
                  onChange={handleInputChange}
                  label="Score type"
                  {...customSelectProps}
                >
                  {scoreTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="score"
                value={formData.score}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                placeholder="4.5"
              />
            </Stack>
          </Box>

          <TextField
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            label="Description"
            placeholder="Write about your education experience..."
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              color="primary"
              onClick={handleAddAnother}
              sx={{
                textTransform: 'none',
                color: '#2196F3'
              }}
            >
              Add another
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                textTransform: 'none',
                backgroundColor: '#2196F3',
                '&:hover': {
                  backgroundColor: '#1976D2'
                },
                borderRadius: '8px',
                paddingX: '24px'
              }}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CandidateEducation;