
import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
  Fade,
  Stack,
  Divider,
  Alert,
  Chip,
  Grid,
  Tooltip,
  Zoom,
  useTheme,
  alpha,
  Stepper,
  Step,
  StepLabel,
  LinearProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import { styled, keyframes } from '@mui/material/styles';

// Styled components (same as AiCodingQuestion)
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.9)}, 
    ${alpha(theme.palette.background.paper, 0.95)})`,
  borderRadius: theme.spacing(3),
  boxShadow: `0 10px 40px ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

const shimmer = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const pulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0 0 ${alpha('#2196F3', 0.4)}; }
  70% { transform: scale(1.05); box-shadow: 0 0 0 10px ${alpha('#2196F3', 0)}; }
  100% { transform: scale(1); box-shadow: 0 0 0 0 ${alpha('#2196F3', 0)}; }
`;

const GenerateButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, 
    ${theme.palette.primary.main} 30%, 
    ${theme.palette.primary.light} 90%)`,
  border: 0,
  borderRadius: 48,
  boxShadow: `0 3px 15px ${alpha(theme.palette.primary.main, 0.3)}`,
  color: 'white',
  height: 56,
  padding: '0 40px',
  fontSize: '1.1rem',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    background: `linear-gradient(45deg, 
      ${theme.palette.primary.light} 30%, 
      ${theme.palette.primary.main} 90%)`,
    boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
    transform: 'translateY(-2px)',
  },
  '&:active': {
    transform: 'translateY(1px)',
  },
  '&.generating': {
    animation: `${pulse} 2s infinite`,
  },
}));

const QuestionCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: alpha(theme.palette.background.paper, 0.8),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  '&:hover': {
    transform: 'translateY(-4px) scale(1.01)',
    boxShadow: `0 12px 24px ${alpha(theme.palette.primary.main, 0.1)}`,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderWidth: '2px',
      },
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  },
}));

const LoadingBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 3,
  background: alpha(theme.palette.primary.main, 0.1),
  '& .MuiLinearProgress-bar': {
    background: `linear-gradient(90deg, 
      ${theme.palette.primary.main}, 
      ${theme.palette.primary.light}, 
      ${theme.palette.primary.main})`,
    backgroundSize: '200% 100%',
    animation: `${shimmer} 2s infinite linear`,
  },
}));

const AiMcqQuestion = ({  onBack  }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    questionType: 'mcq',
    noOfQuestions: '',
    topic: '',
    difficulty: '',
    mcqConfig: {
      type: 'single correct',
      noOfOptions: 4,
      correctOptions: 2
    },
    defaultPoints: '1' // Added default points
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Configure Questions', 'Generate', 'Review & Add'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    setError('');
  };

  const validateForm = () => {
    if (!formData.noOfQuestions || !formData.topic || !formData.difficulty) {
      setError('Please fill in all required fields');
      return false;
    }
    return true;
  };

  const handleGenerate = async () => {
    if (!validateForm()) return;

    setError('');
    setIsGenerating(true);
    setActiveStep(1);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/ai`,
        {
          questionType: 'mcq',
          noOfQuestions: parseInt(formData.noOfQuestions),
          topic: formData.topic,
          difficulty: formData.difficulty,
          mcqConfig: {
            type: formData.mcqConfig.type,
            noOfOptions: parseInt(formData.mcqConfig.noOfOptions),
            minCorrectOptions: formData.mcqConfig.type === 'single correct' ? 1 : 2,
            maxCorrectOptions: formData.mcqConfig.type === 'single correct' ? 1 : parseInt(formData.mcqConfig.correctOptions)
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      const questions = response.data.questions.map((q, idx) => ({
        ...q,
        id: idx + 1,
        points: parseInt(formData.defaultPoints)
      }));

      setGeneratedQuestions(questions);
      setActiveStep(2);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate questions');
      setActiveStep(0);
    } finally {
      setIsGenerating(false);
    }
  };


  const handleAddQuestion = async (question) => {
    try {
      // First, fetch the plan limits
      const planResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/plan/id/limits`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
  
      // Then, fetch the current question count
      const countResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question/count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
  
      const { max_mcq_questions } = planResponse.data;
      const { mcq_question_count } = countResponse.data;
  
      // Check if user has reached the MCQ question limit
      if (mcq_question_count >= max_mcq_questions) {
        setError(`You have reached the maximum limit of ${max_mcq_questions} MCQ questions`);
        return;
      }
  
      // Proceed with adding the question if limit not reached
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/org/question/create-mcq-question`,
        {
          questionType: formData.mcqConfig.type === 'single correct' ? false : true,
          questionText: question.questionText,
          questionDescription: question.questionDescription || '',
          difficulty: formData.difficulty,
          points: question.points,
          userId: localStorage.getItem('user_id'),
          options: question.options
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
  
      setGeneratedQuestions(prev => 
        prev.filter(q => q.id !== question.id)
      );
  
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to add question');
    }
  };

  const handlePointsChange = (questionId, newPoints) => {
    setGeneratedQuestions(prev =>
      prev.map(question =>
        question.id === questionId
          ? { ...question, points: parseInt(newPoints) || 0 }
          : question
      )
    );
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4, position: 'relative' }}>
        {isGenerating && <LoadingBar />}
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Back to Questions
        </Button>

        <StyledPaper elevation={3}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
            <AutoAwesomeIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 40,
                animation: `${pulse} 2s infinite`,
              }}
            />
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 600,
                background: `linear-gradient(45deg, 
                  ${theme.palette.primary.main}, 
                  ${theme.palette.primary.light})`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
              }}
            >
              AI MCQ Generator
            </Typography>
          </Stack>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mb: 4 }}>
            <Alert severity="info" sx={{ mb: 3, borderRadius: 2 }}>
              Generate custom MCQ questions powered by AI. Specify your requirements below.
            </Alert>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Number of Questions"
                  name="noOfQuestions"
                  type="number"
                  value={formData.noOfQuestions}
                  onChange={handleInputChange}
                  InputProps={{
                    inputProps: { min: 1, max: 10 },
                    startAdornment: (
                      <QuizIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Topic"
                  name="topic"
                  value={formData.topic}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <SchoolIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Question Type</InputLabel>
                  <StyledSelect
                    name="mcqConfig.type"
                    value={formData.mcqConfig.type}
                    onChange={handleInputChange}
                    label="Question Type"
                  >
                    <MenuItem value="single correct">Single Correct</MenuItem>
                    <MenuItem value="multiple correct">Multiple Correct</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Difficulty</InputLabel>
                  <StyledSelect
                    name="difficulty"
                    value={formData.difficulty}
                    onChange={handleInputChange}
                    label="Difficulty"
                  >
                    <MenuItem value="easy">Easy</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="hard">Hard</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Number of Options"
                  name="mcqConfig.noOfOptions"
                  type="number"
                  value={formData.mcqConfig.noOfOptions}
                  onChange={handleInputChange}
                  InputProps={{
                    inputProps: { min: 4, max: 6 },
                    startAdornment: (
                      <HelpOutlineIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
          <StyledTextField
            fullWidth
            label="Default Points per Question"
            name="defaultPoints"
            type="number"
            value={formData.defaultPoints}
            onChange={handleInputChange}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <SchoolIcon sx={{ color: 'action.active', mr: 1 }} />
              ),
            }}
          />
        </Grid>

              {formData.mcqConfig.type === 'multiple correct' && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Number of Correct Options</InputLabel>
              <StyledSelect
                name="mcqConfig.correctOptions"
                value={formData.mcqConfig.correctOptions}
                onChange={handleInputChange}
                label="Number of Correct Options"
              >
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>
        )}
            </Grid>

            {error && (
              <Zoom in={!!error}>
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              </Zoom>
            )}

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <GenerateButton
                onClick={handleGenerate}
                disabled={isGenerating}
                className={isGenerating ? 'generating' : ''}
                startIcon={isGenerating ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <AutoAwesomeIcon />
                )}
              >
                {isGenerating ? 'Generating Questions...' : 'Generate Questions'}
              </GenerateButton>
            </Box>
          </Box>

          {generatedQuestions.length > 0 && (
            <Box sx={{ mt: 6 }}>
              <Divider sx={{ mb: 4 }}>
              <Chip
  label="Generated Questions"
  color="primary"
  icon={<AutoAwesomeIcon />}
  sx={{
    px: 2,
    '& .MuiChip-label': {
      display: 'block',
      overflow: 'unset',
      textOverflow: 'unset',
      whiteSpace: 'nowrap',
    },
    '& .MuiChip-icon': {
      fontSize: 20,
    },
  }}
/>
              </Divider>

              <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                {generatedQuestions.map((question, index) => (
                  <Fade in={true} timeout={500 * (index + 1)} key={question.id}>
                    <QuestionCard>
                      <CardContent>
                      <Stack spacing={2}>
                          <Stack 
                            direction="row" 
                            justifyContent="space-between" 
                            alignItems="center"
                          >
                            <Box>
                              <Typography 
                                variant="h6" 
                                sx={{
                                  fontWeight: 600,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                Question {index + 1}
                              </Typography>
                              <Stack 
                                direction="row" 
                                spacing={1} 
                                sx={{ mt: 1 }}
                              >
                                <Chip
                                  size="small"
                                  label={formData.difficulty}
                                  sx={{
                                    backgroundColor: alpha(
                                      formData.difficulty === 'easy' ? theme.palette.success.main :
                                      formData.difficulty === 'medium' ? theme.palette.warning.main :
                                      theme.palette.error.main,
                                      0.1
                                    ),
                                    color: formData.difficulty === 'easy' ? theme.palette.success.main :
                                          formData.difficulty === 'medium' ? theme.palette.warning.main :
                                          theme.palette.error.main,
                                    fontWeight: 500,
                                  }}
                                />
                                <Chip
                                  size="small"
                                  label={formData.mcqConfig.type}
                                  sx={{
                                    backgroundColor: alpha(theme.palette.info.main, 0.1),
                                    color: theme.palette.info.main,
                                    fontWeight: 500,
                                  }}
                                />
                                <Tooltip title="Points for this question" arrow>
                              <Box>
                                <StyledTextField
                                  size="small"
                                  type="number"
                                  value={question.points}
                                  onChange={(e) => handlePointsChange(question.id, e.target.value)}
                                  InputProps={{
                                    startAdornment: (
                                      <Typography variant="caption" sx={{ mr: 1 }}>
                                        Points:
                                      </Typography>
                                    ),
                                    inputProps: { min: 0, style: { width: '60px' } }
                                  }}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                    }
                                  }}
                                />
                              </Box>
                            </Tooltip>
                              </Stack>
                            </Box>
                            <Button
                              variant="contained"
                              startIcon={<AddIcon />}
                              onClick={() => handleAddQuestion(question)}
                              sx={{
                                backgroundColor: alpha(theme.palette.success.main, 0.1),
                                color: theme.palette.success.main,
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.success.main, 0.2),
                                },
                              }}
                            >
                              Add Question
                            </Button>
                          </Stack>

                          <Typography 
                            variant="body1" 
                            sx={{ 
                              color: theme.palette.text.primary,
                              fontWeight: 500,
                              mb: 2 
                            }}
                          >
                            {question.questionText}
                          </Typography>

                          <Stack spacing={1}>
                            {question.options.map((option, optIndex) => (
                              <Paper
                                key={optIndex}
                                sx={{
                                  p: 1.5,
                                  backgroundColor: option.isCorrect ? 
                                    alpha(theme.palette.success.main, 0.1) : 
                                    alpha(theme.palette.background.default, 0.5),
                                  border: `1px solid ${alpha(
                                    option.isCorrect ? theme.palette.success.main : theme.palette.divider,
                                    0.1
                                  )}`,
                                  borderRadius: 1,
                                }}
                              >
                                <Typography 
                                  variant="body2"
                                  sx={{
                                    color: option.isCorrect ? 
                                      theme.palette.success.main : 
                                      theme.palette.text.primary
                                  }}
                                >
                                  {String.fromCharCode(65 + optIndex)}. {option.optionText}
                                </Typography>
                              </Paper>
                            ))}
                          </Stack>
                        </Stack>
                      </CardContent>
                    </QuestionCard>
                  </Fade>
                ))}
              </Box>
            </Box>
          )}
        </StyledPaper>
      </Box>
    </Container>
  );
};

export default AiMcqQuestion;