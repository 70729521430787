import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Container,
  Chip,
  LinearProgress,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  GetApp as DownloadIcon,
  EmojiEvents as TrophyIcon,
  Person as PersonIcon,
  Assessment as AssessmentIcon,
  Stars as StarsIcon,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: "100%",
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));

const ScoreHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[1],
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TopPerformerChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.contrastText,
  fontWeight: "bold",
}));

const StatsCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  transition: "transform 0.2s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[8],
  },
}));

const Scorecard = ({ testId, testType, onBack, testTitle }) => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    avgScore: 0,
    maxScore: 0,
    totalParticipants: 0,
    scoreDistribution: [],
  });

  const getData = async () => {
    try {
      setLoading(true);
      // I think we are not using this structure, api anymore
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-email-and-points`,
        {
          testId,
          testType,
        }
      );

      if (!response.data.data || response.data.data.length === 0) {
        toast.info("No participants have taken this test yet.");
        setError("No participant data available");
        return;
      }

      const sortedParticipants = response.data.data.sort(
        (a, b) => b.total_points - a.total_points
      );

      // Calculate statistics
      const totalScore = sortedParticipants.reduce(
        (sum, p) => sum + p.total_points,
        0
      );
      const maxScore = Math.max(
        ...sortedParticipants.map((p) => p.total_points)
      );
      const avgScore = totalScore / sortedParticipants.length;

      // Calculate score distribution for pie chart
      const distribution = calculateScoreDistribution(sortedParticipants);

      setStats({
        avgScore,
        maxScore,
        totalParticipants: sortedParticipants.length,
        scoreDistribution: distribution,
      });

      setParticipants(sortedParticipants);
      setError(null);
      toast.success("Scorecard loaded successfully!");
    } catch (error) {
      console.error("Error fetching scorecard data:", error);
      toast.error("Failed to load scorecard data");
      setError("Failed to load scorecard data");
    } finally {
      setLoading(false);
    }
  };

  const calculateScoreDistribution = (participants) => {
    const ranges = [
      { range: "0-20%", count: 0, color: "#ff0000" },
      { range: "21-40%", count: 0, color: "#ff9900" },
      { range: "41-60%", count: 0, color: "#ffff00" },
      { range: "61-80%", count: 0, color: "#00ff00" },
      { range: "81-100%", count: 0, color: "#00cc00" },
    ];

    const maxPossibleScore = stats.maxScore || 100;

    participants.forEach((participant) => {
      const percentage = (participant.total_points / maxPossibleScore) * 100;
      if (percentage <= 20) ranges[0].count++;
      else if (percentage <= 40) ranges[1].count++;
      else if (percentage <= 60) ranges[2].count++;
      else if (percentage <= 80) ranges[3].count++;
      else ranges[4].count++;
    });

    return ranges.filter((range) => range.count > 0);
  };

  useEffect(() => {
    if (testId && testType) {
      getData();
    }
  }, [testId, testType]);

  const csvData = [
    ["Rank", "Email", "Score"],
    ...participants.map((participant, index) => [
      index + 1,
      participant.email,
      participant.total_points,
    ]),
  ];

  if (loading)
    return (
      <Container>
        <LinearProgress />
        <Typography sx={{ mt: 2 }}>Loading scorecard data...</Typography>
      </Container>
    );

  if (error)
    return (
      <Container>
        <Box sx={{ mb: 4 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="outlined"
            sx={{ mt: 2, mr: 2, display: "flex", justifyContent: "flex-start" }}
          >
            Back
          </Button>
        </Box>
        <Card sx={{ p: 4, textAlign: "center" }}>
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Typography color="textSecondary">
            Please try again later or check if participants have taken the test.
          </Typography>
        </Card>
      </Container>
    );

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          variant="outlined"
          sx={{ mt: 2, mr: 2, display: "flex", justifyContent: "flex-start" }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1" sx={{ mt: 2 }}>
          {testTitle || `${testType.toUpperCase()} Test Scorecard`}
        </Typography>
      </Box>

      {/* Statistics Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <PersonIcon sx={{ fontSize: 40, color: "primary.main", mb: 1 }} />
            <Typography variant="h6">Total Participants</Typography>
            <Typography variant="h4">{stats.totalParticipants}</Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <AssessmentIcon
              sx={{ fontSize: 40, color: "success.main", mb: 1 }}
            />
            <Typography variant="h6">Average Score</Typography>
            <Typography variant="h4">{stats.avgScore.toFixed(2)}</Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <StarsIcon sx={{ fontSize: 40, color: "warning.main", mb: 1 }} />
            <Typography variant="h6">Highest Score</Typography>
            <Typography variant="h4">{stats.maxScore}</Typography>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatsCard>
            <TrophyIcon sx={{ fontSize: 40, color: "error.main", mb: 1 }} />
            <Typography variant="h6">Pass Rate</Typography>
            <Typography variant="h4">
              {(
                (participants.filter(
                  (p) => p.total_points >= stats.maxScore * 0.4
                ).length /
                  stats.totalParticipants) *
                100
              ).toFixed(1)}
              %
            </Typography>
          </StatsCard>
        </Grid>
      </Grid>

      {/* Score Distribution Chart */}
      {/* {stats.scoreDistribution.length > 0 && (
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Score Distribution
          </Typography>
          <Box sx={{ height: 300, display: 'flex', justifyContent: 'center' }}>
            <PieChart
              series={[
                {
                  data: stats.scoreDistribution.map(item => ({
                    id: item.range,
                    value: item.count,
                    label: `${item.range} (${item.count})`,
                    color: item.color,
                  })),
                },
              ]}
              width={400}
              height={300}
            />
          </Box>
        </Paper>
      )} */}

      {/* Results Table */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6">Detailed Results</Typography>
        <CSVLink
          data={csvData}
          filename={`scorecard-${testId}.csv`}
          className="csv-link"
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" startIcon={<DownloadIcon />}>
            Export Results
          </Button>
        </CSVLink>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Score</TableCell>
              <TableCell align="center">Performance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((participant, index) => (
              <TableRow key={participant.email}>
                <TableCell>
                  {index < 3 ? (
                    <Tooltip
                      title={`${index + 1}${
                        index === 0 ? "st" : index === 1 ? "nd" : "rd"
                      } Place`}
                    >
                      <IconButton size="small">
                        <TrophyIcon
                          sx={{
                            color:
                              index === 0
                                ? "#FFD700"
                                : index === 1
                                ? "#C0C0C0"
                                : "#CD7F32",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    index + 1
                  )}
                </TableCell>
                <TableCell>{participant.email}</TableCell>
                <TableCell align="right">{participant.total_points}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={
                      participant.total_points >= stats.maxScore * 0.8
                        ? "Excellent"
                        : participant.total_points >= stats.maxScore * 0.6
                        ? "Good"
                        : participant.total_points >= stats.maxScore * 0.4
                        ? "Pass"
                        : "Needs Improvement"
                    }
                    color={
                      participant.total_points >= stats.maxScore * 0.8
                        ? "success"
                        : participant.total_points >= stats.maxScore * 0.6
                        ? "primary"
                        : participant.total_points >= stats.maxScore * 0.4
                        ? "warning"
                        : "error"
                    }
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Scorecard;
