import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  QuestionAnswer as QuestionIcon,
  Assessment as AssessmentIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Settings as SettingsIcon ,
} from '@mui/icons-material';
import { GroupWork as GroupWorkIcon } from '@mui/icons-material';
import { profileImageStore } from './profileImageStore';

// Helper function to sanitize S3 URLs (reused from OrgProfile)
const sanitizeImageUrl = (url) => {
  if (!url) return null;
  
  try {
    const decodedUrl = decodeURIComponent(url);
    
    if (decodedUrl.includes('tc-engg.s3.ap-south-1.amazonaws.com') > 1) {
      const matches = decodedUrl.match(/tc-engg\.s3\.ap-south-1\.amazonaws\.com\/(.+?)(\?.*)?$/);
      if (matches && matches[1]) {
        const actualPath = matches[1];
        const authParams = matches[2] || '';
        return `https://tc-engg.s3.ap-south-1.amazonaws.com/${actualPath}${authParams}`;
      }
    }
    
    return decodedUrl;
  } catch (error) {
    console.error('Error sanitizing image URL:', error);
    return url;
  }
};

const UserSidebar = ({ activeItem, handleSetView }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState("");
  const userRole = localStorage.getItem("role"); // Get role from localStorage

  // In UserSidebar.jsx, update the useEffect:

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    setUsername(storedUsername || "User");
  
    const userId = localStorage.getItem("user_id");
    const cachedImageUrl = localStorage.getItem(`profile_image_${userId}`);
    if (cachedImageUrl) {
      setProfileImage(sanitizeImageUrl(cachedImageUrl));
    }
  
    const loadProfileImage = async () => {
      setImageLoading(true);
      try {
        const imageUrl = await profileImageStore.initializeProfileImage();
        setProfileImage(imageUrl || `/path/to/default-avatar.png`);
      } catch (error) {
        console.error("Error initializing profile image:", error);
        setProfileImage(`/path/to/default-avatar.png`);
        setImageError(true);
      } finally {
        setImageLoading(false);
      }
    };
  
    loadProfileImage();
  
    const unsubscribe = profileImageStore.subscribe((newImageUrl) => {
      const sanitizedUrl = sanitizeImageUrl(newImageUrl);
      setProfileImage(sanitizedUrl || `/path/to/default-avatar.png`);
      setImageLoading(false);
      setImageError(false);
    });
  
    return () => unsubscribe();
  }, []);
  
  

   // Define all possible menu items
   const allMenuItems = [
    { id: 'dashboard', icon: DashboardIcon, label: 'Dashboard', roles: ['ADMIN', 'ORG',  'USER'] },
    { id: 'tests', icon: AssessmentIcon, label: 'Tests', roles: ['ADMIN', 'ORG',  'USER'] },
    { id: 'questionGroups', icon: GroupWorkIcon, label: 'Question Groups', roles: ['ADMIN', 'ORG',  'USER'] },
    { id: 'questions', icon: QuestionIcon, label: 'Questions', roles: ['ADMIN', 'ORG',  'USER'] },
    { id: 'participants', icon: PeopleIcon, label: 'Participants', roles: ['ADMIN', 'ORG',  'USER'] },
    { id: 'billing', icon: ReceiptIcon, label: 'Billing & Invoice', roles: ['ADMIN', 'ORG'] },
    { id: 'users', icon: GroupIcon, label: 'Users', roles: ['ADMIN', 'ORG'] },
    { id: 'profile', icon: PersonIcon, label: 'Profile', roles: ['ADMIN',  'ORG'] },
    { id: 'settings', icon: SettingsIcon, label: 'Settings', roles: ['ADMIN',  'ORG',  'USER'] },
  ];

  // Filter menu items based on user role
  const menuItems = allMenuItems.filter(item => item.roles.includes(userRole));

  return (
    <Paper
      elevation={0}
      sx={{
        width: 280,
        // backgroundColor: '#f8f9fa',
        backgroundColor: '#ecf0f1',
        height: '100%',
        position: 'fixed',
        left: 0,
        // borderRight: '1px solid rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.3s ease'
      }}
    >
      {/* Profile Section */}
      <Box
        sx={{
          // background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%)',
          p: 2,
          pb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          // '&::after': {
          //   content: '""',
          //   position: 'absolute',
          //   bottom: 0,
          //   left: '10%',
          //   right: '10%',
          //   height: '1px',
          //   background: 'linear-gradient(90deg, transparent, rgba(0,0,0,0.1), transparent)'
          // }
        }}
      >
        {imageLoading && !imageError && (
          <Skeleton 
            variant="circular" 
            width={80} 
            height={80}
            sx={{ 
              mb: 1.5,
              border: '4px solid white',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
            }} 
          />
        )}
        <Avatar
          src={profileImage}
          sx={{
            width: 80,
            height: 80,
            mb: 1.5,
            bgcolor: 'primary.main',
            border: '4px solid white',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease',
            display: imageLoading ? 'none' : 'flex',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }}
          onLoad={() => {
            setImageLoading(false);
            setImageError(false);
          }}
          onError={(e) => {
            setImageLoading(false);
            setImageError(true);
            const fallbackName = username ||  'ORG';
            const encodedName = encodeURIComponent(fallbackName);
            e.target.src = `https://ui-avatars.com/api/?name=${encodedName}&background=random&size=90`;
          }}
        >
          {imageError && <PersonIcon sx={{ fontSize: 35 }} />}
        </Avatar>
        <Typography variant="h6" fontWeight="600" sx={{ color: 'text.primary' }}>
          {username}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.25 }}>
          {userRole}
        </Typography>
      </Box>

      {/* Navigation List */}
      <List sx={{ width: '100%', mt: 1, px: 1 }}>
        {menuItems.map((item) => {
          const Icon = item.icon;
          const isActive = activeItem === item.id;
          
          return (
            <ListItem
              key={item.id}
              onClick={() => handleSetView(item.id)}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                mb: 0.5,
                height: 48,
                '&:before': isActive ? {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: 4,
                  bottom: 4,
                  width: 4,
                  backgroundColor: 'primary.main',
                  borderRadius: '0 4px 4px 0'
                } : {},
                backgroundColor: isActive ? '#fff' : 'transparent',
                backdropFilter: isActive ? 'blur(8px)' : 'none',
                boxShadow: isActive ? '0 2px 8px rgba(0,0,0,0.05)' : 'none',
                '&:hover': {
                  backgroundColor: isActive ? '#fff' : 'rgba(0, 0, 0, 0.02)',
                  '& .MuiListItemIcon-root': {
                    color: isActive ? 'primary.main' : 'primary.light'
                  }
                },
                borderRadius: 2,
                transition: 'all 0.2s ease',
                pl: 2
              }}
            >
              <ListItemIcon 
                sx={{ 
                  minWidth: 36,
                  color: isActive ? 'primary.main' : 'text.secondary',
                  transition: 'color 0.2s ease'
                }}
              >
                <Icon sx={{ fontSize: 18 }} />
              </ListItemIcon>
              <ListItemText 
                primary={item.label}
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: '0.9rem',
                    color: isActive ? 'primary.main' : 'text.primary',
                    fontWeight: isActive ? 600 : 400,
                    transition: 'all 0.2s ease'
                  }
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default UserSidebar;