import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Alert,
  Stack,
  Paper,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Styled components
const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const StyledDropZone = styled(Paper)(({ theme, isDragActive }) => ({
  border: `2px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isDragActive ? theme.palette.action.hover : theme.palette.background.paper,
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
}));

const CSVFormatInfo = () => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon color="primary" />
      Required CSV Format:
    </Typography>
    <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
      <Typography variant="body2" component="pre" sx={{ overflowX: 'auto' }}>
        title,description,difficulty,testcase_input,testcase_output
        Question 1,Description 1,Easy,[1|2|3],[2|4|6]
        Question 2,Description 2,Medium,[4|5|6],[8|10|12]
      </Typography>
    </Paper>
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      <Chip icon={<CheckCircleIcon />} label="UTF-8 Encoding" color="primary" variant="outlined" />
      <Chip icon={<CheckCircleIcon />} label="Header Required" color="primary" variant="outlined" />
      <Chip icon={<CheckCircleIcon />} label="Valid Test Cases" color="primary" variant="outlined" />
    </Stack>
  </Box>
);

const CreateBulkQuestions = ({
  questionLimit,
  remainingQuestionCount,
  testcaseLimitPerQuestion,
  csvRowCount,
  errorMessage,
  handleCreateBulkQuestionsCSVUpload,
  handleBulkCreateQuestions,
  handleGoBackToQuestionsView,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      handleCreateBulkQuestionsCSVUpload({ target: { files: [file] } });
      setActiveStep(1);
      simulateProgress();
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleCreateBulkQuestionsCSVUpload(e);
      setActiveStep(1);
      simulateProgress();
    }
  };

  const simulateProgress = () => {
    setUploadProgress(0);
    const timer = setInterval(() => {
      setUploadProgress((oldProgress) => {
        const diff = Math.random() * 10;
        const newProgress = Math.min(oldProgress + diff, 100);
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 100);
  };

  const handleDownloadSample = () => {
    const link = document.createElement('a');
    link.href = '/SampleCreateBulkQuestions.csv';
    link.download = 'SampleCreateBulkQuestions.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const steps = [
    {
      label: 'Upload CSV File',
      description: 'Upload a CSV file containing question details',
    },
    {
      label: 'Review Information',
      description: 'Review question data before saving',
    },
    {
      label: 'Save Questions',
      description: 'Confirm and save all questions',
    },
  ];

  const usedQuestions = questionLimit - remainingQuestionCount;

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <IconButton
            onClick={handleGoBackToQuestionsView}
            color="primary"
            sx={{ p: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" fontWeight="bold">
            Bulk Create Questions
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
          {/* Left Section - Statistics */}
          <Box sx={{ flex: '1 1 300px', minWidth: 300 }}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <InfoIcon /> Plan Statistics
                </Typography>
                <Stack spacing={3}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', border: '1px solid', borderColor: 'divider' }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Total Question Limit
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {questionLimit}
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(usedQuestions / questionLimit) * 100}
                      sx={{ mt: 1 }}
                    />
                  </Paper>

                  <Stack direction="row" spacing={2}>
                    <Paper elevation={0} sx={{ p: 2, flex: 1, bgcolor: 'background.default', border: '1px solid', borderColor: 'divider' }}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Used Questions
                      </Typography>
                      <Typography variant="h5" color="info.main">
                        {usedQuestions}
                      </Typography>
                    </Paper>
                    <Paper elevation={0} sx={{ p: 2, flex: 1, bgcolor: 'background.default', border: '1px solid', borderColor: 'divider' }}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Remaining
                      </Typography>
                      <Typography variant="h5" color="success.main">
                        {remainingQuestionCount}
                      </Typography>
                    </Paper>
                  </Stack>

                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', border: '1px solid', borderColor: 'divider' }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Testcases per Question
                    </Typography>
                    <Typography variant="h5" color="warning.main">
                      {testcaseLimitPerQuestion}
                    </Typography>
                  </Paper>
                </Stack>
              </CardContent>
            </StyledCard>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <HelpIcon color="primary" />
                  CSV Format Guide
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CSVFormatInfo />
              </AccordionDetails>
            </Accordion>
          </Box>

          {/* Right Section - Upload & Steps */}
          <Box sx={{ flex: '2 1 600px' }}>
            <StyledCard>
              <CardContent>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <Typography variant="subtitle1">{step.label}</Typography>
                      </StepLabel>
                      <StepContent>
                        <Typography color="text.secondary" paragraph>
                          {step.description}
                        </Typography>
                        {index === 0 && (
                          <Box>
                            <StyledDropZone
                              isDragActive={dragActive}
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            >
                              <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileSelect}
                                style={{ display: 'none' }}
                                id="csv-upload"
                              />
                              <label htmlFor="csv-upload">
                                <Stack spacing={2} alignItems="center">
                                  <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                                  <Typography variant="h6" color="primary">
                                    Drag & Drop your CSV file here
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    or click to browse
                                  </Typography>
                                  <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<DownloadIcon />}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDownloadSample();
                                    }}
                                  >
                                    Download Sample CSV
                                  </Button>
                                </Stack>
                              </label>
                            </StyledDropZone>

                            {selectedFile && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Upload Progress:
                                </Typography>
                                <LinearProgress variant="determinate" value={uploadProgress} />
                                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                                  {selectedFile.name}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        )}

                        {index === 1 && csvRowCount > 0 && (
                          <Alert 
                            severity={csvRowCount <= remainingQuestionCount ? "info" : "warning"}
                            sx={{ mt: 2 }}
                            action={
                              csvRowCount <= remainingQuestionCount && (
                                <Button 
                                  color="inherit" 
                                  size="small"
                                  onClick={() => setActiveStep(2)}
                                >
                                  Continue
                                </Button>
                              )
                            }
                          >
                            {csvRowCount <= remainingQuestionCount ? (
                              `${csvRowCount} questions found in the file and ready to be added.`
                            ) : (
                              `File contains ${csvRowCount} questions, which exceeds the remaining limit (${remainingQuestionCount}). Please adjust the data.`
                            )}
                          </Alert>
                        )}

                        {index === 2 && (
                          <Button
                            onClick={handleBulkCreateQuestions}
                            variant="contained"
                            size="large"
                            disabled={!csvRowCount || csvRowCount > remainingQuestionCount}
                            startIcon={<CloudUploadIcon />}
                            sx={{ mt: 2 }}
                          >
                            Save All Questions
                          </Button>
                        )}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
            </StyledCard>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateBulkQuestions;