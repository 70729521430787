import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/AdminCreatePlan.css";
import AdminLanguage from "./AdminLanguage";

function AdminCreatePlan({ plan, closeForm, fetchPlans }) {
  const [currentPlan, setCurrentPlan] = useState({
    plan_name: "",
    monthly_price: "",
    annual_price: "",
    public: false,
    plan_description: "",
    max_tests: "",
    max_questions: "",
    max_testcases_per_question: "",
    max_participants: "",
    max_mcq_questions: "", // Added new field
    plan_type: "1", // Default to "1" for private plans
  });
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (plan) {
      setCurrentPlan({
        plan_name: plan.plan_name,
        monthly_price: plan.monthly_price,
        annual_price: plan.annual_price,
        public: plan.public,
        plan_description: plan.plan_description,
        max_tests: plan.max_tests,
        max_questions: plan.max_questions, // Match with backend expected field name
        max_testcases_per_question: plan.max_testcases_per_question,
        max_participants: plan.max_participants,
        max_mcq_questions: plan.max_mcq_questions, // Added new field
        plan_type: plan.plan_type || "1", // Default to "1" if not set
      });
      setSelectedLanguages(plan.languages || []);
    }
  }, [plan]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const newPlanType = checked ? "0" : "1"; // "0" for public, "1" for private
      setCurrentPlan((prevPlan) => ({
        ...prevPlan,
        [name]: checked,
        plan_type: newPlanType,
      }));
    } else {
      setCurrentPlan((prevPlan) => ({
        ...prevPlan,
        [name]: value,
      }));
    }
  };

  const handleSavePlan = async () => {
    try {
      const planData = {
        plan_name: currentPlan.plan_name,
        plan_description: currentPlan.plan_description,
        monthly_price: parseFloat(currentPlan.monthly_price), // Convert to number
        annual_price: parseFloat(currentPlan.annual_price), // Convert to number
        plan_type: currentPlan.plan_type,
        max_tests: parseInt(currentPlan.max_tests, 10), // Convert to number
        max_question: parseInt(currentPlan.max_questions, 10), // Adjust field name to match backend
        max_testcases_per_question: parseInt(
          currentPlan.max_testcases_per_question,
          10
        ), // Convert to number
        max_participants: parseInt(currentPlan.max_participants, 10), // Convert to number
        max_mcq_questions: parseInt(currentPlan.max_mcq_questions, 10), // Added new field
        languages: selectedLanguages,
      };

      // Step 1: Make API request to create plan
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/plan`,
        planData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the full response to inspect its structure
      console.log("Full response:", response);

      // Step 2: Check if the response was successful and includes the plan_id
      if (response.data && response.data.success) {
        const plan_id = response.data.data; // Correctly access the plan_id
        console.log("Plan created successfully. Plan ID:", plan_id);

        // Optionally store the plan_id in the current plan state
        setCurrentPlan((prevPlan) => ({
          ...prevPlan,
          plan_id: plan_id,
        }));

        // Step 3: Get user_id from local storage
        const user_id = localStorage.getItem("user_id"); // Assuming user_id is stored in local storage

        // Step 4: Create Razorpay Monthly Plan
        const monthlyPrice = parseFloat(currentPlan.monthly_price);
        if (monthlyPrice) {
          const razorpayMonthlyPlanData = {
            plan_type: "Monthly", // Monthly plan
            user_id, // From local storage
            plan_id, // The plan_id from our plan creation
            plan_name: currentPlan.plan_name,
            amount: monthlyPrice, // Use monthly price here
          };

          const razorpayMonthlyResponse = await axios.post(
            `${import.meta.env.VITE_BASE_URL}/api/razorpay/plan`, // Corrected route
            razorpayMonthlyPlanData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(
            "Razorpay Monthly Plan Created:",
            razorpayMonthlyResponse.data
          );
        }

        // Step 5: Create Razorpay Annual Plan
        const annualPrice = parseFloat(currentPlan.annual_price);
        if (annualPrice) {
          const razorpayAnnualPlanData = {
            plan_type: "Yearly", // Yearly plan
            user_id, // From local storage
            plan_id, // The same plan_id from our plan creation
            plan_name: currentPlan.plan_name,
            amount: annualPrice, // Use annual price here
          };

          const razorpayAnnualResponse = await axios.post(
            `${import.meta.env.VITE_BASE_URL}/api/razorpay/plan`, // Corrected route
            razorpayAnnualPlanData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(
            "Razorpay Annual Plan Created:",
            razorpayAnnualResponse.data
          );
        }

        // Step 6: Refresh the plans list and close the form
        fetchPlans();
        closeForm();
      } else {
        console.error("Failed to create plan:", response.data.error);
      }
    } catch (error) {
      console.error("Error saving plan", error);
    }
  };

  if (showLanguageSelection) {
    return (
      <AdminLanguage
        selectedLanguages={selectedLanguages}
        onSave={(newLanguages) => {
          setSelectedLanguages(newLanguages);
          setShowLanguageSelection(false);
        }}
        onClose={() => setShowLanguageSelection(false)}
      />
    );
  }

  return (
    <div className="create-plan-wrapper">
      <div className="create-plan-container">
        <div className="create-plan-left">
          <img src="/plan-image.jpg" alt="Plan" className="create-plan-image" />
        </div>
        <div className="create-plan-right">
          <div className="create-plan-header">
            <h2>{currentPlan.plan_id ? "Edit Plan" : "Create New Plan"}</h2>
          </div>
          <div className="create-plan-form">
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="plan_name"
                value={currentPlan.plan_name}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Plan Name</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="monthly_price"
                type="number"
                step="0.01"
                value={currentPlan.monthly_price || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Price (Monthly)</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="annual_price"
                type="number"
                step="0.01"
                value={currentPlan.annual_price || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Price (Annually)</label>
            </div>
            <div className="create-plan-input-group">
              <textarea
                className="create-plan-textarea"
                name="plan_description"
                value={currentPlan.plan_description}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Description</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="max_tests"
                value={currentPlan.max_tests || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Tests Limit</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="max_questions" // Adjust field name to match backend
                value={currentPlan.max_questions || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Questions Limit</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="max_testcases_per_question"
                value={currentPlan.max_testcases_per_question || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Test Cases Limit</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="max_mcq_questions"
                type="number"
                value={currentPlan.max_mcq_questions || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>MCQ Questions Limit</label>
            </div>
            <div className="create-plan-input-group">
              <input
                className="create-plan-input"
                name="max_participants"
                value={currentPlan.max_participants || ""}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label>Participants Limit</label>
            </div>
            <div className="create-plan-checkbox">
              <input
                type="checkbox"
                name="public"
                checked={currentPlan.public}
                onChange={handleInputChange}
              />
              <label>Keep this plan publicly visible</label>
            </div>
            <button
              className="create-plan-language-button"
              onClick={() => setShowLanguageSelection(true)}
            >
              Select Languages
            </button>
          </div>
          <div className="create-plan-actions">
            <button className="create-plan-button" onClick={handleSavePlan}>
              {currentPlan.plan_id ? "Update Plan" : "Create Plan"}
            </button>
            <button className="create-plan-cancel-btn" onClick={closeForm}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminCreatePlan;
