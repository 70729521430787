import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { loader } from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {
  const [value, setValue] = useState(code || "");
  const [key, setKey] = useState(0); // Add a key to force re-render

  useEffect(() => {
    setValue(code);
  }, [code]);

  useEffect(() => {
    // When theme changes, force a re-render
    setKey((prevKey) => prevKey + 1);
  }, [theme]);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  const handleEditorDidMount = (editor) => {
    editor.onDidChangeModelContent(() => {
      handleEditorChange(editor.getValue());
    });

    // Disable copy/paste commands
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyV, () => {
      // Prevent paste
    });
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyC, () => {
      // Prevent copy
    });
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyX, () => {
      // Prevent cut
    });
  };

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <Editor
        key={key}
        height="100%"
        width="99%"
        language={language || "javascript"}
        value={value}
        theme={theme}
        defaultValue="// some comment"
        onMount={handleEditorDidMount}
        options={{
          contextmenu: false,
          // colorDecorators: true,
          // cursorBlinking: "blink",
          // cursorSmoothCaretAnimation: false,
          // cursorStyle: "line",
        }}
      />
    </div>
  );
};

export default CodeEditorWindow;
