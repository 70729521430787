import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  Paper,
  Tabs,
  Tab,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Card,
  CardContent,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast } from "react-toastify";

const CreateQuestionsGroup = ({ onBack, editingGroup = null }) => {
  const [isInitialMount, setIsInitialMount] = useState(true);

  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState({
    coding: [],
    mcq: [],
  });
  const [viewQuestion, setViewQuestion] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [isEditing] = useState(!!editingGroup);

  // Fetch questions on component mount
  useEffect(() => {
    if (isInitialMount) {
      fetchCodingQuestions();
      fetchMCQQuestions();
      setIsInitialMount(false);
    }
  }, [isInitialMount]);

  // Fetch coding questions
  const fetchCodingQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/question`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCodingQuestions(
        response.data.map((q) => ({
          ...q,
          type: "coding",
        }))
      );
    } catch (error) {
      console.error("Error fetching coding questions:", error);
      toast.error("Failed to fetch coding questions");
    }
  };

  // Fetch MCQ questions
  const fetchMCQQuestions = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/org/question/fetch-questions/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.data.questions) {
        setMcqQuestions(
          response.data.data.questions.map((q) => ({
            ...q,
            type: "mcq",
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      toast.error("Failed to fetch MCQ questions");
    }
  };

  //   useEffect to handle editing mode
  useEffect(() => {
    if (editingGroup) {
      console.log("Editing Group Data:", editingGroup); // For debugging
      setGroupName(editingGroup.groupData.group_name);
      setGroupDescription(editingGroup.groupData.group_description);

      // Set selected questions
      const selectedCoding = [];
      const selectedMcq = [];

      editingGroup.questions.forEach((q) => {
        if (q.type === "coding") {
          selectedCoding.push({
            ...q.details,
            type: "coding",
            id: q.details.question_id || q.details.id, // Handle different ID formats
          });
        } else {
          selectedMcq.push({
            ...q.details,
            type: "mcq",
            mcq_question_id: q.details.mcq_question_id,
          });
        }
      });

      setSelectedQuestions({
        coding: selectedCoding,
        mcq: selectedMcq,
      });
    }
  }, [editingGroup]);

  // HandleCreateGroup to handle both create and edit
  const handleCreateGroup = () => {
    if (!groupName.trim()) {
      toast.error("Please enter a group name");
      return;
    }
    if (
      selectedQuestions.coding.length === 0 &&
      selectedQuestions.mcq.length === 0
    ) {
      toast.error("Please select at least one question");
      return;
    }
    setIsConfirmDialogOpen(true);
  };

  // Function to handle group submission
  const handleSubmitGroup = async () => {
    try {
      const questions = [
        ...selectedQuestions.coding.map((q) => ({
          type: "coding",
          id: q.id || q.question_id,
        })),
        ...selectedQuestions.mcq.map((q) => ({
          type: "mcq",
          id: q.mcq_question_id,
        })),
      ];

      if (isEditing) {
        // Update existing group
        const updateData = {
          groupId: editingGroup.groupData.group_id,
          groupName,
          groupDescription,
          questions: questions.map((q) => ({
            questionId: q.id,
            type: q.type,
          })),
        };

        // Only add deletedQuestions if there are any
        if (deletedQuestions.length > 0) {
          updateData.deletedQuestions = deletedQuestions;
        }

        await axios.put(
          `${BASE_URL}/api/org/group/update-group-data`,
          updateData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        toast.success("Group updated successfully!");
      } else {
        // Create new group
        const createData = {
          groupName,
          groupDescription,
          userId,
          questions,
        };

        console.log("Create Data:", createData); // For debugging

        await axios.post(`${BASE_URL}/api/org/group/create-group`, createData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Group created successfully!");
      }
      onBack();
    } catch (error) {
      console.error("Error submitting group:", error);
      // More detailed error handling
      if (error.response) {
        toast.error(
          error.response.data.message ||
            (isEditing ? "Failed to update group" : "Failed to create group")
        );
      } else {
        toast.error(
          isEditing ? "Failed to update group" : "Failed to create group"
        );
      }
    }
  };

  // Filter questions based on search query
  const filteredQuestions = () => {
    const questions = activeTab === 0 ? codingQuestions : mcqQuestions;
    const selectedIds = new Set(
      selectedQuestions[activeTab === 0 ? "coding" : "mcq"].map((q) =>
        activeTab === 0 ? q.id : q.mcq_question_id
      )
    );

    // Filter based on search query
    const filtered = searchQuery
      ? questions.filter((q) =>
          (q.title || q.question_text || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : questions;

    // Sort to show selected questions first
    return filtered.sort((a, b) => {
      const aSelected = selectedIds.has(
        activeTab === 0 ? a.id : a.mcq_question_id
      );
      const bSelected = selectedIds.has(
        activeTab === 0 ? b.id : b.mcq_question_id
      );
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  };

  //isQuestionSelected to handle both question types correctly
  const isQuestionSelected = (question) => {
    const type = question.type;
    const questionId =
      type === "coding" ? question.id : question.mcq_question_id;

    return selectedQuestions[type].some((q) =>
      type === "coding"
        ? q.id === questionId || q.question_id === questionId
        : q.mcq_question_id === questionId
    );
  };

  // ToggleQuestionSelection to handle both question types
  const toggleQuestionSelection = (question) => {
    const type = question.type;
    const questionId =
      type === "coding" ? question.id : question.mcq_question_id;

    setSelectedQuestions((prev) => {
      const currentSelected = prev[type];
      const isCurrentlySelected = currentSelected.some((q) =>
        type === "coding"
          ? q.id === questionId || q.question_id === questionId
          : q.mcq_question_id === questionId
      );

      if (isCurrentlySelected) {
        // If editing, track deleted questions
        if (isEditing) {
          const questionToTrack = editingGroup.questions.find(
            (q) =>
              q.type === type &&
              (type === "coding"
                ? q.details.id === questionId ||
                  q.details.question_id === questionId
                : q.details.mcq_question_id === questionId)
          );
          if (questionToTrack) {
            setDeletedQuestions((prev) => [...prev, questionToTrack.id]);
          }
        }

        return {
          ...prev,
          [type]: currentSelected.filter((q) =>
            type === "coding"
              ? q.id !== questionId && q.question_id !== questionId
              : q.mcq_question_id !== questionId
          ),
        };
      } else {
        // If adding back a previously deleted question
        if (isEditing) {
          const questionToUntrack = editingGroup.questions.find(
            (q) =>
              q.type === type &&
              (type === "coding"
                ? q.details.id === questionId ||
                  q.details.question_id === questionId
                : q.details.mcq_question_id === questionId)
          );
          if (questionToUntrack) {
            setDeletedQuestions((prev) =>
              prev.filter((id) => id !== questionToUntrack.id)
            );
          }
        }

        return {
          ...prev,
          [type]: [...currentSelected, question],
        };
      }
    });
  };

  // Handle view question details
  const handleViewQuestion = (question) => {
    setViewQuestion(question);
    setIsViewModalOpen(true);
  };

  // Render the questions table
  const renderQuestionsTable = () => {
    const questions = filteredQuestions();

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {activeTab === 0 ? (
                // Coding questions columns
                <>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </>
              ) : (
                // MCQ questions columns
                <>
                  <TableCell>Question Text</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Difficulty</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question) => (
              <TableRow key={question.id || question.mcq_question_id}>
                {activeTab === 0 ? (
                  // Coding question rows
                  <>
                    <TableCell>{question.title}</TableCell>
                    <TableCell sx={{ maxWidth: "400px" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: question.content }}
                        sx={{
                          maxHeight: "100px",
                          overflow: "auto",
                          "& p": { margin: "4px 0" },
                          "& blockquote": {
                            margin: "4px 0",
                            paddingLeft: "10px",
                            borderLeft: "3px solid #e0e0e0",
                          },
                          "& strong": { fontWeight: "bold" },
                          "& em": { fontStyle: "italic" },
                        }}
                      />
                    </TableCell>
                  </>
                ) : (
                  // MCQ question rows
                  <>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question_text,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question_description,
                        }}
                      />
                    </TableCell>
                    <TableCell>{question.difficulty}</TableCell>
                    <TableCell>{question.points}</TableCell>
                  </>
                )}
                <TableCell align="center">
                  <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                  >
                    <IconButton
                      onClick={() => handleViewQuestion(question)}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <Button
                      size="small"
                      color={isQuestionSelected(question) ? "error" : "success"}
                      onClick={() => toggleQuestionSelection(question)}
                    >
                      {isQuestionSelected(question) ? "Remove" : "Add"}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case "easy":
        return "#4caf50";
      case "medium":
        return "#ff9800";
      case "hard":
        return "#f44336";
      default:
        return "inherit";
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {/* Header Section */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{ mb: 4, display: "flex", justifyContent: "flex-start" }}
        >
          Back to Questions Groups
        </Button>

        <Typography variant="h4" component="h1" gutterBottom>
          {isEditing ? "Edit Questions Group" : "Create Questions Group"}
        </Typography>

        {/* Group Details Form */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <TextField
            fullWidth
            label="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            sx={{ mb: 3 }}
            required
          />
          <TextField
            fullWidth
            label="Group Description"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            multiline
            rows={3}
          />
        </Paper>

        {/* Questions Selection Section */}
        <Paper sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              gap: 2,
            }}
          >
            <TextField
              placeholder="Search questions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ flexGrow: 1, maxWidth: "400px" }}
            />

            <Box sx={{ display: "flex", gap: 2, flexShrink: 0 }}>
              <Chip
                label={`Coding Questions: ${selectedQuestions.coding.length}`}
                color="primary"
                variant="outlined"
              />
              <Chip
                label={`MCQ Questions: ${selectedQuestions.mcq.length}`}
                color="secondary"
                variant="outlined"
              />
              <Chip
                label={`Total: ${
                  selectedQuestions.coding.length + selectedQuestions.mcq.length
                }`}
                color="default"
                variant="outlined"
              />
            </Box>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
            >
              <Tab label="Coding Questions" />
              <Tab label="MCQ Questions" />
            </Tabs>
          </Box>

          {renderQuestionsTable()}
        </Paper>

        {/* Create Button */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCreateGroup}
            disabled={
              !groupName.trim() ||
              (selectedQuestions.coding.length === 0 &&
                selectedQuestions.mcq.length === 0)
            }
          >
            Create Group
          </Button>
        </Box>
      </Box>

      {/* View Question Modal */}
      <Modal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid",
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {viewQuestion?.type === "coding"
                ? "Coding Question Details"
                : "MCQ Question Details"}
            </Typography>
            <IconButton onClick={() => setIsViewModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Modal Content */}
          <Box sx={{ p: 3, overflowY: "auto" }}>
            {viewQuestion &&
              (viewQuestion.type === "coding" ? (
                // Coding Question View
                <Box>
                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom color="primary">
                      Question Title
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {viewQuestion.title}
                    </Typography>
                  </Paper>

                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom color="primary">
                      Description
                    </Typography>
                    <Box
                      sx={{
                        "& p": { mb: 2 },
                        "& blockquote": {
                          borderLeft: "4px solid",
                          borderColor: "primary.main",
                          pl: 2,
                          my: 2,
                          bgcolor: "action.hover",
                          py: 1,
                        },
                        "& strong": { fontWeight: "bold" },
                        "& em": { fontStyle: "italic" },
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: viewQuestion.content,
                        }}
                      />
                    </Box>
                  </Paper>

                  {viewQuestion.testCases ? (
                    <Paper sx={{ p: 3 }}>
                      <Typography variant="h6" gutterBottom color="primary">
                        Test Cases
                      </Typography>
                      {viewQuestion.testCases.map((testCase, index) => (
                        <Box
                          key={index}
                          sx={{
                            mb: 2,
                            p: 2,
                            bgcolor: "action.hover",
                            borderRadius: 1,
                          }}
                        >
                          <Typography variant="subtitle2" gutterBottom>
                            Test Case {index + 1}
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: 2,
                            }}
                          >
                            <Box>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                Input:
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: "monospace",
                                  whiteSpace: "pre-wrap",
                                  bgcolor: "grey.100",
                                  p: 1,
                                  borderRadius: 1,
                                }}
                              >
                                {testCase.inputContent || testCase.input}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                Expected Output:
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: "monospace",
                                  whiteSpace: "pre-wrap",
                                  bgcolor: "grey.100",
                                  p: 1,
                                  borderRadius: 1,
                                }}
                              >
                                {testCase.outputContent || testCase.output}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Paper>
                  ) : (
                    <Paper sx={{ p: 3 }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        No test cases available
                      </Typography>
                    </Paper>
                  )}
                </Box>
              ) : (
                // MCQ Question View
                <Box>
                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      Question Type:
                    </Typography>
                    <Typography>
                      {viewQuestion.question_type === 0
                        ? "Single Correct Option"
                        : "Multiple Correct Options"}
                    </Typography>
                  </Paper>

                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      Question Text:
                    </Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: viewQuestion.question_text,
                      }}
                    />
                  </Paper>

                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      Description:
                    </Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: viewQuestion.question_description,
                      }}
                    />
                  </Paper>

                  <Paper sx={{ p: 3, mb: 3 }}>
                    <Box sx={{ display: "flex", gap: 4 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Difficulty:{" "}
                        <span
                          style={{
                            color: getDifficultyColor(viewQuestion.difficulty),
                          }}
                        >
                          {viewQuestion.difficulty}
                        </span>
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Points: {viewQuestion.points}
                      </Typography>
                    </Box>
                  </Paper>

                  {viewQuestion.options ? (
                    <Paper sx={{ p: 3 }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Options:
                      </Typography>
                      {(Array.isArray(viewQuestion.options)
                        ? viewQuestion.options
                        : []
                      ).map((option, index) => (
                        <Box
                          key={option.option_id || index}
                          sx={{
                            mb: 2,
                            p: 2,
                            bgcolor: option.is_correct
                              ? "success.light"
                              : "grey.50",
                            borderRadius: 1,
                            border: 1,
                            borderColor: option.is_correct
                              ? "success.main"
                              : "grey.300",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body2" sx={{ flex: 1 }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    option.option_text || option.optionText,
                                }}
                              />
                            </Typography>
                            {(option.is_correct || option.isCorrect) && (
                              <Chip
                                label="Correct"
                                color="success"
                                size="small"
                                variant="outlined"
                                sx={{ mt: 0.5 }}
                              />
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Paper>
                  ) : (
                    <Paper sx={{ p: 3 }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        No options available
                      </Typography>
                    </Paper>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <DialogTitle>
          {isEditing ? "Confirm Group Update" : "Confirm Group Creation"}
        </DialogTitle>{" "}
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Group Name:</strong> {groupName}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Description:</strong> {groupDescription}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Selected Questions:</strong>
          </Typography>
          <Typography>
            Coding Questions: {selectedQuestions.coding.length}
          </Typography>
          <Typography>MCQ Questions: {selectedQuestions.mcq.length}</Typography>
          <Typography>
            Total Questions:{" "}
            {selectedQuestions.coding.length + selectedQuestions.mcq.length}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitGroup}
          >
            {isEditing ? "Update Group" : "Create Group"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CreateQuestionsGroup;
