// participantUtils.js
import Papa from 'papaparse';
import validator from 'validator';

export const validateParticipantCSV = (results) => {
  const headers = results.meta.fields;
  const requiredHeaders = ['first_name', 'last_name', 'email'];
  
  // Validate headers
  const hasRequiredHeaders = requiredHeaders.every(header => 
    headers.includes(header)
  );
  
  if (!hasRequiredHeaders) {
    return {
      isValid: false,
      error: 'CSV must contain first_name, last_name, and email columns'
    };
  }

  // Validate rows
  const emails = new Set();
  const errors = [];
  
  results.data.forEach((row, index) => {
    // Check for missing fields
    if (!row.first_name || !row.last_name || !row.email) {
      errors.push(`Row ${index + 1}: Missing required fields`);
      return;
    }

    // Validate email format
    if (!validator.isEmail(row.email)) {
      errors.push(`Row ${index + 1}: Invalid email format - ${row.email}`);
      return;
    }

    // Check for duplicate emails within the file
    if (emails.has(row.email)) {
      errors.push(`Row ${index + 1}: Duplicate email - ${row.email}`);
      return;
    }

    emails.add(row.email);
  });

  return {
    isValid: errors.length === 0,
    error: errors.length > 0 ? errors.join('\n') : null,
    rowCount: results.data.length,
    data: results.data
  };
};

export const processParticipantCSV = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const validation = validateParticipantCSV(results);
        if (!validation.isValid) {
          reject(new Error(validation.error));
          return;
        }
        resolve(validation);
      },
      error: (error) => {
        reject(new Error('Error parsing CSV: ' + error.message));
      }
    });
  });
};