// profileImageStore.js

import axios from 'axios'; // Ensure axios is imported

// A Set to hold listener functions
const listeners = new Set();

// Helper function to sanitize the image URL
const sanitizeImageUrl = (url) => {
  if (!url) return null;
  try {
    const decodedUrl = decodeURIComponent(url);
    if (decodedUrl.includes('tc-engg.s3.ap-south-1.amazonaws.com')) {
      const matches = decodedUrl.match(
        /tc-engg\.s3\.ap-south-1\.amazonaws\.com\/(.+?)(\?.*)?$/
      );
      if (matches && matches[1]) {
        const actualPath = matches[1];
        const authParams = matches[2] || '';
        return `https://tc-engg.s3.ap-south-1.amazonaws.com/${actualPath}${authParams}`;
      }
    }
    return decodedUrl;
  } catch (error) {
    console.error('Error sanitizing image URL:', error);
    return url;
  }
};

export const profileImageStore = {
  // Add a listener to the Set
  subscribe: (listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener); // Return an unsubscribe function
  },

  // Notify all listeners of a new profile image
  notifyImageChange: (imageUrl) => {
    const userId = localStorage.getItem('user_id');
    if (imageUrl) {
      localStorage.setItem(`profile_image_${userId}`, imageUrl);
    } else {
      localStorage.removeItem(`profile_image_${userId}`);
    }
    listeners.forEach((listener) => listener(imageUrl));
  },

  // Initialize the profile image
  initializeProfileImage: async () => {
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    if (!userId || !token) {
      return null;
    }

    try {
      const cachedImageUrl = localStorage.getItem(`profile_image_${userId}`);
      if (cachedImageUrl) {
        const img = new Image();
        img.src = cachedImageUrl;

        return new Promise((resolve) => {
          img.onload = () => {
            listeners.forEach((listener) => listener(cachedImageUrl));
            resolve(cachedImageUrl);
          };
          img.onerror = async () => {
            const freshImageUrl = await fetchFromApi();
            resolve(freshImageUrl);
          };
        });
      }
      return await fetchFromApi();
    } catch (error) {
      console.error('Error in initializeProfileImage:', error);
      return null;
    }

    async function fetchFromApi() {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/get-profile/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success && response.data.data?.profile_photo_url) {
          const sanitizedUrl = sanitizeImageUrl(response.data.data.profile_photo_url);
          if (sanitizedUrl) {
            localStorage.setItem(`profile_image_${userId}`, sanitizedUrl);
            listeners.forEach((listener) => listener(sanitizedUrl));
            return sanitizedUrl;
          }
        }
      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
      return null;
    }
  },
};
